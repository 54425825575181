import React from "react";

import underConstructionImage from '../../assets/illustrations/under-construction.png';
import { Box } from "@mui/material";
import Copyright from "../Copyright";



function Dashboard() {

    return (
        <div>
            <div className="layout layout-item-center layout-justify-center">
                <h1>We are currently working on this feature and will launch soon!</h1>
            </div>
            <div className="layout layout-item-center layout-justify-center">
                <div>
                    <img src={underConstructionImage} alt="Under Construction Image" width="512px" />
                </div>
            </div>
            <Box component="footer">
                <Copyright sx={{ pb: 3, px: 3 }} />
            </Box>
        </div>
    )
}

export default Dashboard;