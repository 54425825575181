import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Icon } from '@mui/material';
import { Chip } from '@mui/material';
import { useEffect, useState } from 'react';
import Header from '../../Header/Header';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import Grid from '@mui/material/Grid';
import { NavLink, useNavigate, useParams } from "react-router-dom";

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import DeleteIcon from '@mui/icons-material/Delete';

import patientAddSuccessImage from '../../../assets/illustrations/patient-add-success.png';
import errorImage from '../../../assets/illustrations/error.png';

import { useForm, Controller, useFieldArray } from "react-hook-form";

import Divider from '@mui/material/Divider';

import AddIcon from '@mui/icons-material/Add';

import CircularProgress from '@mui/material/CircularProgress';
import Copyright from '../../Copyright';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    // [`&.${tableCellClasses.head}`]: {
    //   backgroundColor: theme.palette.common.black,
    //   color: theme.palette.common.white,
    // },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // '&:nth-of-type(odd)': {
    //   backgroundColor: theme.palette.action.hover,
    // },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));



function EditSurvey() {
    let { surveyId } = useParams();
    let navigate = useNavigate();
    const [surveyFormData, setSurveyFormData] = useState({
        firstName: '',
        lastName: '',
        dob: '',
        phone: '',
        email: '',
        gender: '',
        roles: '',
        hospitals: ''
    })

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        clearErrors
    } = useForm({
        defaultValues: async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/surveys/` + surveyId);
            const data = await response.json();
            // console.log(data);
            return data['data'];
        },
    });

    const { register: register1, handleSubmit: handleSubmit1, control: control1, reset: reset1 } = useForm({
        defaultValues: {
            responseOptions: [
                { option: "", value: "" },
            ]
        }
    });

    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control: control1, // control props comes from useForm (optional: if you are using FormContext)
        name: "responseOptions", // unique name for your Field Array
    });

    const onSubmit = (data) => {
        console.log(JSON.stringify(data));
        let myHeaders = new Headers();

        let surveydata = {
            ...data,
            ['questions']: questionList
        }
        myHeaders.append("Content-Type", "application/json");
        let requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: JSON.stringify(surveydata),
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/surveys/` + surveyId, requestOptions)
            .then(response => response.text())
            .then(result => {
                if (!JSON.parse(result).err) {
                    console.log(result)
                    setSuccessDialog(true);
                } else {
                    console.log('error', JSON.parse(result).err)
                    setErrorDialog(true);
                    setErrMsg(JSON.parse(result).err);
                }
            })
            .catch(error => console.log('error', error));
        console.log("Reuest body", surveydata);

    }

    const [questionList, setQuestionList] = useState([]);

    const [isLoading, setisLoading] = React.useState(false);

    const onSaveQuestion = (data) => {
        console.log(JSON.stringify(data));
        if ('uid' in data) {
            let selectedQuestionIndex = questionList.findIndex(q => q.uid === data.uid);
            // console.log('selectedQuestionIndex',selectedQuestionIndex);
            let updatedQuestionList = JSON.parse(JSON.stringify(questionList));
            updatedQuestionList[selectedQuestionIndex] = data;
            setQuestionList(updatedQuestionList);


        } else {
            setQuestionList([
                ...questionList,
                data
            ]);
        }

        handleClose();
        reset1({});
    }

    function backToSurveys() {
        navigate('/surveys')
    }

    function backToSurvey() {
        navigate('/surveys/viewsurvey/' + surveyId)
    }

    const [tabValue, setTabValue] = React.useState('1');

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        setisLoading(true);
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/surveys/` + surveyId)
            .then((res) => res.json())
            .then((data) => {
                setSurveyFormData({
                    ...data['data']
                });
                setQuestionList([
                    ...data['data']['questions']
                ]);
                setisLoading(false);
                console.log("Survey Data Edit", data);
            })
    }, [])


    const [openDialog, setOpenDialog] = React.useState(false);
    const [successDialog, setSuccessDialog] = React.useState(false);
    const [errorDialog, setErrorDialog] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState('');

    // const handleClickOpen = () => {
    //     setOpenDialog(true);
    // };



    const closeSuccessDialog = () => {
        setSuccessDialog(false);
    };
    const closeErrorDialog = () => {
        setErrorDialog(false);
    };

    const handleClose = () => {
        reset1({});
        setOpenDialog(false);
    };

    const openQuestionDialog = () => {
        setOpenDialog(true);
        reset1({});
    };

    const [surveyList, setsurveyList] = useState([])
    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/surveys?status=all`)
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    setsurveyList(data['data']['data']);
                }
            })
    }, [])

    const [configList, setConfigList] = useState([])
    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/configs`)
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    setConfigList(data['data']['data']);
                }
            })
    }, [])

    const getQuestionbyID = (uid) => {
        setOpenDialog(true);
        // console.log(surveyFormData.questions.find((question)=> question.uid === uid)); 
        let question = questionList.find((question) => question.uid === uid)
        reset1(question);

    }



    return (
        <div>
            <Dialog
                open={successDialog}
                onClose={closeSuccessDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{ sx: { borderRadius: "16px" } }}
            >
                <DialogTitle id="alert-dialog-title">
                </DialogTitle>
                <DialogContent className='pad48'>
                    <DialogContentText id="alert-dialog-description">
                        <div className="layout layout-vertical layout-justify-center layout-item-center">
                            <img src={patientAddSuccessImage} alt="" />
                            <p className='dialog-description'>
                                Survey updated successfully!
                            </p>
                            <p></p>
                        </div>

                    </DialogContentText>
                </DialogContent>
                <DialogActions className="dialog-action-bg">
                    <div className='layout layout-item-center layout-justify-center layout-full-width mt16 mb16'>
                        <Button variant="text" onClick={backToSurveys}>Close</Button>
                        {/* <Button className="round-button ml24" variant="outlined" onClick={backToViewUser}>View User</Button> */}
                    </div>

                </DialogActions>
            </Dialog>
            <Dialog
                open={errorDialog}
                onClose={closeErrorDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{ sx: { borderRadius: "16px" } }}
            >
                <DialogTitle id="alert-dialog-title">
                </DialogTitle>
                <DialogContent className='pad48'>
                    <DialogContentText id="alert-dialog-description">
                        <div className="layout layout-vertical layout-justify-center layout-item-center">
                            <img src={errorImage} alt="" height="256px" />
                            <p className='dialog-description' style={{ 'color': '#B04933' }}>
                                Something Went Wrong!
                            </p>
                            <p style={{ textTransform: 'capitalize', textAlign: 'center' }}>{errMsg}</p>
                        </div>

                    </DialogContentText>
                </DialogContent>
                <DialogActions className="dialog-action-bg">
                    <div className='layout layout-item-center layout-justify-center layout-full-width mt16 mb16'>
                        <Button variant="text" onClick={closeErrorDialog}>Close</Button>
                    </div>

                </DialogActions>
            </Dialog>

            <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{ sx: { borderRadius: "16px" } }}
                maxWidth='xl'
            >
                <form onSubmit={handleSubmit1(onSaveQuestion)}>
                    <DialogTitle id="alert-dialog-title">
                        Add New Question
                    </DialogTitle>

                    <DialogContent className='pad48'>
                        <DialogContentText id="alert-dialog-description">
                            <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                <Grid item xs={12} md={6}>
                                    {/* <div className='mb16'>
                                        <FormControl fullWidth>
                                            <InputLabel id="Variable-ID">Variable ID</InputLabel>
                                            <Select
                                                name="variableId"
                                                labelId="variableId"
                                                id="variableId-select"
                                                label="Variable ID"
                                                {...register1('variableId', { required: true })}
                                            >
                                                <MenuItem value={'Q10'}>Q10</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div> */}
                                    <TextField
                                        fullWidth
                                        className='mb16'
                                        name="variableId"
                                        label="Vaiable ID"
                                        type="text"
                                        autoFocus
                                        {...register1('variableId', { required: true })}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {/* <div className='mb16'>
                                        <FormControl fullWidth>
                                            <InputLabel id="item">Item</InputLabel>
                                            <Select
                                                name="item"
                                                labelId="item"
                                                id="item-select"
                                                label="Item"
                                                {...register1('item', { required: true })}
                                            >
                                                <MenuItem value={'test3'}>test3</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div> */}
                                    <TextField
                                        fullWidth
                                        className='mb16'
                                        name="item"
                                        label="Item"
                                        type="text"
                                        {...register1('item', { required: true })}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        fullWidth
                                        className='mb16'
                                        name="question"
                                        label="Question"
                                        type="text"
                                        {...register1('displayedValue', { required: true })}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        name="definition"
                                        label="Definition"
                                        fullWidth
                                        id="outlined-multiline-static"
                                        multiline
                                        rows={2}
                                        className='mb16'
                                        type="text"
                                        {...register1('definition', { required: true })}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        name="supportingDefinition"
                                        label="Supporting Definition"
                                        fullWidth
                                        id="outlined-multiline-static"
                                        multiline
                                        rows={2}
                                        className='mb16'
                                        type="text"
                                        {...register1('supportingDefinition', { required: true })}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className='mb16'>
                                        <Controller
                                            control={control1}
                                            name='inclusionCriteria'
                                            rules={{ required: true }}
                                            defaultValue={''}
                                            render={({ field }) => (
                                                <FormControl fullWidth>
                                                    <InputLabel id="Question-Type">Inclusion Criteria</InputLabel>
                                                    <Select
                                                        name="inclusionCriteria"
                                                        labelId="inclusionCriteria"
                                                        id="inclusionCriteria-select"
                                                        label="Inclusion Criteria"
                                                        {...field}
                                                    >
                                                        {configList['inclusionCriteria']?.map((criteria) => (
                                                            <MenuItem value={criteria}>{criteria}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>

                                            )}
                                        />

                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className='mb16'>
                                        <Controller
                                            control={control1}
                                            name='timing'
                                            rules={{ required: true }}
                                            defaultValue={''}
                                            render={({ field }) => (
                                                <FormControl fullWidth>
                                                    <InputLabel id="timing">Timing</InputLabel>
                                                    <Select
                                                        name="timing"
                                                        labelId="timing"
                                                        id="timing-select"
                                                        label="Timing"
                                                        {...field}
                                                    >
                                                        {configList['timing']?.map((timing) => (
                                                            <MenuItem value={timing}>{timing}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>

                                            )}
                                        />

                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className='mb16'>
                                        <Controller
                                            control={control1}
                                            name='reportingSource'
                                            rules={{ required: true }}
                                            defaultValue={''}
                                            render={({ field }) => (
                                                <FormControl fullWidth>
                                                    <InputLabel id="source">Reporting Source</InputLabel>
                                                    <Select
                                                        name="reportingSource"
                                                        labelId="reportingSource"
                                                        id="source-select"
                                                        label="Reporting Source"
                                                        {...field}
                                                    >
                                                        {configList['reportingSource']?.map((source) => (
                                                            <MenuItem value={source}>{source}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>

                                            )}
                                        />

                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className='mb16'>
                                        <Controller
                                            control={control1}
                                            name='type'
                                            rules={{ required: true }}
                                            defaultValue={''}
                                            render={({ field }) => (
                                                <FormControl fullWidth>
                                                    <InputLabel id="Question-Type">Question Type</InputLabel>
                                                    <Select
                                                        name="questionType"
                                                        labelId="questionType"
                                                        id="question-type-select"
                                                        label="Question Type"
                                                        {...field}
                                                    >
                                                        {configList['questionType']?.map((type) => (
                                                            <MenuItem value={type}>{type}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>

                                            )}
                                        />

                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className='mb16'>
                                        <Controller
                                            control={control1}
                                            name='valueDomain'
                                            rules={{ required: true }}
                                            defaultValue={''}
                                            render={({ field }) => (
                                                <FormControl fullWidth>
                                                    <InputLabel id="Value-Domain">Value Domain</InputLabel>
                                                    <Select
                                                        name="valueDomain"
                                                        labelId="valueDomain"
                                                        id="value-domain-select"
                                                        label="Value Type"
                                                        {...field}
                                                    >
                                                        {configList['valueDomain']?.map((type) => (
                                                            <MenuItem value={type}>{type}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>

                                            )}
                                        />

                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className='mb16'>
                                        <Controller
                                            control={control1}
                                            name='responseType'
                                            rules={{ required: true }}
                                            defaultValue={''}
                                            render={({ field }) => (
                                                <FormControl fullWidth>
                                                    <InputLabel id="Response-Type">Response Type</InputLabel>
                                                    <Select
                                                        name="responseType"
                                                        labelId="responseType"
                                                        id="response-type-select"
                                                        label="Response Type"
                                                        {...field}
                                                    >
                                                        {configList['responseType']?.map((type) => (
                                                            <MenuItem value={type}>{type}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>

                                            )}
                                        />

                                    </div>
                                </Grid>



                                {/* <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        className='mb16'
                                        name="rationalForInclusion"
                                        label="Rational For Inclusion"
                                        type="text"
                                        {...register1('rationalForInclusion', { required: true })}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        className='mb16'
                                        name="reference"
                                        label="Reference"
                                        type="text"
                                        {...register1('reference', { required: true })}
                                    />
                                    <TextField
                                        fullWidth
                                        className='mb16'
                                        name="additionalNotes"
                                        label="Additional Notes"
                                        type="text"
                                        {...register1('additionalNotes', { required: true })}
                                    />
                                </Grid> */}
                                {/* <Grid item xs={6}>
                                            <div className='mb16'>
                                                <FormControl fullWidth>
                                                    <InputLabel id="responseType">Response Type</InputLabel>
                                                    <Select
                                                        name="responseType"
                                                        labelId="responseType"
                                                        id="responseType-select"
                                                        label="Response Type"
                                                    >
                                                        <MenuItem value={'Objective'}>Objective</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </Grid> */}
                            </Grid>

                            <Divider variant='fullWidth' />

                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <h3>Response Options</h3>
                                </Grid>

                                {fields.map((item, index) => (
                                    <Grid container item spacing={2} key={item.id}>
                                        <Grid item xs={2}>
                                            <div className="mb16">
                                                <TextField
                                                    fullWidth
                                                    name={`response-value-${index}`}
                                                    label={`Response Value ${index + 1}`}
                                                    type="text"
                                                    {...register1(`responseOptions.${index}.value`, { required: true })}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div className="mb16">
                                                <TextField
                                                    fullWidth
                                                    name={`response-option-${index}`}
                                                    label={`Response Option ${index + 1}`}
                                                    type="text"
                                                    {...register1(`responseOptions.${index}.option`, { required: true })}
                                                />
                                            </div>
                                            {/* <p>Response Option {`${index + 1}`}</p> */}
                                            {/* Updating Response Options */}
                                        </Grid>

                                        {index != 0 ?
                                            <Grid item xs={2} className='mt8'>
                                                <IconButton aria-label="delete" id={index} onClick={() => remove(index)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Grid> :
                                            <Grid item xs={2}>
                                                <Button
                                                    className="round-button"
                                                    variant="outlined"
                                                    onClick={() => {
                                                        console.log('fields 1', fields);
                                                        append({ option: '', value: '' });
                                                    }}
                                                    startIcon={<AddIcon />}>
                                                    Add Response
                                                </Button>
                                            </Grid>
                                        }
                                    </Grid>
                                ))}
                            </Grid>
                            {/* </Box> */}

                        </DialogContentText>
                    </DialogContent>

                    <DialogActions className="dialog-action-bg">
                        <div className='layout layout-item-center layout-justify-end layout-full-width mt16 mb16 mr32'>
                            <Button variant="text" onClick={handleClose}>Close</Button>
                            <Button className="round-button ml24" variant="outlined" type='submit'>Save Question</Button>
                        </div>

                    </DialogActions>
                </form>
            </Dialog>

            <form onSubmit={handleSubmit(onSubmit)}>
                <Header>
                    <div className="layout layout-item-center layout-justify-space-between">
                        <h2>Edit Survey  - {surveyFormData.surveyName} - {surveyFormData.pid}</h2>
                        <Stack spacing={2} direction="row">
                            <Button variant="text" onClick={backToSurvey}>Cancel</Button>
                            <Button className="round-button" variant="contained" type='submit'>Save Survey</Button>
                        </Stack>
                    </div>
                </Header>

                {isLoading == true ?
                    <div className='layout layout-item-center layout-justify-center loadingProgress'>
                        <CircularProgress />
                    </div>
                    :
                    <div style={{ padding: '24px' }}>
                        <Grid container>
                            <Grid item xs={12} md={10}>
                                <Card>
                                    <CardContent>
                                        <p className='mb24'><strong>Basic Details</strong></p>
                                        <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                            <Grid item xs={12} md={5}>
                                                <div className="mb24">
                                                    <p className='view-label mb8'>Survey ID</p>
                                                    <p className='view-value'>{surveyFormData.pid}</p>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={5}>
                                                <Controller
                                                    control={control}
                                                    name='status'
                                                    rules={{ required: true }}
                                                    defaultValue={''}
                                                    render={({ field }) => (
                                                        <FormControl fullWidth>
                                                            <InputLabel id="status">Status</InputLabel>
                                                            <Select
                                                                name="status"
                                                                labelId="status"
                                                                id="status-select"
                                                                {...field}
                                                                label="Status"
                                                            >
                                                                <MenuItem value={'Active'}>Active</MenuItem>
                                                                <MenuItem value={'Inactive'}>Inactive</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}></Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <p></p>
                        <Grid container>
                            <Grid item xs={12} md={10}>
                                <Card className='mb16'>
                                    <CardContent>
                                        <p><b>Survey Details</b></p>
                                        <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                            <Grid item xs={12} md={5}>
                                                <div className="mb16">
                                                    <TextField
                                                        fullWidth
                                                        name="surveyName"
                                                        label="Survey Name"
                                                        type="text"
                                                        InputLabelProps={{ shrink: true }}
                                                        {...register('surveyName', { required: true })}
                                                        aria-label="Survey Name"
                                                        tabIndex={1}
                                                        autoFocus
                                                    />
                                                    {errors.surveyName && <p role="alert" className="error-message">Please enter Survey Name</p>}
                                                </div>
                                                {/* </Grid>
                                        <Grid item xs={12} md={2}></Grid>
                                        <Grid item xs={12} md={5}> */}
                                                <div class="mb16">
                                                    <TextField
                                                        name="surveyDescription"
                                                        label="Description"
                                                        fullWidth
                                                        id="outlined-multiline-static"
                                                        multiline
                                                        rows={6}
                                                        InputLabelProps={{ shrink: true }}
                                                        {...register('surveyDescription', { required: true })}
                                                        aria-label="Description"
                                                        tabIndex={2}
                                                    />
                                                    {errors.surveyDescription && <p role="alert" className="error-message">Please enter Survey Description</p>}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={5}>
                                                <div className='mb24'>
                                                    <Controller
                                                        control={control}
                                                        name='canBeSurveySet'
                                                        rules={{ required: true }}
                                                        defaultValue={''}
                                                        render={({ field }) => (
                                                            <FormControl fullWidth>
                                                                <InputLabel id="canBeSurveySet">Can be Survey Set</InputLabel>
                                                                <Select
                                                                    name="canBeSurveySet"
                                                                    labelId="canBeSurveySet"
                                                                    id="canBeSurveySet-select"
                                                                    {...field}
                                                                    label="Can be Survey Set"
                                                                    aria-label="Can be Survey Set"
                                                                    tabIndex={3}
                                                                >
                                                                    <MenuItem value={true}>Yes</MenuItem>
                                                                    <MenuItem value={false}>No</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        )}
                                                    />
                                                </div>
                                                <div>
                                                    <Controller
                                                        control={control}
                                                        name='canBeSubset'
                                                        rules={{ required: true }}
                                                        defaultValue={''}
                                                        render={({ field }) => (

                                                            <FormControl fullWidth>
                                                                <InputLabel id="canBeSubset">Can be Survey Subset</InputLabel>
                                                                <Select
                                                                    name="canBeSubset"
                                                                    labelId="canBeSubset"
                                                                    id="canBeSubset-select"
                                                                    // value={studyData.canBeSubset}
                                                                    // onChange={handleChange}
                                                                    {...field}
                                                                    label="Can be Survey Subset"
                                                                    aria-label="Can be Survey Subset"
                                                                    tabIndex={4}
                                                                >
                                                                    <MenuItem value={true}>Yes</MenuItem>
                                                                    <MenuItem value={false}>No</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        )}
                                                    />
                                                </div>

                                            </Grid>
                                            <Grid item xs={12} md={5}>


                                            </Grid>
                                            <Grid item xs={12} md={2}></Grid>
                                            {/* <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="surveySubSets">Survey Subsets</InputLabel>
                                        <Select
                                            name="surveySubSets"
                                            labelId="surveySubSets"
                                            id="surveySubSets-select"
                                            // value={studyData.surveySets}
                                            // onChange={handleChange}
                                            {...register('surveySubSets', { required: true })}
                                            label="Survey Sets"
                                        >
                                            {surveyList?.map((survey) => (
                                                <MenuItem value={survey.uid}>{survey.title}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid> */}


                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12} md={10}>
                                <Card className=''>
                                    <CardContent>
                                        <div className='layout layout-item-center layout-justify-space-between mb16'>
                                            <div>
                                                <p><b>Survey Questions</b></p>
                                            </div>
                                            <div className='layout layout-item-center'>
                                                <div>
                                                    <Button variant="text" >Import</Button>
                                                </div>
                                                <div className='ml16'>
                                                    <Button className="round-button" variant="outlined" onClick={openQuestionDialog}>Add New</Button>
                                                </div>
                                            </div>
                                        </div>

                                        <TableContainer >
                                            <Table sx={{ minWidth: 700 }} aria-label="customized table" size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell>Sequence</StyledTableCell>
                                                        <StyledTableCell>Item</StyledTableCell>
                                                        <StyledTableCell width={'500px'}>Question</StyledTableCell>
                                                        <StyledTableCell>Question Type</StyledTableCell>
                                                        <StyledTableCell>Reporting Source</StyledTableCell>
                                                        <StyledTableCell>Actions</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {surveyFormData?.questions?.length > 0 ? surveyFormData?.questions?.map((row, index) => (
                                                        <StyledTableRow key={row.pid}>
                                                            <StyledTableCell scope="row">
                                                                {/* <NavLink to={`/viewuser/${row.uid}`} exact activeClassName="active"> */}
                                                                <Button color="primary" onClick={() => getQuestionbyID(row.uid)}>
                                                                    {row.priorityLevel}
                                                                </Button>
                                                                {/* </NavLink> */}
                                                            </StyledTableCell>
                                                            <StyledTableCell>{row.item}</StyledTableCell>
                                                            <StyledTableCell>{row.displayedValue}</StyledTableCell>
                                                            <StyledTableCell>{row.type}</StyledTableCell>

                                                            <StyledTableCell>{row.reportingSource}</StyledTableCell>
                                                            <StyledTableCell>
                                                                <IconButton aria-label="create">
                                                                    <Icon color="dark">delete</Icon>
                                                                </IconButton>
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    )) : <StyledTableRow><StyledTableCell colSpan={8} align='center'>No Records Available</StyledTableCell></StyledTableRow>}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                }
            </form>
            <Box component="footer">
                <Copyright sx={{ pb: 3, px: 3 }} />
            </Box>
        </div>
    )
}

export default EditSurvey;