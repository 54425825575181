import * as React from 'react';

import { useEffect, useState, useMemo } from 'react';

import Header from "../../Header/Header";

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";

import { useTheme } from '@mui/material/styles';

import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import patientAddSuccessImage from '../../../assets/illustrations/patient-add-success.png';
import errorImage from '../../../assets/illustrations/error.png';

import countryList from 'react-select-country-list';
import { useForm, Controller, useFieldArray } from "react-hook-form";
import Copyright from '../../Copyright';
import { CircularProgress } from '@mui/material';



function CreateHospital() {

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        clearErrors,
        control
    } = useForm()

    const onSubmit = (data) => {
        console.log(JSON.stringify(data));
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(data),
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/hospitals`, requestOptions)
            .then(response => response.text())
            .then(result => {
                if (!JSON.parse(result).err) {
                    console.log(result)
                    setOpenDialog(true);
                } else {
                    console.log('error', JSON.parse(result).err)
                    setErrorDialog(true);
                    setErrMsg(JSON.parse(result).err);
                }
            })
            .catch(error => console.log('error', error));

    }

    let navigate = useNavigate();
    const [data, setData] = useState(null)
    const [hospitalFormData, setHospitalFormData] = useState({
        hospitalName: '',
        addresses: [{
            street: '',
            city: '',
            state: '',
            zipcode: '',
            country: ''
        }]
    })

    // const validateField = (name, value) => {
    //     let error = '';
    //     switch (name) {
    //         case 'hospitalName':
    //         case 'city':
    //         case 'street':
    //         case 'state':
    //         case 'country':
    //             if (!value) {
    //                 error = 'This field is required';
    //             }
    //             break;
    //         case 'zipcode':
    //             if (!value) {
    //                 error = 'This field is required';
    //             } else if (value.length != 6) {
    //                 error = 'ZIP Code must be 6 digits';
    //             }
    //             break;
    //         default:
    //             break;
    //     }
    //     return error;
    // };

    const handleChange = (e) => {
        setHospitalFormData((hospitalFormData) => ({
            ...hospitalFormData,
            [e.target.name]: e.target.value
        }));
    };

    // const handleSubmit = () => {
    //     console.log("Hospital Data", hospitalFormData);
    //     let myHeaders = new Headers();
    //     myHeaders.append("Content-Type", "application/json");
    //     const dob = new Date(hospitalFormData.dob);
    //     let requestOptions = {
    //         method: 'POST',
    //         headers: myHeaders,
    //         body: JSON.stringify({ ...hospitalFormData }),
    //         redirect: 'follow'
    //     };

    //     fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/hospitals", requestOptions)
    //         .then(response => response.text())
    //         .then(result => console.log(result))
    //         .catch(error => console.log('error', error));
    // };

    // Dialog Declarations
    const [openDialog, setOpenDialog] = React.useState(false);
    const [errorDialog, setErrorDialog] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState('');

    const [isLoading, setisLoading] = React.useState(false);

    // const handleClickOpen = () => {
    //     setOpenDialog(true);
    // };



    const handleClose = () => {
        setOpenDialog(false);
    };
    const closeErrorDialog = () => {
        setErrorDialog(false);
    };


    const options = useMemo(() => countryList().getData(), [])

    function backToHospitals() {
        navigate('/hospitals')
    }

    const theme = useTheme();

    return (
        <div>
            <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{ sx: { borderRadius: "16px" } }}
            >
                <DialogTitle id="alert-dialog-title">
                </DialogTitle>
                <DialogContent className='pad48'>
                    <DialogContentText id="alert-dialog-description">
                        <div className="layout layout-vertical layout-justify-center layout-item-center">
                            <img src={patientAddSuccessImage} alt="" />
                            <p className='dialog-description'>
                                Hospital added successfully!
                            </p>
                            <p></p>
                        </div>

                    </DialogContentText>
                </DialogContent>
                <DialogActions className="dialog-action-bg">
                    <div className='layout layout-item-center layout-justify-center layout-full-width mt16 mb16'>
                        <Button variant="text" onClick={backToHospitals}>Close</Button>
                        {/* <Button className="round-button ml24" variant="outlined" onClick={backToViewUser}>View User</Button> */}
                    </div>

                </DialogActions>
            </Dialog>
            <Dialog
                open={errorDialog}
                onClose={closeErrorDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{ sx: { borderRadius: "16px" } }}
            >
                <DialogTitle id="alert-dialog-title">
                </DialogTitle>
                <DialogContent className='pad48'>
                    <DialogContentText id="alert-dialog-description">
                        <div className="layout layout-vertical layout-justify-center layout-item-center">
                            <img src={errorImage} alt="" height="256px" />
                            <p className='dialog-description' style={{ 'color': '#B04933' }}>
                                Something Went Wrong!
                            </p>
                            <p style={{ textTransform: 'capitalize', textAlign: 'center' }}>{errMsg}</p>
                        </div>

                    </DialogContentText>
                </DialogContent>
                <DialogActions className="dialog-action-bg">
                    <div className='layout layout-item-center layout-justify-center layout-full-width mt16 mb16'>
                        <Button variant="text" onClick={closeErrorDialog}>Close</Button>
                    </div>

                </DialogActions>
            </Dialog>
            <form onSubmit={handleSubmit(onSubmit)}  >
                <Header>
                    <div className="layout layout-item-center layout-justify-space-between">
                        <h2>Create Hospital</h2>
                        <Stack spacing={2} direction="row">
                            <Button variant="text" onClick={backToHospitals}>Cancel</Button>
                            <Button className="round-button" variant="contained" type='submit'>Save Hospital</Button>
                        </Stack>
                    </div>
                </Header>
                <div style={{ padding: '24px' }}>
                    {/* <Box
                    id="userForm"
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                > */}
                    <Grid container>
                        <Grid item xs={12} md={10}>

                            <Card>
                                <CardContent>
                                    <p><b>Hospital Details</b></p>
                                    <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb16">
                                                <TextField
                                                    fullWidth
                                                    name="hospitalName"
                                                    label="Hospital Name"
                                                    type="text"
                                                    {...register('hospitalName', { required: true, maxLength: 30 })}
                                                    aria-label="Hospital Name"
                                                    aria-invalid={errors.hospitalName ? "true" : "false"}
                                                    tabIndex={1}
                                                    autoFocus
                                                />
                                                {errors.hospitalName && errors.hospitalName.type === "required" && <p role="alert" className="error-message">Please enter Hospital Name</p>}
                                                {errors.hospitalName && errors.hospitalName.type === "maxLength" && <p role="alert" className="error-message">Please enter a shorter Hospital Name</p>}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb16">
                                                <TextField
                                                    fullWidth
                                                    name="street"
                                                    label="Street"
                                                    type="text"
                                                    {...register('address.street', { required: true })}
                                                    aria-label="Street"
                                                    tabIndex={4}
                                                />
                                                {errors.address?.street && <p role="alert" className="error-message">Please enter Street details</p>}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={2}></Grid>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb16">
                                                <TextField
                                                    fullWidth
                                                    name="city"
                                                    label="City"
                                                    type="text"
                                                    {...register('address.city', { required: true })}
                                                    aria-label="City"
                                                    tabIndex={2}
                                                />
                                                {errors.address?.city && <p role="alert" className="error-message">Please enter City Name</p>}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb16">
                                                <TextField
                                                    fullWidth
                                                    name="zipcode"
                                                    label="ZIP Code"
                                                    type="text"
                                                    max="6"
                                                    {...register('address.zipcode', { required: true, maxLength: 6 })}
                                                    aria-label="ZIP Code"
                                                    aria-invalid={errors.zipcode ? "true" : "false"}
                                                    tabIndex={3}
                                                />
                                                {errors.address?.zipcode && errors.address?.zipcode.type === "required" && <p role="alert" className="error-message">Please enter ZIP Code</p>}
                                                {errors.address?.zipcode && errors.address?.zipcode.type === "maxLength" && <p role="alert" className="error-message">ZIP Code should be 6 characters</p>}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={2}></Grid>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb16">
                                                <TextField
                                                    fullWidth
                                                    name="state"
                                                    label="State"
                                                    type="text"
                                                    {...register('address.state', { required: true })}
                                                    aria-label="State"
                                                    tabIndex={5}
                                                />
                                                {errors.address?.state && <p role="alert" className="error-message">Please enter State</p>}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb16">
                                                <FormControl fullWidth>
                                                    <InputLabel id="country">Country</InputLabel>
                                                    <Select
                                                        name="country"
                                                        labelId="country-label"
                                                        id="country-select"
                                                        {...register('address.country', { required: true })}
                                                        defaultValue=""
                                                        label="Country"
                                                        aria-label="Country"
                                                        // onChange={(event) => {
                                                        //     setValue('address.country', event.target.value);
                                                        //     clearErrors('address.country');
                                                        // }}
                                                        tabIndex={6}
                                                    >
                                                        {options.map((option) => (
                                                            <MenuItem value={option.label}>
                                                                <div className='layout layout-item-center'>
                                                                    <img
                                                                        loading="lazy"
                                                                        width="20"
                                                                        srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`}
                                                                        src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`}
                                                                        alt=""
                                                                    />
                                                                    <span className='ml8'>{option.label}</span>
                                                                    {/* <span className='ml8'>{option.value}</span> */}
                                                                </div>
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                {errors.address?.country && <p role="alert" className="error-message">Please enter Country</p>}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={2}></Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    {/* <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    </Grid> */}
                    {/* </Box> */}
                </div>
            </form>
            <Box component="footer">
                <Copyright sx={{ pb: 3, px: 3 }} />
            </Box>
        </div>
    )

}

export default CreateHospital;