import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Icon } from '@mui/material';
import { Chip } from '@mui/material';
import { useEffect, useState } from 'react';
import Header from "../../Header/Header";

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import Grid from '@mui/material/Grid';
import { useNavigate, useParams } from "react-router-dom";

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import CircularProgress from '@mui/material/CircularProgress';
import Copyright from '../../Copyright';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    // [`&.${tableCellClasses.head}`]: {
    //   backgroundColor: theme.palette.common.black,
    //   color: theme.palette.common.white,
    // },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // '&:nth-of-type(odd)': {
    //   backgroundColor: theme.palette.action.hover,
    // },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function ViewStudy() {
    let { studyId } = useParams();
    let navigate = useNavigate();
    const [data, setData] = useState(null)
    const [studyFormData, setStudyFormData] = useState({
        firstName: '',
        lastName: '',
        dob: '',
        phone: '',
        email: '',
        gender: '',
        roles: [],
        hospitalName: ''
    })

    const [usersData, setUsersData] = useState({});
    const [patientsData, setPatientsData] = useState({});
    const [isLoading, setisLoading] = React.useState(false);


    function backToStudies() {
        navigate('/studies')
    }

    function gotoEditStudy(uid) {
        navigate(`/studies/editstudy/${uid}`)
    }
    function formatDate(date){
        if (!date || date == null || date == '' || date=='0001-01-01T00:00:00Z') {
            return '';
        }
        return dayjs(date,'YYYY-MM-DD HH:mm:ss').format("DD-MM-YYYY");
    
    }
    useEffect(() => {
        setisLoading(true);
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/studies/` + studyId)
            .then((res) => res.json())
            .then((data) => {
                setStudyFormData({
                    ...data['data']
                });
                if (data['data'].patients && data['data'].patients.length > 0) {
                    fetchPatientsData(data['data'].patients);
                }
                if (data['data'].users && data['data'].users.length > 0) {
                    fetchUsersData(data['data'].users);
                }
                setisLoading(false);
                console.log("Study Data", data);

            })
    }, [])

    function fetchPatientsData(uids) {
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/patients`)
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    let selectedPatients = [];
                    uids.map((uid) => {
                        selectedPatients.push(data['data']['data'].filter((patient) => patient.uid == uid)[0])
                    });
                    console.log("SP", selectedPatients);
                    setPatientsData(selectedPatients);
                    console.log("User data", data['data']['data'])
                }
            })
    }

    function fetchUsersData(uids) {
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/users`)
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    let selectedUsers = [];
                    uids.map((uid) => {
                        selectedUsers.push(data['data']['data'].filter((user) => user.uid == uid)[0])
                    });
                    setUsersData(selectedUsers);
                    console.log("User data", data['data']['data'])
                }
            })
    }

    const [tabValue, setTabValue] = React.useState('1');

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <div>

            <Header>
                <div className="layout layout-item-center layout-justify-space-between">
                    <h2>View Study - {studyFormData?.studyName} - {studyFormData?.pid}</h2>
                    <Stack spacing={2} direction="row">
                        <Button variant="text" onClick={backToStudies}>Cancel</Button>
                        <Button className="round-button" variant="contained" onClick={() => gotoEditStudy(studyFormData.uid)}>Edit</Button>
                    </Stack>
                </div>
            </Header>

            {isLoading == true ?
                <div className='layout layout-item-center layout-justify-center loadingProgress'>
                    <CircularProgress />
                </div>
                :
                <div style={{ padding: '24px' }}>
                    {/* <Box
                    id="userForm"
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                > */}
                    <Grid container>
                        <Grid item xs={12} md={10}>
                            <Card>
                                <CardContent>
                                    <p className='mb24'><strong>Basic Details</strong></p>
                                    <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb24">
                                                <p className='view-label mb8'>Study ID</p>
                                                <p className='view-value'>{studyFormData?.pid}</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb24">
                                                <p className='view-label mb8'>Status</p>
                                                <p className='view-value'>{studyFormData?.status}</p>
                                            </div>
                                        </Grid>
                                        {studyFormData.status == 'Active' && <><Grid item xs={12} md={5}>
                                            <div className="mb24">
                                                <p className='view-label mb8'>Start Date</p>
                                                <p className='view-value'>{formatDate(studyFormData?.startDate)}</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb24">
                                                <p className='view-label mb8'>End Date</p>
                                                <p className='view-value'>{formatDate(studyFormData?.endDate)}</p>
                                            </div>
                                        </Grid>
                                        </>}
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <p></p>
                    <Grid container>
                        <Grid item xs={12} md={10}>
                            <Card>
                                <CardContent>
                                    <p className='mb24'><strong>Study Details</strong></p>
                                    <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb24">
                                                <p className='view-label mb8'>Study Name</p>
                                                <p className='view-value'>{studyFormData?.studyName}</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb24">
                                                <p className='view-label mb8'>Study Type</p>
                                                <p className='view-value'>{studyFormData?.studyType ? studyFormData?.studyType : "Not Available"}</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={2}></Grid>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb24">
                                                <p className='view-label mb8'>Survey Set</p>
                                                <p className='view-value'>{studyFormData?.surveySetName ? studyFormData?.surveySetName : "Not Available"}</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb24">
                                                <p className='view-label mb8'>Survey Subset</p>
                                                <p className='view-value'>{studyFormData?.surveySubsetName ? studyFormData?.surveySubsetName : "Not Available"}</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={2}></Grid>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb24">
                                                <p className='view-label mb8'>Description</p>
                                                <p className='view-value'>{studyFormData?.studyDescription}</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb24">
                                                <p className='view-label mb8'>Principal Investigator Name</p>
                                                <p className='view-value'>{studyFormData?.principalInvestigator}</p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <p></p>

                    <Grid container>
                        <Grid item xs={12} md={10}>
                            <Card>
                                <CardContent>
                                    <p className='mb24'><strong>Hospital Details</strong></p>
                                    <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb24">
                                                <p className='view-label mb8'>Hospital Name</p>
                                                <p className='view-value'>{studyFormData?.hospitalName}</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb24">
                                                <p className='view-label mb8'>Location</p>
                                                <p className='view-value'>{studyFormData?.location ? studyFormData?.location : "Not Available"}</p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <p></p>

                    <Grid container>
                        <Grid item xs={12} md={10}>
                            <Card>
                                <CardContent>
                                    <p className='mb24'><strong>Consent Form</strong></p>
                                    <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb24">
                                                <p className='view-label mb8'>Consent Title</p>
                                                <p className='view-value'>{studyFormData?.consentTitle ? studyFormData?.consentTitle : "Not Available"}</p>
                                            </div>
                                            <div className="mb24">
                                                <p className='view-label mb8'>Patient Consent Form</p>
                                                <p className='view-value'>{studyFormData?.patientConsentForm ? studyFormData?.patientConsentForm : "Not Available"}</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb24">
                                                <p className='view-label mb8'>Language</p>
                                                <p className='view-value'>{studyFormData?.language ? studyFormData?.language : "Not Available"}</p>
                                            </div>
                                            <div className="mb24">
                                                <p className='view-label mb8'>PI Consent Form</p>
                                                <p className='view-value'>{studyFormData?.piConsentForm ? studyFormData?.piConsentForm : "Not Available"}</p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <p></p>
                    <Grid container>
                        <Grid item xs={12} md={10}>
                            <Card className=''>
                                <CardContent>
                                    <Box sx={{ width: '100%', typography: 'body1' }}>
                                        <TabContext value={tabValue}>
                                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                <div className='layout layout-item-center layout-justify-space-between'>
                                                    <div>
                                                        <TabList onChange={handleTabChange} aria-label="">
                                                            <Tab label="Patients" value="1" />
                                                            <Tab label="Users" value="2" />
                                                        </TabList>
                                                    </div>

                                                </div>

                                            </Box>
                                            <TabPanel value="1">
                                                <TableContainer>
                                                    <Table sx={{ minWidth: 700 }} aria-label="customized table" size="small">
                                                        <TableHead>
                                                            <TableRow>
                                                                <StyledTableCell>Patient ID</StyledTableCell>
                                                                <StyledTableCell>First Name</StyledTableCell>
                                                                <StyledTableCell>Last Name</StyledTableCell>
                                                                <StyledTableCell>Gender</StyledTableCell>
                                                                <StyledTableCell>Date of Birth</StyledTableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {patientsData.length > 0 ? patientsData.map((row) => (
                                                                <StyledTableRow key={row.pid}>
                                                                    <StyledTableCell component="th" scope="row">
                                                                        {/* <NavLink to={`/viewpatient/${row.uid}`} exact activeClassName="active"> */}
                                                                        {row.pid}
                                                                        {/* </NavLink> */}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell>{row.firstName}</StyledTableCell>
                                                                    <StyledTableCell>{row.lastName}</StyledTableCell>
                                                                    <StyledTableCell>{row.gender}</StyledTableCell>
                                                                    <StyledTableCell>{dayjs(row.dob).format("DD-MM-YYYY")}</StyledTableCell>
                                                                </StyledTableRow>
                                                            )) : <StyledTableRow><StyledTableCell colSpan={8} align='center'>No Records Available</StyledTableCell></StyledTableRow>}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </TabPanel>
                                            <TabPanel value="2">
                                                <TableContainer >
                                                    <Table sx={{ minWidth: 700 }} aria-label="customized table" size="small">
                                                        <TableHead>
                                                            <TableRow>
                                                                <StyledTableCell>User ID</StyledTableCell>
                                                                <StyledTableCell>First Name</StyledTableCell>
                                                                <StyledTableCell>Last Name</StyledTableCell>
                                                                <StyledTableCell>Status</StyledTableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {usersData.length > 0 ? usersData.map((row) => (
                                                                <StyledTableRow key={row.pid}>
                                                                    <StyledTableCell component="th" scope="row">
                                                                        {/* <NavLink to={`/viewuser/${row.uid}`} exact activeClassName="active"> */}
                                                                        {row.pid}
                                                                        {/* </NavLink> */}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell>{row.firstName}</StyledTableCell>
                                                                    <StyledTableCell>{row.lastName}</StyledTableCell>
                                                                    <StyledTableCell><Chip label={row.status} variant="outlined" /></StyledTableCell>
                                                                </StyledTableRow>
                                                            )) : <StyledTableRow><StyledTableCell colSpan={8} align='center'>No Records Available</StyledTableCell></StyledTableRow>}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </TabPanel>
                                        </TabContext>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    {/* </Box> */}
                </div>
            }
            <Box component="footer">
                <Copyright sx={{ pb: 3, px: 3 }} />
            </Box>
        </div>
    )
}



export default ViewStudy;