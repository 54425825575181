import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Layout from "./components/Layout/Layout";
import Login from "./components/Login/Login";
import ForgotPassword from "./components/Forgotpassword/ForgotPassword";
import Signup from "./components/Signup/Signup";

import Dashboard from "./components/Dashboard/Dashboard";

import Users from "./components/UserModule/Users/Users";
import CreateUser from "./components/UserModule/CreateUser/CreateUser";
import ViewUser from "./components/UserModule/ViewUser/ViewUser";
import EditUser from "./components/UserModule/EditUser/EditUser";

import Patients from "./components/PatientModule/Patients/Patients";
import CreatePatient from "./components/PatientModule/CreatePatient/CreatePatient";
import ViewPatient from "./components/PatientModule/ViewPatient/ViewPatient";
import EditPatient from "./components/PatientModule/EditPatient/EditPatient";

import Hospitals from "./components/HospitalModule/Hospitals/Hospitals";
import CreateHospital from "./components/HospitalModule/CreateHospital/CreateHospital";
import ViewHospital from "./components/HospitalModule/ViewHospital/ViewHospital";
import EditHospital from "./components/HospitalModule/EditHospital/EditHospital";

import Surveys from "./components/SurveyModule/Surveys/Surveys";
import CreateSurvey from "./components/SurveyModule/CreateSurvey/CreateSurvey";
import ViewSurvey from "./components/SurveyModule/ViewSurvey/ViewSurvey";
import EditSurvey from "./components/SurveyModule/EditSurvey/EditSurvey";

import Studies from "./components/StudyModule/Studies/Studies";
import CreateStudy from "./components/StudyModule/CreateStudy/CreateStudy";
import ViewStudy from "./components/StudyModule/ViewStudy/ViewStudy";
import EditStudy from "./components/StudyModule/EditStudy/EditStudy";

import Settings from "./components/Settings/Settings";

import ViewProfile from "./components/UserProfile/ViewProfile/ViewProfile";
import EditProfile from "./components/UserProfile/EditProfile/EditProfile";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import CreatePassword from "./components/CreatePassword/CreatePassword";
import VerifiedCreatePassword from "./components/VericationFlow/VerifiedCreatePassword";
import AlreadyVerified from "./components/VericationFlow/VerificationAlreadyVerified";
import { AuthProvider, useAuth } from "./auth.context";
import { CircularProgress } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: [
      "SF Pro Display",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  palette: {
    mode: "light",
    primary: {
      main: "#3456FF",
      light: "#445fec",
      dark: "#1636d8",
    },
    secondary: {
      main: "#FFFFFF",
    },
    dark: {
      main: "#000000",
    },
  },
  overrides: {},
});

function ProtectedRoute({ children }) {
  const { authState } = useAuth();
  
  if (!authState.isInitialized) {
    return <CircularProgress />;
  }
  
  if (!authState.authenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
}

function AppRoutes() {
  const { authState } = useAuth();

  if (!authState.isInitialized) {
    return <CircularProgress />;
  }

  return (
    <Routes>
      <Route path="/" element={authState.authenticated ? <Navigate to="/dashboard" replace /> : <Login />} />
      <Route path="/login" element={authState.authenticated ? <Navigate to="/dashboard" replace /> : <Login />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/account-already-verified" element={<AlreadyVerified />} />
      <Route path="/account-verified" element={<VerifiedCreatePassword />} />
      <Route path="/create-password" element={<CreatePassword />} />
      <Route path="/signup" element={<Signup />} />

      <Route element={<Layout />}>
        <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
        <Route path="/users" element={<ProtectedRoute><Users /></ProtectedRoute>} />
        <Route path="/users/createuser" element={<ProtectedRoute><CreateUser /></ProtectedRoute>} />
        <Route path="/users/viewuser/:userId" element={<ProtectedRoute><ViewUser /></ProtectedRoute>} />
        <Route path="/users/edituser/:userId" element={<ProtectedRoute><EditUser /></ProtectedRoute>} />
        <Route path="/patients" element={<ProtectedRoute><Patients /></ProtectedRoute>} />
        <Route path="/patients/createpatient" element={<ProtectedRoute><CreatePatient /></ProtectedRoute>} />
        <Route path="/patients/viewpatient/:patientId" element={<ProtectedRoute><ViewPatient /></ProtectedRoute>} />
        <Route path="/patients/editpatient/:patientId" element={<ProtectedRoute><EditPatient /></ProtectedRoute>} />
        <Route path="/hospitals" element={<ProtectedRoute><Hospitals /></ProtectedRoute>} />
        <Route path="/hospitals/createhospital" element={<ProtectedRoute><CreateHospital /></ProtectedRoute>} />
        <Route path="/hospitals/viewhospital/:hospitalId" element={<ProtectedRoute><ViewHospital /></ProtectedRoute>} />
        <Route path="/hospitals/edithospital/:hospitalId" element={<ProtectedRoute><EditHospital /></ProtectedRoute>} />
        <Route path="/surveys" element={<ProtectedRoute><Surveys /></ProtectedRoute>} />
        <Route path="/surveys/createsurvey" element={<ProtectedRoute><CreateSurvey /></ProtectedRoute>} />
        <Route path="/surveys/viewsurvey/:surveyId" element={<ProtectedRoute><ViewSurvey /></ProtectedRoute>} />
        <Route path="/surveys/editsurvey/:surveyId" element={<ProtectedRoute><EditSurvey /></ProtectedRoute>} />
        <Route path="/studies" element={<ProtectedRoute><Studies /></ProtectedRoute>} />
        <Route path="/studies/createstudy" element={<ProtectedRoute><CreateStudy /></ProtectedRoute>} />
        <Route path="/studies/viewstudy/:studyId" element={<ProtectedRoute><ViewStudy /></ProtectedRoute>} />
        <Route path="/studies/editstudy/:studyId" element={<ProtectedRoute><EditStudy /></ProtectedRoute>} />
        <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
        <Route path="/profile/:profileId" element={<ProtectedRoute><ViewProfile /></ProtectedRoute>} />
        <Route path="/profile/edit" element={<ProtectedRoute><EditProfile /></ProtectedRoute>} />

      </Route>
    </Routes>
  );
}
function App({ children }) {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <AuthProvider>
          <AppRoutes />
          </AuthProvider>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;


