import * as React from 'react';
import { Chip, Stack, Card, CardContent, Grid, Box } from '@mui/material';
import Header from "../../Header/Header";
import dayjs from 'dayjs';
import Copyright from '../../Copyright';
import { useAuth } from '../../../auth.context';

function ViewProfile() {
    const { authState:{user} } = useAuth(); // Use the auth context to get user data

    const [roleList, setRoleList] = React.useState([]);

    React.useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/roles`)
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    setRoleList(data['data']['data']);
                }
            });
    }, []);

    return (
        <div>
            <Header>
                <div className="layout layout-item-center layout-justify-space-between">
                    <h2>My Profile</h2>
                </div>
            </Header>

            <div style={{ padding: '24px' }}>
                <p className='view-value'>Hello {user.firstName} {user.lastName}!</p>
                <p></p>
                <Grid container>
                    <Grid item xs={12} md={10}>
                        <Card>
                            <CardContent>
                                <p className='mb24'><strong>User Details</strong></p>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={5}>
                                        <div className="mb24">
                                            <p className='view-label mb8'>User ID</p>
                                            <p className='view-value'>{user.pid}</p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                        <div className="mb24">
                                            <p className='view-label mb8'>Status</p>
                                            <p className='view-value'>{user.status}</p>
                                        </div>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <p></p>
                <Grid container>
                    <Grid item xs={12} md={10}>
                        <Card>
                            <CardContent>
                                <p className='mb24'><strong>Personal Details</strong></p>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={5}>
                                        <div className="mb24">
                                            <p className='view-label mb8'>First Name</p>
                                            <p className='view-value'>{user.firstName}</p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                        <div className="mb24">
                                            <p className='view-label mb8'>Last Name</p>
                                            <p className='view-value'>{user.lastName}</p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                        <div className="mb24">
                                            <p className='view-label mb8'>Gender</p>
                                            <p className='view-value'>{user.gender}</p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                        <div className="mb24">
                                            <p className='view-label mb8'>Date of Birth</p>
                                            <p className='view-value'>{dayjs(user.dob).format('DD/MM/YYYY')}</p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                        <div className="mb24">
                                            <p className='view-label mb8'>Phone</p>
                                            <p className='view-value'>{user.phone || "Not Available"}</p>
                                        </div>
                                    </Grid>                                                                            
                                    <Grid item xs={12} md={5}>
                                        <div className="mb24">
                                            <p className='view-label mb8'>Email</p>
                                            <p className='view-value'>{user.email}</p>
                                        </div>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <p></p>
                <Grid container>
                    <Grid item xs={12} md={10}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={5}>
                                        <p className='mb24'><strong>Hospital Details</strong></p>
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                        <p className='mb24'><strong>Role Details</strong></p>
                                    </Grid>
                                </Grid>
                                {user.roles?.length > 0 ? user.roles.map((hospitalRole, index) => (
                                    <div key={index}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={5}>
                                                <div className="mb24">
                                                    <p className='view-value'>{hospitalRole.hospitalName}</p>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={5}>
                                                <div className="mb24 view-value">
                                                    <Stack direction="row" spacing={1}>
                                                        {hospitalRole.roleUIDs?.length > 0 ? hospitalRole.roleUIDs.map((roleUID) => (
                                                            <Chip key={roleUID} label={roleList?.find((item) => item.uid === roleUID)?.name || ''} variant="outlined" />
                                                        )) : 'Not Available'}
                                                    </Stack>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        {index < user.roles.length - 1 && (
                                            <Grid container spacing={2}>
                                                <Grid item xs={8}>
                                                    <div style={{ margin: '0px 0 16px', backgroundColor: '#f5f5f5', height: '1px' }}></div>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </div>
                                )) : 'Not Available'}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
            <Box component="footer">
                <Copyright sx={{ pb: 3, px: 3 }} />
            </Box>
        </div>
    );
}

export default ViewProfile;