import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            {new Date().getFullYear()}{' '}
            <Link color="inherit" target="_blank" href="https://opendialysis.org/index.html">
                Open Dialysis Project
            </Link>
            {'. version 1.2.12'}
        </Typography>
    );
}

export default Copyright;