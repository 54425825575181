import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Icon from '@mui/material/Icon';
import { Chip } from '@mui/material';

import { useEffect, useState } from 'react';
import Header from '../../Header/Header';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";

import IconButton from '@mui/material/IconButton';

import { NavLink } from "react-router-dom";
import dayjs from 'dayjs';

import CircularProgress from '@mui/material/CircularProgress';
import Copyright from '../../Copyright';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    // [`&.${tableCellClasses.head}`]: {
    //   backgroundColor: theme.palette.common.black,
    //   color: theme.palette.common.white,
    // },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // '&:nth-of-type(odd)': {
    //   backgroundColor: theme.palette.action.hover,
    // },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

let rows = []

function Surveys() {

    let navigate = useNavigate();
    const [data, setData] = useState({});
    const [cursor, setCursor] = React.useState(0);
    const [direction, setDirection] = React.useState('next');
    const [page, setpage] = React.useState(0);
    const [limit, setLimit] = React.useState(10);
    const [isLoading, setisLoading] = React.useState(false);

    function createSurvey() {
        navigate('/surveys/createsurvey');
    }

    function editSurvey(uid) {
        console.log("uid", uid);
        navigate('/surveys/editsurvey/' + uid);
    }

    const handleChangePage = (event, newPage) => {
        if (page < newPage) {
            // console.log("cursor direction forward - ",usersData.pageInfo.endCursor);
            setDirection('next');
            setpage(newPage);
            setCursor(data.pageInfo.endCursor);
        } else if (page > newPage) {
            // console.log("cursor direction backward - ",usersData.pageInfo.startCursor);
            setpage(newPage);
            setDirection('prev');
            setCursor(data.pageInfo.startCursor);
        }

    };

    const handleChangeRowsPerPage = (event) => {
        setLimit(prev => parseInt(event.target.value, 10));
        setDirection('next');
        setCursor(0);
    };

    function fetchSurveysData() {
        setisLoading(true);
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/surveys?limit=${limit}&cursor=${cursor}&cursor_direction=${direction}&status=all`)
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    setData(data['data'])
                    setisLoading(false);
                    console.log("Surveys data", data['data']['data'])
                }
            })
    }

    useEffect(() => {
        fetchSurveysData();
    }, [cursor, limit]);

    return (
        <div >
            {/* <img src={UC} alt="COming Soon" width="512px" /> */}
            <Header>
                <div className="layout layout-item-center layout-justify-space-between">
                    <h2>Surveys</h2>
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '50ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField id="outlined-basic" label="Search" variant="outlined" />
                    </Box>

                    <Stack spacing={2} direction="row">
                        <Button variant="text">Export</Button>
                        <Button variant="text">Import</Button>
                        <Button className="round-button" variant="contained" onClick={createSurvey}>Add Survey</Button>

                    </Stack>
                </div>
            </Header>

            {isLoading == true ?
                <div className='layout layout-item-center layout-justify-center loadingProgress'>
                    <CircularProgress />
                </div>
                :
                <div style={{ padding: '24px' }}>
                    <Card>
                        <TableContainer>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table" size="small">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Survey ID</StyledTableCell>
                                        <StyledTableCell>Survey Name</StyledTableCell>
                                        <StyledTableCell>Description</StyledTableCell>
                                        <StyledTableCell>Created On</StyledTableCell>
                                        <StyledTableCell>Last Edited On</StyledTableCell>
                                        <StyledTableCell>Used in Studies</StyledTableCell>
                                        <StyledTableCell>Status</StyledTableCell>
                                        <StyledTableCell>Actions</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data['data']?.length > 0 ? data['data']?.map((row) => (
                                        <StyledTableRow key={row.pid}>
                                            <StyledTableCell component="th" scope="row">
                                                <NavLink to={`/surveys/viewsurvey/${row.uid}`} exact activeClassName="active">
                                                    {row.pid}
                                                </NavLink>
                                            </StyledTableCell>
                                            <StyledTableCell>{row.surveyName}</StyledTableCell>
                                            <StyledTableCell width={'500px'}>{row.surveyDescription}</StyledTableCell>
                                            <StyledTableCell>{dayjs(row.createdAt).format("DD/MM/YYYY")}</StyledTableCell>
                                            <StyledTableCell>{dayjs(row.updatedAt).format("DD/MM/YYYY")}</StyledTableCell>
                                            <StyledTableCell>{row.usedIn}</StyledTableCell>
                                            {/* <StyledTableCell>{row.status}</StyledTableCell> */}
                                            <StyledTableCell><Chip label={row.status} variant="outlined" /></StyledTableCell>
                                            <StyledTableCell>
                                                <div className='layout layout-item-center'>
                                                    <IconButton aria-label="create" title='Edit'>
                                                        <Icon color="dark" onClick={() => editSurvey(row.uid)}>create_outlined</Icon>
                                                    </IconButton>
                                                    <IconButton aria-label="archive" title='Archive'>
                                                        <Icon color="dark">archive_outlined</Icon>
                                                    </IconButton>
                                                </div>

                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )) : <StyledTableRow><StyledTableCell colSpan={8} align='center'>No Records Available</StyledTableCell></StyledTableRow>}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 50]}
                            component="div"
                            count={data?.totalCount}
                            rowsPerPage={limit}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Card>
                </div>
            }
            <Box component="footer">
                <Copyright sx={{ pb: 3, px: 3 }} />
            </Box>
        </div>
    )
}

export default Surveys;