import React, { useEffect, useState } from "react";
import "./Login.css";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import logo from "../../assets/logos/PROCare_logo.png";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../auth.context";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import Copyright from "../Copyright";
import errorImage from "../../assets/illustrations/error.png";
import StaticOverlay from "../Shared/static-overlay";

const schema = z.object({
	email: z
		.string()
		.min(1, "Email is required")
		.email("Invalid email format"),
	password: z
		.string()
		.min(1, "Password is required")
		.min(6, "Password must be at least 6 characters"),
});

function Login() {
	const { authState, login } = useAuth();
	const [showPassword, setShowPassword] = useState(false);
	const [loading, setLoading] = useState(false);
	const [errorDialog, setErrorDialog] = useState(false);
	const [errMsg, setErrMsg] = useState("");
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: zodResolver(schema),
	});

	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const onSubmit = async (data) => {
		setLoading(true);
		login(data.email, data.password);
	};

	useEffect(() => {
		if (authState.authenticated) {
			navigate("/dashboard");
		}
		if (authState.error) {
			setErrMsg(authState.error);
			setErrorDialog(true);
			setLoading(false);
		}
	}, [authState, navigate]);

	const handleClose = () => {
		setErrorDialog(false);
	};

	return (
		<Grid container component="main" sx={{ height: "100vh" }}>
			<StaticOverlay />
			<Grid item xs={12} sm={6} md={6} elevation={6} square>
				<Box
					sx={{
						mx: 4,
						my: 8,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<Grid container>
						<Grid item xs={12} md={1}></Grid>
						<Grid item xs={12} md={10}>
							<Card>
								<CardContent>
									<img
										src={logo}
										width="194px"
										alt="Logo"
										className="mt24 mb24"
									/>
									<h1>Login</h1>
									<form onSubmit={handleSubmit(onSubmit)}>
										<Box sx={{ mt: 3 }}>
											<Grid container spacing={2}>
												<Grid item xs={12}>
													<TextField
														label="Email"
														variant="outlined"
														fullWidth
														autoComplete="email"
														autoFocus
														{...register("email")}
														error={!!errors.email}
														helperText={errors.email?.message}
													/>
												</Grid>
												<Grid item xs={12}>
													<TextField
														label="Password"
														variant="outlined"
														type={showPassword ? "text" : "password"}
														fullWidth
														autoComplete="current-password"
														{...register("password")}
														error={!!errors.password}
														helperText={errors.password?.message}
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">
																	<IconButton
																		aria-label="toggle password visibility"
																		onClick={handleClickShowPassword}
																		onMouseDown={handleMouseDownPassword}
																		edge="end"
																	>
																		{showPassword ? (
																			<VisibilityOff />
																		) : (
																			<Visibility />
																		)}
																	</IconButton>
																</InputAdornment>
															),
														}}
													/>
												</Grid>
											</Grid>
											<Grid container justifyContent="flex-end" sx={{ pt: 1 }}>
												<Grid item>
													<Link to="/forgotpassword" variant="body2">
														Forgot password?
													</Link>
												</Grid>
											</Grid>
											<Button
												className="round-button"
												variant="contained"
												type="submit"
												disabled={loading}
												sx={{ mt: 3, mb: 2 }}
											>
												{loading ? <CircularProgress size={24} /> : "Sign In"}
											</Button>
										</Box>
									</form>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
				</Box>
				<Box component="footer" sx={{ pb: 3, px: 3 }}>
					<Copyright />
				</Box>
			</Grid>
			<Dialog
				open={errorDialog}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				PaperProps={{ sx: { borderRadius: "16px" } }}
			>
				<DialogTitle id="alert-dialog-title"></DialogTitle>
				<DialogContent className="pad48">
					<DialogContentText id="alert-dialog-description">
						<div className="layout layout-vertical layout-justify-center layout-item-center">
							<img src={errorImage} alt="Error" height="256px" />
							<p className="dialog-description" style={{ color: "#B04933" }}>
								Something Went Wrong!
							</p>
							<p style={{ textTransform: "capitalize", textAlign: "center" }}>
								{errMsg}
							</p>
						</div>
					</DialogContentText>
				</DialogContent>
				<DialogActions className="dialog-action-bg">
					<div className="layout layout-item-center layout-justify-center layout-full-width mt16 mb16">
						<Button variant="text" onClick={handleClose}>
							Close
						</Button>
					</div>
				</DialogActions>
			</Dialog>
		</Grid>
	);
}

export default Login;
