import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Icon } from '@mui/material';
import { Chip } from '@mui/material';
import { useEffect, useState, useMemo } from 'react';
import Header from '../../Header/Header';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import Grid from '@mui/material/Grid';
import { useNavigate, useParams } from "react-router-dom";

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import userAddSuccessImage from '../../../assets/illustrations/user-add-success.png';
import errorImage from '../../../assets/illustrations/error.png';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

import { useForm, Controller, useFieldArray } from "react-hook-form"
import Copyright from '../../Copyright';
function EditProfile() {
    let { userId } = useParams();
    let navigate = useNavigate();

    const [userFormData, setUserFormData] = useState({
        firstName: '',
        lastName: '',
        dob: dayjs(null),
        phone: '',
        email: '',
        gender: '',
        roles: [{ 'id': 0, 'hospitalUID': '', 'roleUIDs': [] }],
        hospitalName: ''
    })
    
    const [hospitalrolecontrol, setHospitalRoleControl] = useState([]);
    const [hrcCounter, sethrcCounter] = useState(1)
    const [hospitalList, setHospitalList] = useState([])
    const [configList, setConfigList] = useState([])
    const [roleList, setRoleList] = useState([])
    const [userID, setUserID] = useState('')

    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/hospitals`)
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    setHospitalList(data['data']['data']);
                }
            })
    }, [])
    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/configs`)
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    setConfigList(data['data']['data']);
                }
            })
    }, [])
    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/roles`)
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    setRoleList(data['data']['data']);
                }
            })
    }, [])

    const setDoB = (e) => {
        console.log("dob", e.format('DD-MM-YYYY'));
        setUserFormData((userFormData) => ({
            ...userFormData,
            ['dob']: e.format('DD-MM-YYYY')
        }));
    };
    
    const [message, setMessage] = useState('');

    function backToProfile() {
        navigate(`/profile/${userId}`)
    }

    function getStyles(name, personName, theme) {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    const theme = useTheme();

    const handleMultiselectChipChange = (event) => {
        const {
            target: { value },
        } = event;
        let roles = { 'id': 0, 'hospitalUID': userFormData.roles[0].hospitalUID, 'roleUIDs': value === 'string' ? value.split(',') : value };
        setUserFormData((userFormData) => ({
            ...userFormData,
            ['roles']: [roles]
        }));
    };

    // Dialog Declarations
    const [openDialog, setOpenDialog] = React.useState(false);
    const [errorDialog, setErrorDialog] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState('');

    // const handleClickOpen = () => {
    //     setOpenDialog(true);
    // };

    const handleClose = () => {
        setOpenDialog(false);
    };
    const closeErrorDialog = () => {
        setErrorDialog(false);
    };

    const [userdata, setData] = useState(null)
    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/users/` + userId)
            .then((res) => res.json())
            .then((data) => {
                // console.log("type", typeof (data['data']['roles']))
                setData(data['data']);

            })
    }, [])

    const { register, handleSubmit, control } = useForm({
        defaultValues: async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/users/` + userId);
            const data = await response.json();
            // console.log(data);
            return data['data'];
        },
    })
    
    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "roles", // unique name for your Field Array
    });

    const onSubmit = (data) => {
        console.log(JSON.stringify(data));
        data['phone'] = '+' + data['phone'];
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: JSON.stringify({ ...data }),
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/users/` + userId, requestOptions)
            .then(response => response.text())
            .then(result => {
                if (!JSON.parse(result).err) {
                    console.log(result)
                    setOpenDialog(true);
                    setUserID(JSON.parse(result).data.uid);
                } else {
                    console.log('error', JSON.parse(result).err)
                    setErrorDialog(true);
                    setErrMsg(JSON.parse(result).err);
                }

            })
            .catch(error => {
                console.log('error', error)
                setErrorDialog(true);
            });

    }

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}  >
                <Header>
                    <div className="layout layout-item-center layout-justify-space-between">
                        <h2>My Profile</h2>
                        <Stack spacing={2} direction="row">
                            <Button variant="text" onClick={backToProfile}>Cancel</Button>
                            <Button className="round-button" variant="contained" type='submit'>Save</Button>
                        </Stack>
                    </div>
                </Header>

                <div style={{ padding: '24px' }}>
                    <p className='view-value'>Hello {userFormData.firstName} {userFormData.lastName}!</p>
                </div>
            </form>
            <Box component="footer">
                <Copyright sx={{ pb: 3, px: 3 }} />
            </Box>
        </div>
    )
}

export default EditProfile;
