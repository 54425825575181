import React from "react";
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { Icon } from '@mui/material';
import { Chip } from '@mui/material';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Drawer from '@mui/material/Drawer';

import Header from "../../Header/Header";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

import { useNavigate } from "react-router-dom";

import IconButton from '@mui/material/IconButton';

import { NavLink } from "react-router-dom";

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import CircularProgress from '@mui/material/CircularProgress';
import Copyright from "../../Copyright";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    // [`&.${tableCellClasses.head}`]: {
    //   backgroundColor: theme.palette.common.black,
    //   color: theme.palette.common.white,
    // },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // '&:nth-of-type(odd)': {
    //   backgroundColor: theme.palette.action.hover,
    // },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

let rows = []

function Hospitals(props) {
    let navigate = useNavigate();
    const [data, setData] = useState({})
    const [cursor, setCursor] = React.useState(0);
    const [direction, setDirection] = React.useState('next');
    const [page, setpage] = React.useState(0);
    const [limit, setLimit] = React.useState(10);

    const [isLoading, setisLoading] = React.useState(false);

    // const { window } = props;
    // const [mobileOpen, setMobileOpen] = React.useState(false);
    // const handleDrawerToggle = () => {
    //     setMobileOpen((prevState) => !prevState);
    // };
    // const container = window !== undefined ? () => window().document.body : undefined;

    function createHospital() {
        navigate('/hospitals/createhospital');
    }

    function editHospital(uid) {
        console.log("uid", uid);
        navigate('/hospitals/edithospital/' + uid);
    }

    const handleChangePage = (event, newPage) => {
        if (page < newPage) {
            setDirection('next');
            setpage(newPage);
            setCursor(data.pageInfo.endCursor);
        } else if (page > newPage) {
            setpage(newPage);
            setDirection('prev');
            setCursor(data.pageInfo.startCursor);
        }

    };

    const handleChangeRowsPerPage = (event) => {
        setLimit(prev => parseInt(event.target.value, 10));
        setDirection('next');
        setCursor(0);
    };

    function fetchHospitalsData() {
        setisLoading(true);
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/hospitals?limit=${limit}&cursor=${cursor}&cursor_direction=${direction}`)
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    setData(data['data']);
                    setisLoading(false);
                    console.log("Hospitals data", data['data']['data'])
                }
            }).catch(error => console.log('error', error));
    }

    useEffect(() => {
        fetchHospitalsData();
    }, [cursor, limit]);

    return (
        <div>
            <Header>
                <div className="layout layout-item-center layout-justify-space-between">
                    <h2>Hospitals</h2>
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '50ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField id="outlined-basic" label="Search" variant="outlined" />
                    </Box>

                    <Stack spacing={2} direction="row">
                        <Button variant="text">Export</Button>
                        <Button variant="text">Import</Button>
                        <Button className="round-button" variant="contained" onClick={createHospital}>Add Hospital</Button>
                    </Stack>
                </div>
            </Header>

            {isLoading == true ?
                <div className='layout layout-item-center layout-justify-center loadingProgress'>
                    <CircularProgress />
                </div>
                :
                <div style={{ padding: '24px' }}>
                    <Card>
                        <TableContainer>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table" size="small">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Hospital ID</StyledTableCell>
                                        <StyledTableCell>Hospital Name</StyledTableCell>
                                        <StyledTableCell>City</StyledTableCell>
                                        <StyledTableCell>State</StyledTableCell>
                                        <StyledTableCell>Country</StyledTableCell>
                                        <StyledTableCell>Status</StyledTableCell>
                                        <StyledTableCell>Actions</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data['data']?.length > 0 ? data['data']?.map((row) => (
                                        <StyledTableRow key={row.pid}>
                                            <StyledTableCell component="th" scope="row">
                                                <NavLink to={`/hospitals/viewhospital/${row.uid}`} exact activeClassName="active">
                                                    {row.pid}
                                                </NavLink>
                                            </StyledTableCell>
                                            <StyledTableCell>{row.hospitalName}</StyledTableCell>
                                            <StyledTableCell>{row.address.city}</StyledTableCell>
                                            <StyledTableCell>{row.address.state}</StyledTableCell>
                                            <StyledTableCell>{row.address.country}</StyledTableCell>
                                            <StyledTableCell><Chip label={row.status} variant="outlined" /></StyledTableCell>
                                            <StyledTableCell>
                                                <IconButton aria-label="create">
                                                    <Icon color="dark" onClick={() => editHospital(row.uid)}>create_outlined</Icon>
                                                </IconButton>
                                                <IconButton aria-label="archive" title='Archive'>
                                                    <Icon color="dark">archive_outlined</Icon>
                                                </IconButton>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )) : <StyledTableRow><StyledTableCell colSpan={8} align='center'>No Records Available</StyledTableCell></StyledTableRow>}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 50]}
                            component="div"
                            count={data?.totalCount}
                            rowsPerPage={limit}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Card>
                </div>
            }
            {/* <Drawer
                container={container}
                variant="permanent"
                open={mobileOpen}
                anchor="bottom"
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{ display: { xs: 'block', sm: 'none', md: 'none', lg: 'none', xl: 'none' } }}
            >
                <Stack spacing={2} direction="row">
                    <Button variant="text">Export</Button>
                    <Button variant="text">Import</Button>
                    <Button className="round-button" variant="contained" onClick={createHospital}>Add Hospital</Button>
                </Stack>
            </Drawer> */}

            <Box component="footer">
                <Copyright sx={{ pb: 3, px: 3 }} />
            </Box>
        </div>


    )
}

export default Hospitals;