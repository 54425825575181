import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Icon } from "@mui/material";
import { Chip } from "@mui/material";
import { useEffect, useState } from "react";
import Header from "../../Header/Header";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import Grid from "@mui/material/Grid";
import { useNavigate, useParams } from "react-router-dom";

import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import { NavLink } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";

import patientAddSuccessImage from "../../../assets/illustrations/patient-add-success.png";
import errorImage from "../../../assets/illustrations/error.png";

import { useForm, Controller, useFieldArray } from "react-hook-form";

import CircularProgress from "@mui/material/CircularProgress";
import Copyright from "../../Copyright";
import { unknown, z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import useFetchStudyData from "./FetchData";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  // [`&.${tableCellClasses.head}`]: {
  //   backgroundColor: theme.palette.common.black,
  //   color: theme.palette.common.white,
  // },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // '&:nth-of-type(odd)': {
  //   backgroundColor: theme.palette.action.hover,
  // },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const today = dayjs(new Date()).startOf("day");

const editStudyDataSchema = z
  .object({
    studyName: z.string().min(1, "Study Name is required"),
    studyType: z.string().min(1, "Study Type is required"),
    surveySetUID: z.string().min(1, "Survey Set is required"),
    hospitalUID: z.string().min(1, "Hospital is required"),
    startDate: z
      .string()
      .optional()
      .refine(
        (val) => {
          if (!val || val === "0001-01-01T00:00:00Z") return true;
          const startDate = dayjs(val, "DD-MM-YYYY");
          return startDate.isSame(today) || startDate.isAfter(today);
        },
        {
          message: "Start date should be today or later.",
        }
      ),
    endDate: z
      .string()
      .optional()
      .refine(
        (val) => {
          if (!val || val === "0001-01-01T00:00:00Z") return true;
          const endDate = dayjs(val, "DD-MM-YYYY");
          return endDate.isSame(today) || endDate.isAfter(today);
        },
        {
          message: "End date should be today or later.",
        }
      ),
    status: z.string().min(1, "Status is required"),
    consentTitle: z.string().min(1, "Consent Title is required"),
    language: z.string().min(1, "Language is required"),
    patientConsentForm: z.string().min(1, "Patient Consent Form is required"),
    piConsentForm: z.string().optional(),
  })
  .superRefine((data, ctx) => {
    if (data.status === "Active") {
      const { startDate, endDate } = data;
      if (!startDate || startDate === "0001-01-01T00:00:00Z") {
        ctx.addIssue({
          path: ["startDate"],
          code: z.ZodIssueCode.custom,
          message: "Start date is required for Active status",
        });
      }
      if (!endDate || endDate === "0001-01-01T00:00:00Z") {
        ctx.addIssue({
          path: ["endDate"],
          code: z.ZodIssueCode.custom,
          message: "End date is required for Active status",
        });
      }

      if (
        startDate &&
        endDate &&
        startDate !== "0001-01-01T00:00:00Z" &&
        endDate !== "0001-01-01T00:00:00Z"
      ) {
        const startDateObj = dayjs(startDate, "DD-MM-YYYY");
        const endDateObj = dayjs(endDate, "DD-MM-YYYY");
        if (startDateObj.isAfter(endDateObj)) {
          ctx.addIssue({
            path: ["endDate"],
            code: z.ZodIssueCode.custom,
            message: "End date should be after start date",
          });
        }
      }
    }
  });

function EditStudy() {
  let { studyId } = useParams();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const {
    studyFormData,
    hospitalList,
    surveyList,
    configList,
    usersData,
    patientsData,
    selectedPatientsData,
    selectedUsersData,
    selectedUsers,
    selectedPatients,
    hospitalAddress,
    isLoading,
    roleList,
    fetchHospitalAddress,
    setSelectedPatients,
    setSelectedUsers,
    setSelectedPatientsData,
    setSelectedUsersData,
    setIsActiveStudy,
    isActiveStudy,
    usersDataMap,
  } = useFetchStudyData(studyId);
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors, isDirty },
    setValue,
    getValues,
  } = useForm({
    resolver: zodResolver(editStudyDataSchema),
  });
  useEffect(() => {
    if (studyFormData) {
      Object.keys(studyFormData).forEach((key) => {
        // if (key === "startDate" || key === "endDate") {
        //   // Convert the date string to a dayjs object
        //   const dateValue = studyFormData[key]
        //     ? dayjs(studyFormData[key], "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY")
        //     : null;
        //     console.log(`got value for ${key} is ${dateValue} and raw date is ${studyFormData[key]}`)
        //   setValue(key, dateValue);
        // } else {
        if (key === "status" && studyFormData[key] === "Active") {
        }
        setValue(key, studyFormData[key]);
        // }
      });
    }
  }, [studyFormData, setValue]);
  // const updateSelectedUsers = React.useCallback((newPIUID) => {
  //   const prevUserIds = getValues('users')
  //   console.log(`previously selected users`, prevUserIds)
  //   const updatedUserIDs = new Set(prevUserIds);
  //   const updatedUserIdsArray = Array.from(updatedUserIDs);
  //   updatedUserIDs.add(newPIUID);
  //   const previouslySelectedUsers = updatedUserIdsArray.map(userId=>usersDataMap[userId]).filter(user=>!!user);
  //   if (previouslySelectedUsers && previouslySelectedUsers.length > 0) {
  //     setSelectedUsersData(previouslySelectedUsers);
  //   }

  //   setValue('users', Array.from(updatedUserIDs))
  // }, []);

  const formatDate = (date) => {
    console.log("selected", date);
    if (!date || date === "0001-01-01T00:00:00Z") return undefined;
    const formattedDate = dayjs(date).format("DD-MM-YYYY");
    console.log(`formatted date`, formattedDate);
    return formattedDate;
  };
  const hospital = watch("hospitalUID");
  const userSelectedStatus = watch("status");
  const onSubmit = (data) => {
    setIsSubmitLoading(true);
    console.log(JSON.stringify(data));
    let myHeaders = new Headers();
    let selectedpatientsid = [];
    let selectedusersid = [];
    if (selectedPatientsData?.length > 0) {
      selectedPatientsData.map((selectedpatient) => {
        selectedpatientsid.push(selectedpatient.uid);
      });
    }

    if (selectedUsersData?.length > 0) {
      selectedUsersData.map((selecteduser) => {
        selectedusersid.push(selecteduser.uid);
      });
    }
    if (data["startDate"] === "0001-01-01T00:00:00Z") {
      delete data["startDate"];
    }
    if (data["endDate"] === "0001-01-01T00:00:00Z") {
      delete data["endDate"];
    }
    console.log("selectedids", selectedpatientsid);
    console.log("selectedids", selectedusersid);
    let studydata = {
      ...data,
      ["patients"]: selectedpatientsid,
      ["users"]: selectedusersid,
    };
    myHeaders.append("Content-Type", "application/json");
    let requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify(studydata),
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/studies/` + studyId,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        setIsSubmitLoading(false);
        if (!JSON.parse(result).err) {
          console.log(result);
          setIsActiveStudy(data.status === "Active");
          setSuccessDialog(true);
        } else {
          console.log("error", JSON.parse(result).err);
          setErrorDialog(true);
          setErrMsg(JSON.parse(result).err);
        }
      })
      .catch((error) => {
        setIsSubmitLoading(false);
        console.log("error", error);
      });
    console.log("Reuest body", studydata);
  };
  useEffect(() => {
    if (hospital) {
      console.log(`hospital`, hospital);
      fetchHospitalAddress(hospital);
    }
  }, [hospital]);

  let navigate = useNavigate();
  const endDateWatch = watch("endDateg");
  const [data, setData] = useState({});
  const [studyData, setStudyData] = useState({});
  const [searchData, setSearchData] = useState({});
  useEffect(() => {
    console.log(`formErrors`, errors);
  }, [errors]);

  const handleChange = (e) => {
    setStudyData((studyData) => ({
      ...studyData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSearch = (e) => {
    searchData((searchData) => ({
      ...searchData,
      [e.target.name]: e.target.value,
    }));
  };

  function backToStudy() {
    navigate("/studies/viewstudy/" + studyId);
  }

  const [tabValue, setTabValue] = React.useState("1");
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Dialog Declarations
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openUsersDialog, setOpenUsersDialog] = React.useState(false);
  const [successDialog, setSuccessDialog] = React.useState(false);
  const [errorDialog, setErrorDialog] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState("");

  // const handleClickOpen = () => {
  //     setOpenDialog(true);
  // };

  const closeSuccessDialog = () => {
    setSuccessDialog(false);
  };
  const closeErrorDialog = () => {
    setErrorDialog(false);
  };

  const OpenPatientDialog = () => {
    setOpenDialog(true);
    // fetchPatientsData();
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const OpenUsersDialog = () => {
    setOpenUsersDialog(true);
    // fetchUsersData();
  };

  const closeUsersDialog = () => {
    setOpenUsersDialog(false);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = patientsData["data"].map((n) => n.id);
      setSelectedPatients(newSelected);
      return;
    }
    setSelectedPatients([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selectedPatients.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedPatients, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedPatients.slice(1));
    } else if (selectedIndex === selectedPatients?.length - 1) {
      newSelected = newSelected.concat(selectedPatients.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedPatients.slice(0, selectedIndex),
        selectedPatients.slice(selectedIndex + 1)
      );
    }
    setSelectedPatients(newSelected);
  };

  const isSelected = (id) => selectedPatients.indexOf(id) !== -1;

  function savePatientSelection() {
    let selectedpatients = [];
    selectedPatients.map((id) => {
      selectedpatients.push(
        patientsData["data"].filter((patient) => patient.id == id)[0]
      );
      // console.log(selectedpatients);
    });
    setSelectedPatientsData(selectedpatients);
    setOpenDialog(false);
  }

  //Users Table
  const SelectAllUsers = (event) => {
    if (event.target.checked) {
      const newSelected = usersData["data"].map((n) => n.uid);
      setSelectedUsers(newSelected);
      return;
    }
    setSelectedUsers([]);
  };

  const selectUser = (event, id) => {
    const selectedIndex = selectedUsers.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedUsers, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers?.length - 1) {
      newSelected = newSelected.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      );
    }
    setSelectedUsers(newSelected);
  };

  const isSelectedUsers = (id) => {
    console.log(`checking for ${id}`, selectedUsers);
    return selectedUsers.indexOf(id) !== -1;
  };

  function saveUserSelection() {
    let selectedusers = [];
    selectedUsers.map((id) => {
      selectedusers.push(usersData["data"].filter((user) => user.uid == id)[0]);
      // console.log(selectedpatients);
    });
    setSelectedUsersData(selectedusers);
    setOpenUsersDialog(false);
  }

  function backToStudies() {
    navigate("/studies");
  }

  const principalInvestigatorUID = watch("principalInvestigatorUID");
  useEffect(() => {
    if (principalInvestigatorUID) {
      selectUser(null, principalInvestigatorUID);
      let previousSelectedUsersdata = selectedUsersData;
      const principalUserIsExistingUser = previousSelectedUsersdata.some(
        (user) => user.uid === principalInvestigatorUID
      );
      if (!principalUserIsExistingUser) {
        const principalUser = usersData.data?.find(
          (user) => user.uid === principalInvestigatorUID
        );
        if (principalUser) {
          previousSelectedUsersdata.push(principalUser);
          setSelectedUsersData(previousSelectedUsersdata);
        }
      }
    }
  }, [principalInvestigatorUID, usersDataMap, usersData]);

  return (
    <div>
      <Dialog
        open={successDialog}
        onClose={closeSuccessDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { borderRadius: "16px" } }}
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent className="pad48">
          <DialogContentText id="alert-dialog-description">
            <div className="layout layout-vertical layout-justify-center layout-item-center">
              <img src={patientAddSuccessImage} alt="" />
              <p className="dialog-description">Study updated successfully!</p>
              <p></p>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-bg">
          <div className="layout layout-item-center layout-justify-center layout-full-width mt16 mb16">
            <Button variant="text" onClick={backToStudies}>
              Close
            </Button>
            {/* <Button className="round-button ml24" variant="outlined" onClick={backToViewUser}>View User</Button> */}
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={errorDialog}
        onClose={closeErrorDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { borderRadius: "16px" } }}
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent className="pad48">
          <DialogContentText id="alert-dialog-description">
            <div className="layout layout-vertical layout-justify-center layout-item-center">
              <img src={errorImage} alt="" height="256px" />
              <p className="dialog-description" style={{ color: "#B04933" }}>
                Something Went Wrong!
              </p>
              <p style={{ textTransform: "capitalize", textAlign: "center" }}>
                {errMsg}
              </p>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-bg">
          <div className="layout layout-item-center layout-justify-center layout-full-width mt16 mb16">
            <Button variant="text" onClick={closeErrorDialog}>
              Close
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { borderRadius: "16px" } }}
        maxWidth="xl"
      >
        <DialogTitle id="alert-dialog-title">Select Patient(s)</DialogTitle>
        <DialogContent className="pad48">
          <DialogContentText id="alert-dialog-description">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div className="mb16">
                  <TextField
                    name="firstName"
                    label="First Name"
                    type="text"
                    value={""}
                    onChange={handleSearch}
                  />
                </div>
                <div className="mb16">
                  <TextField
                    name="patientid"
                    label="Patient Id"
                    type="text"
                    value={""}
                    onChange={handleSearch}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="mb16">
                  <TextField
                    name="lastName"
                    label="Last Name"
                    type="text"
                    value={""}
                    onChange={handleSearch}
                  />
                </div>
                <div className="mb16">
                  <FormControl fullWidth>
                    <InputLabel id="hospitals">Hospital</InputLabel>
                    <Select
                      name="hospitals"
                      labelId="hospital"
                      id="hospital-select"
                      value={studyData.hospital}
                      onChange={handleSearch}
                      label="Hospital"
                    >
                      {hospitalList?.map((hospital) => (
                        <MenuItem value={hospital.uid}>
                          {hospital.hospitalName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="layout layout-item-center layout-justify-end">
                  <Button variant="text" onClick={handleClose}>
                    Reset
                  </Button>
                  <Button
                    className="round-button ml24"
                    variant="outlined"
                    onClick={handleClose}
                  >
                    Search
                  </Button>
                </div>
              </Grid>
            </Grid>
            <TableContainer>
              <Table
                sx={{ minWidth: 700 }}
                aria-label="customized table"
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        // indeterminate={selectedPatients > 0 && selectedPatients < patientsData['data']??.length}
                        // checked={patientsData['data']??.length > 0 && selectedPatients === patientsData['data']??.length}
                        onChange={handleSelectAllClick}
                      />
                    </StyledTableCell>
                    <StyledTableCell>Patient ID</StyledTableCell>
                    <StyledTableCell>First Name</StyledTableCell>
                    <StyledTableCell>Last Name</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {patientsData["data"]?.length > 0 ? (
                    patientsData["data"]?.map((row) => (
                      <StyledTableRow
                        key={row.id}
                        hover
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isSelected(row.id)}
                        tabIndex={-1}
                        selected={isSelected(row.id)}
                        sx={{ cursor: "pointer" }}
                      >
                        <StyledTableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isSelected(row.id)}
                          />
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {/* <NavLink to={`/viewuser/${row.uid}`} exact activeClassName="active"> */}
                          {row.pid}
                          {/* </NavLink> */}
                        </StyledTableCell>
                        <StyledTableCell>{row.firstName}</StyledTableCell>
                        <StyledTableCell>{row.lastName}</StyledTableCell>

                        <StyledTableCell>
                          <Chip label={row.status} variant="outlined" />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell colSpan={8} align="center">
                        No Records Available
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-bg">
          <div className="layout layout-item-center layout-justify-end layout-full-width mt16 mb16 mr32">
            <Button variant="text" onClick={handleClose}>
              Close
            </Button>
            <Button
              className="round-button ml24"
              variant="outlined"
              onClick={savePatientSelection}
            >
              Save Selection
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      {!isLoading ? (
        <Dialog
          open={openUsersDialog}
          onClose={closeUsersDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{ sx: { borderRadius: "16px" } }}
          maxWidth="xl"
        >
          <DialogTitle id="alert-dialog-title">Select User(s)</DialogTitle>
          <DialogContent className="pad48">
            <DialogContentText id="alert-dialog-description">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div className="mb16">
                    <TextField
                      name="firstName"
                      label="First Name"
                      type="text"
                      value={""}
                      onChange={handleSearch}
                    />
                  </div>
                  <div className="mb16">
                    <TextField
                      name="patientid"
                      label="Patient Id"
                      type="text"
                      value={""}
                      onChange={handleSearch}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="mb16">
                    <TextField
                      name="lastName"
                      label="Last Name"
                      type="text"
                      value={""}
                      onChange={handleSearch}
                    />
                  </div>
                  <div className="mb16">
                    <FormControl fullWidth>
                      <InputLabel id="hospitals">Hospital</InputLabel>
                      <Select
                        name="hospitals"
                        labelId="hospital"
                        id="hospital-select"
                        value={studyData.hospital}
                        onChange={handleSearch}
                        label="Hospital"
                      >
                        {hospitalList?.map((hospital) => (
                          <MenuItem value={hospital.uid}>
                            {hospital.hospitalName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="layout layout-item-center layout-justify-end">
                    <Button variant="text" onClick={handleClose}>
                      Reset
                    </Button>
                    <Button
                      className="round-button ml24"
                      variant="outlined"
                      onClick={handleClose}
                    >
                      Search
                    </Button>
                  </div>
                </Grid>
              </Grid>
              <TableContainer>
                <Table
                  sx={{ minWidth: 700 }}
                  aria-label="customized table"
                  size="small"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          // indeterminate={selectedPatients > 0 && selectedPatients < patientsData['data']??.length}
                          // checked={patientsData['data']??.length > 0 && selectedPatients === patientsData['data']??.length}
                          onChange={SelectAllUsers}
                        />
                      </StyledTableCell>
                      <StyledTableCell>User ID</StyledTableCell>
                      <StyledTableCell>First Name</StyledTableCell>
                      <StyledTableCell>Last Name</StyledTableCell>
                      <StyledTableCell>Status</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {usersData["data"]?.length > 0 ? (
                      usersData["data"]?.map((row) => (
                        <StyledTableRow
                          key={row.uid}
                          hover
                          onClick={(event) => selectUser(event, row.uid)}
                          role="checkbox"
                          aria-checked={isSelectedUsers(row.uid)}
                          tabIndex={-1}
                          selected={isSelectedUsers(row.uid)}
                          sx={{ cursor: "pointer" }}
                        >
                          <StyledTableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isSelectedUsers(row.uid)}
                            />
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {/* <NavLink to={`/viewuser/${row.uid}`} exact activeClassName="active"> */}
                            {row.pid}
                            {/* </NavLink> */}
                          </StyledTableCell>
                          <StyledTableCell>{row.firstName}</StyledTableCell>
                          <StyledTableCell>{row.lastName}</StyledTableCell>

                          <StyledTableCell>
                            <Chip label={row.status} variant="outlined" />
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell colSpan={8} align="center">
                          No Records Available
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="dialog-action-bg">
            <div className="layout layout-item-center layout-justify-end layout-full-width mt16 mb16 mr32">
              <Button variant="text" onClick={closeUsersDialog}>
                Close
              </Button>
              <Button
                className="round-button ml24"
                variant="outlined"
                onClick={saveUserSelection}
              >
                Save Selection
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      ) : null}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Header>
          <div className="layout layout-item-center layout-justify-space-between">
            <h2>
              Edit Study - {studyFormData?.studyName} - {studyFormData?.pid}
            </h2>
            <Stack spacing={2} direction="row">
              <Button
                variant="text"
                disabled={isSubmitLoading}
                onClick={backToStudy}
              >
                Cancel
              </Button>
              <Button
                className="round-button"
                variant="contained"
                type="submit"
                disabled={isLoading || isSubmitLoading}
              >
                {isSubmitLoading ? (
                  <CircularProgress color="secondary" size={24} />
                ) : (
                  "Save Study"
                )}
              </Button>
            </Stack>
          </div>
        </Header>

        {isLoading == true ? (
          <div className="layout layout-item-center layout-justify-center loadingProgress">
            <CircularProgress />
          </div>
        ) : (
          <div style={{ padding: "24px" }}>
            <Grid container>
              <Grid item xs={12} md={10}>
                <Card>
                  <CardContent>
                    <p className="mb24">
                      <strong>Basic Details</strong>
                    </p>
                    <Grid
                      container
                      rowSpacing={{ xs: 1, sm: 1, md: 1 }}
                      columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                    >
                      <Grid item xs={12} md={5}>
                        <div className="mb24">
                          <p className="view-label mb8">Survey ID</p>
                          <p className="view-value">{studyFormData.pid}</p>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Controller
                          control={control}
                          name="status"
                          rules={{ required: true }}
                          defaultValue={""}
                          render={({ field }) => (
                            <FormControl fullWidth>
                              <InputLabel id="status">Status</InputLabel>
                              <Select
                                name="status"
                                labelId="status"
                                id="status-select"
                                {...field}
                                label="Status"
                                disabled={isActiveStudy}
                              >
                                <MenuItem value={"Active"}>Active</MenuItem>
                                <MenuItem value={"Inactive"}>Inactive</MenuItem>
                              </Select>
                            </FormControl>
                          )}
                        />
                      </Grid>
                      {userSelectedStatus === "Active" && (
                        <>
                          <Grid item xs={12} md={5}>
                            <div className="mb24">
                              <Controller
                                control={control}
                                name="startDate"
                                render={({ field }) => (
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DatePicker
                                      slotProps={{
                                        textField: {
                                          fullWidth: true,
                                          error: !!errors.startDate,
                                          helperText: errors.startDate?.message,
                                        },
                                      }}
                                      label="Start Date"
                                      onChange={(newValue) => {
                                        field.onChange(
                                          newValue
                                            ? dayjs(
                                                newValue,
                                                "DD-MM-YYYY"
                                              ).format("DD-MM-YYYY")
                                            : null
                                        );
                                      }}
                                      value={
                                        field.value
                                          ? dayjs(field.value, "DD-MM-YYYY")
                                          : null
                                      }
                                      disablePast
                                      disabled={isActiveStudy}
                                      format="DD-MM-YYYY"
                                    />
                                  </LocalizationProvider>
                                )}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} md={5}>
                            <div className="mb24">
                              <Controller
                                control={control}
                                name="endDate"
                                render={({ field }) => (
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DatePicker
                                      slotProps={{
                                        textField: {
                                          fullWidth: true,
                                          error: !!errors.endDate,
                                          helperText: errors.endDate?.message,
                                        },
                                      }}
                                      label="End Date"
                                      onChange={(newValue) => {
                                        field.onChange(
                                          newValue
                                            ? dayjs(
                                                newValue,
                                                "DD-MM-YYYY"
                                              ).format("DD-MM-YYYY")
                                            : null
                                        );
                                      }}
                                      value={
                                        field.value
                                          ? dayjs(field.value, "DD-MM-YYYY")
                                          : null
                                      }
                                      disablePast
                                      format="DD-MM-YYYY"
                                    />
                                  </LocalizationProvider>
                                )}
                              />
                            </div>
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12} md={2}></Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <p></p>
            <Grid container>
              <Grid item xs={12} md={10}>
                <Card className="mb16">
                  <CardContent>
                    <p>
                      <b>Study Details</b>
                    </p>
                    <Grid
                      container
                      rowSpacing={{ xs: 1, sm: 1, md: 1 }}
                      columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                    >
                      <Grid item xs={12} md={5}>
                        <div className="mb16">
                          <TextField
                            fullWidth
                            name="studyName"
                            label="Study Name"
                            type="text"
                            {...register("studyName", { required: true })}
                            InputLabelProps={{ shrink: true }}
                            // value={studyData.name}
                            // onChange={handleChange}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <div className="mb16">
                          <Controller
                            control={control}
                            name="studyType"
                            rules={{ required: true }}
                            defaultValue={""}
                            render={({ field }) => (
                              <FormControl fullWidth>
                                <InputLabel id="studytype">
                                  Study Type
                                </InputLabel>
                                <Select
                                  name="studyType"
                                  labelId="studytype"
                                  id="studytype-select"
                                  {...field}
                                  label="Study Type"
                                >
                                  {configList["studyType"]?.map((type) => (
                                    <MenuItem key={type} value={type}>
                                      {type}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={2}></Grid>
                      <Grid item xs={12} md={5}>
                        <div className="mb16">
                          <Controller
                            control={control}
                            name="surveySetUID"
                            rules={{ required: true }}
                            defaultValue={""}
                            render={({ field }) => (
                              <FormControl fullWidth>
                                <InputLabel id="surveysets">
                                  Survey Sets
                                </InputLabel>
                                <Select
                                  name="surveySets"
                                  labelId="surveysets"
                                  id="surveysets-select"
                                  {...field}
                                  label="Survey Sets"
                                >
                                  {surveyList?.map((survey) => (
                                    <MenuItem
                                      key={survey.uid}
                                      value={survey.uid}
                                    >
                                      {survey.surveyName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <div className="mb16">
                          <Controller
                            control={control}
                            name="surveySubsetUID"
                            rules={{ required: false }}
                            defaultValue={""}
                            render={({ field }) => (
                              <FormControl fullWidth>
                                <InputLabel id="surveySubset">
                                  Survey Subset
                                </InputLabel>
                                <Select
                                  name="surveySubsetUID"
                                  labelId="surveySubset"
                                  id="surveySubset-select"
                                  label="Survey Subset"
                                  {...field}
                                >
                                  {surveyList?.map((survey) => (
                                    <MenuItem
                                      key={survey.uid}
                                      value={survey.uid}
                                    >
                                      {survey.surveyName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={2}></Grid>

                      <Grid item xs={12} md={5}>
                        <div className="mb16">
                          <Controller
                            control={control}
                            name="principalInvestigatorUID"
                            rules={{ required: true }}
                            defaultValue={""}
                            render={({ field }) => (
                              <FormControl fullWidth>
                                <InputLabel id="principalInvestigatorUID">
                                  Principal Investigator
                                </InputLabel>
                                <Select
                                  name="principalInvestigatorUID"
                                  labelId="principalInvestigator"
                                  id="principalInvestigator-select"
                                  {...field}
                                  label="Principal Investigator"
                                >
                                  {usersData["data"]?.map((user) => (
                                    <MenuItem value={user.uid}>
                                      {user.firstName} {user.lastName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={2}></Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} md={10}>
                <Card className="mb16">
                  <CardContent>
                    <p>
                      <b>Hospital Details</b>
                    </p>
                    <Grid
                      container
                      rowSpacing={{ xs: 1, sm: 1, md: 1 }}
                      columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                    >
                      <Grid item xs={12} md={5}>
                        <div className="mb16">
                          <Controller
                            control={control}
                            name="hospitalUID"
                            rules={{ required: true }}
                            defaultValue={""}
                            render={({ field }) => (
                              <FormControl fullWidth>
                                <InputLabel id="hospitals">
                                  Hospital Name
                                </InputLabel>
                                <Select
                                  name="hospitalUID"
                                  labelId="hospital"
                                  id="hospital-select"
                                  label="Hospital"
                                  {...field}
                                  onChange={(e) => {
                                    field.onChange(e.target.value);
                                    fetchHospitalAddress(e.target.value);
                                  }}
                                >
                                  {hospitalList?.map((hospital) => (
                                    <MenuItem value={hospital.uid}>
                                      {hospital.hospitalName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <div className="">
                          <p className="view-label mb8">Address</p>
                          <p>
                            {hospitalAddress?.street}, {hospitalAddress?.city},{" "}
                            {hospitalAddress?.state}, {hospitalAddress?.country}
                            , {hospitalAddress?.zipcode}
                          </p>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={2}></Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} md={10}>
                <Card className="mb16">
                  <CardContent>
                    <p>
                      <b>Consent Form</b>
                    </p>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={5}>
                        <Controller
                          control={control}
                          name="consentTitle"
                          render={({ field }) => (
                            <TextField
                              fullWidth
                              label="Consent Title"
                              error={!!errors.consentTitle}
                              helperText={errors.consentTitle?.message}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Controller
                          control={control}
                          name="language"
                          render={({ field }) => (
                            <FormControl fullWidth error={!!errors.language}>
                              <InputLabel id="language">Language</InputLabel>
                              <Select
                                labelId="language"
                                id="language-select"
                                label="Language"
                                {...field}
                              >
                                <MenuItem value="French">French</MenuItem>
                                <MenuItem value="English">English</MenuItem>
                              </Select>
                              {errors.language && (
                                <p className="error-message">
                                  {errors.language.message}
                                </p>
                              )}
                            </FormControl>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Controller
                          control={control}
                          name="patientConsentForm"
                          render={({ field }) => (
                            <TextField
                              fullWidth
                              label="Patient Description"
                              multiline
                              rows={4}
                              error={!!errors.patientConsentForm}
                              helperText={errors.patientConsentForm?.message}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Controller
                          control={control}
                          name="piConsentForm"
                          render={({ field }) => (
                            <TextField
                              fullWidth
                              label="Principal Investigator Description"
                              multiline
                              rows={4}
                              error={!!errors.piConsentForm}
                              helperText={errors.piConsentForm?.message}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={10}>
                <Card className="">
                  <CardContent>
                    <TabContext value={tabValue}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <div className="layout layout-item-center layout-justify-space-between">
                          <div>
                            <TabList onChange={handleTabChange} aria-label="">
                              <Tab label="Patients" value="1" />
                              <Tab label="Users" value="2" />
                            </TabList>
                          </div>
                          <div>
                            {tabValue == 1 ? (
                              <Button
                                className="round-button"
                                variant="outlined"
                                onClick={OpenPatientDialog}
                              >
                                Add Patients
                              </Button>
                            ) : (
                              <Button
                                className="round-button"
                                variant="outlined"
                                onClick={OpenUsersDialog}
                              >
                                Add Users
                              </Button>
                            )}
                          </div>
                        </div>
                      </Box>
                      <TabPanel value="1">
                        <TableContainer>
                          <Table
                            sx={{ minWidth: 700 }}
                            aria-label="customized table"
                            size="small"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Patient ID</StyledTableCell>
                                <StyledTableCell>First Name</StyledTableCell>
                                <StyledTableCell>Last Name</StyledTableCell>
                                <StyledTableCell>Gender</StyledTableCell>
                                <StyledTableCell>Hospital Name</StyledTableCell>
                                <StyledTableCell>Actions</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {selectedPatientsData?.length > 0 ? (
                                selectedPatientsData.map((row) => (
                                  <StyledTableRow key={row.pid}>
                                    <StyledTableCell component="th" scope="row">
                                      {/* <NavLink to={`/viewpatient/${row.uid}`} exact activeClassName="active"> */}
                                      {row.pid}
                                      {/* </NavLink> */}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row.firstName}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row.lastName}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row.gender}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row.hospitalName}
                                    </StyledTableCell>
                                    {/* <StyledTableCell>{row.email}</StyledTableCell> */}
                                    <StyledTableCell>
                                      <IconButton aria-label="create">
                                        <Icon color="dark">delete</Icon>
                                      </IconButton>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))
                              ) : (
                                <StyledTableRow>
                                  <StyledTableCell colSpan={8} align="center">
                                    No Records Available
                                  </StyledTableCell>
                                </StyledTableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TabPanel>
                      <TabPanel value="2">
                        <TableContainer>
                          <Table
                            sx={{ minWidth: 700 }}
                            aria-label="customized table"
                            size="small"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>User ID</StyledTableCell>
                                <StyledTableCell>First Name</StyledTableCell>
                                <StyledTableCell>Last Name</StyledTableCell>
                                <StyledTableCell>Gender</StyledTableCell>
                                <StyledTableCell>Hospital Name</StyledTableCell>
                                <StyledTableCell>Roles</StyledTableCell>
                                <StyledTableCell>Actions</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {selectedUsersData?.length > 0 ? (
                                selectedUsersData.map((row) => (
                                  <StyledTableRow key={row.pid}>
                                    <StyledTableCell component="th" scope="row">
                                      {/* <NavLink to={`/viewuser/${row.uid}`} exact activeClassName="active"> */}
                                      {row.pid}
                                      {/* </NavLink> */}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row.firstName}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row.lastName}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row.gender}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row.hospitalName}
                                    </StyledTableCell>
                                    {/* <StyledTableCell><Chip label={row.roles} variant="outlined" /></StyledTableCell> */}
                                    <StyledTableCell>
                                      <IconButton aria-label="create">
                                        <Icon color="dark">delete</Icon>
                                      </IconButton>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))
                              ) : (
                                <StyledTableRow>
                                  <StyledTableCell colSpan={8} align="center">
                                    No Records Available
                                  </StyledTableCell>
                                </StyledTableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TabPanel>
                    </TabContext>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Box component="footer">
              <Copyright sx={{ px: 3, py: 2 }} />
            </Box>
          </div>
        )}
      </form>
      <Box component="footer">
        <Copyright sx={{ pb: 3, px: 3 }} />
      </Box>
    </div>
  );
}

export default EditStudy;
