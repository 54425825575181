import { Outlet } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import SideNav from "../SideNav/Sidenav";


function Layout() {
  return (
    <div class="layout">
        <div style={{minWidth:274 + 'px'}}>
            <SideNav />
        </div>   
        <div style={{marginTop:72 + 'px',width: 100 + '%'}}>
            <Outlet />
        </div>
    </div>
  );
};

export default Layout;