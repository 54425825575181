import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Icon } from '@mui/material';
import { Chip } from '@mui/material';
import { useEffect, useState } from 'react';
import Header from "../../Header/Header";

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

import Tab from '@mui/material/Tab';

import Tabs from '@mui/material/Tabs';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import Grid from '@mui/material/Grid';
import { useNavigate, useParams } from "react-router-dom";

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

import CircularProgress from '@mui/material/CircularProgress';
import Copyright from '../../Copyright';

function ViewPatient() {
    let { patientId } = useParams();
    let navigate = useNavigate();
    // const [data, setData] = useState(null)
    const [patientFormData, setPatientFormData] = useState({
        firstName: '',
        lastName: '',
        dob: '',
        gender: '',
        phone: '',
        email: '',
        height: '',
        weight: '',
        bloodGroup: '',
        bloodPressure: '',
        hospitalUID: '',
        address: {
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            zipcode: '',
            country: ''
        },
        treatmentType: ''
    })

    const [open, setOpen] = React.useState(false);

    const [isLoading, setisLoading] = React.useState(false);

    const [tabValue, setTabValue] = React.useState('1');
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    // const handleChange = (e) => {
    //     setPatientFormData((patientFormData) => ({
    //         ...patientFormData,
    //         [e.target.name]: e.target.value
    //     }));
    // };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const action = (
        <React.Fragment>

            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    const [message, setMessage] = useState('');

    function backToPatients() {
        navigate('/patients')
    }
    function gotoEditPatient(uid) {
        navigate(`/patients/editpatient/${uid}`)
    }

    useEffect(() => {
        setisLoading(true);
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/patients/` + patientId)
            .then((res) => res.json())
            .then((data) => {
                setPatientFormData({
                    ...data['data']
                });
                setisLoading(false);
                console.log("Patient Data", data);
            })
    }, [])

    return (
        <div>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message={message}
                action={action}
            />

            <Header>
                <div className="layout layout-item-center layout-justify-space-between">
                    <h2>View Patient - {patientFormData.firstName} {patientFormData.lastName} - {patientFormData.pid}</h2>
                    <Stack spacing={2} direction="row">
                        <Button variant="text" onClick={backToPatients}>Cancel</Button>
                        <Button className="round-button" variant="contained" onClick={() => gotoEditPatient(patientFormData.uid)}>Edit</Button>
                    </Stack>
                </div>
            </Header>

            {isLoading == true ?
                <div className='layout layout-item-center layout-justify-center loadingProgress'>
                    <CircularProgress />
                </div>
                :
                <TabContext value={tabValue}>
                    {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}> */}
                    <div className='layout layout-item-center layout-justify-space-between'>
                        <div style={{ padding: '0 24px' }}>
                            <TabList
                                onChange={handleTabChange}
                                aria-label=""
                                TabIndicatorProps={{
                                    sx: {
                                        top: 0,
                                        // height: "2px",
                                        borderBottom: '2px solid #1976d2',
                                        borderRadius: '0 0 4px 4px',
                                    }
                                }}>
                                <Tab label="Patient details" value="1" />
                                <Tab label="Clinical details" value="2" />
                                <Tab label="Study details" value="3" />
                            </TabList>
                        </div>
                    </div>
                    {/* </Box> */}
                    <div>
                        <TabPanel value="1">
                            <Grid container>
                                <Grid item xs={12} md={10}>
                                    <Card>
                                        <CardContent>
                                            <p className='mb24'><strong>Patient Details</strong></p>
                                            <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                                <Grid item xs={12} md={5}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Patient ID</p>
                                                        <p className='view-value'>{patientFormData.pid}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={5}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Status</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                            <p></p>
                            <Grid container>
                                <Grid item xs={12} md={10}>
                                    <Card>
                                        <CardContent>
                                            <p className='mb24'><strong>Personal Details</strong></p>
                                            <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                                <Grid item xs={12} md={5}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>First Name</p>
                                                        <p className='view-value'>{patientFormData.firstName}</p>
                                                    </div>
                                                </Grid>

                                                <Grid item xs={12} md={5}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Last Name</p>
                                                        <p className='view-value'>{patientFormData.lastName}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={5}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Gender</p>
                                                        <p className='view-value'>{patientFormData.gender}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={5}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Date of Birth</p>
                                                        <p className='view-value'>{dayjs(patientFormData.dob).format('DD/MM/YYYY')}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={5}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Phone</p>
                                                        <p className='view-value'>{patientFormData.phone ? patientFormData.phone : "Not Available"}</p>
                                                    </div>
                                                </Grid>

                                                <Grid item xs={12} md={5}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Email</p>
                                                        <p className='view-value'>{patientFormData.email ? patientFormData.email : "Not Available"}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={5}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Appartment, House No., Flat</p>
                                                        <p className='view-value'>{patientFormData.address.addressLine1 ? patientFormData.address.addressLine1 : "Not Available"}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={5}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Area, Street</p>
                                                        <p className='view-value'>{patientFormData.address.addressLine2 ? patientFormData.address.addressLine2 : "Not Available"}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={5}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>City</p>
                                                        <p className='view-value'>{patientFormData.address.city ? patientFormData.address.city : "Not Available"}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={5}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>ZIP Code</p>
                                                        <p className='view-value'>{patientFormData.address.zipcode ? patientFormData.address.zipcode : "Not Available"}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={5}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>State</p>
                                                        <p className='view-value'>{patientFormData.address.state ? patientFormData.address.state : "Not Available"}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={5}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Country</p>
                                                        <p className='view-value'>{patientFormData.address.country ? patientFormData.address.country : "Not Available"}</p>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                            <p></p>
                            <Grid container>
                                <Grid item xs={12} md={10}>
                                    <Card>
                                        <CardContent>
                                            <p className='mb24'><strong>Health Details</strong></p>
                                            <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                                <Grid item xs={12} md={5}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Height</p>
                                                        <div style={{ 'display': 'flex' }}>
                                                            <p className="view-value">{patientFormData.height ? patientFormData.height : "Not Available"}</p>
                                                            <span className="view-value" style={{ 'padding-left': '4px' }}>cm</span>
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={5}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Blood Group</p>
                                                        <div className="view-value">
                                                            <p>{patientFormData.bloodGroup ? patientFormData.bloodGroup : "Not Available"}</p>
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={5}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Weight</p>
                                                        <div style={{ 'display': 'flex' }}>
                                                            <p className='view-value'>{patientFormData.weight ? patientFormData.weight : "Not Available"}</p>
                                                            <span className="view-value" style={{ 'padding-left': '4px' }}>kgs</span>
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={5}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Blood Pressure (S/D)</p>
                                                        <div style={{ 'display': 'flex' }}>
                                                            <p className="view-value">{patientFormData.bloodPressure ? patientFormData.bloodPressure : "Not Available"}</p>
                                                            <span className="view-value" style={{ 'padding-left': '4px' }}>mmHg</span>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                            <p></p>
                            <Grid container>
                                <Grid item xs={12} md={10}>
                                    <Card>
                                        <CardContent>
                                            <p className='mb24'><strong>Treatment Details</strong></p>
                                            <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                                <Grid item xs={12} md={5}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Treatement Type</p>
                                                        <p className='view-value'>{patientFormData.treatmentType ? patientFormData.treatmentType : "Not Available"}</p>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                            <p></p>
                            <Grid container>
                                <Grid item xs={12} md={10}>
                                    <Card>
                                        <CardContent>
                                            <p className='mb24'><strong>Hospital Details</strong></p>
                                            <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                                <Grid item xs={12} md={5}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Hospital Name</p>
                                                        <p className='view-value'>{patientFormData.hospitalName}</p>
                                                    </div>
                                                </Grid>

                                                <Grid item xs={4}>

                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Department</p>
                                                        <p className='view-value'>{patientFormData.department ? patientFormData.department : "Not Available"}</p>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="2">
                            {/* Demographic Factors */}
                            <Grid container>
                                <Grid item xs={12} md={10}>
                                    <Card>
                                        <CardContent>
                                            <p className='mb24'><strong>Demographic Factors</strong></p>
                                            <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Year of Birth</p>
                                                        <p className='view-value'>{patientFormData.pid}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Gender</p>
                                                        <p className='view-value'>{patientFormData.gender}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Level of Education</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                            <p></p>
                            {/* Baseline Characteristics */}
                            <Grid container>
                                <Grid item xs={12} md={10}>
                                    <Card>
                                        <CardContent>
                                            <p className='mb24'><strong>Baseline Characteristics</strong></p>
                                            <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Treatment Pathway</p>
                                                        <p className='view-value'>{patientFormData.pid}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Previous Modality Treatment(s)</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Smoking Status</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Body Height</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Body Height Units</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}></Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Body Weight</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Body Weight Units</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                            <p></p>
                            {/* Baseline COndition Factors */}
                            <Grid container>
                                <Grid item xs={12} md={10}>
                                    <Card>
                                        <CardContent>
                                            <p className='mb24'><strong>Baseline Condition Factors</strong></p>
                                            <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Primary Renal Disease</p>
                                                        <p className='view-value'>{patientFormData.pid}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Primary Kidney Allografts</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Vascular Access Types</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Transplant Type</p>
                                                        <p className='view-value'>{patientFormData.pid}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Renal/Kidney Allograft Function</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Renal function/Kidney Allograft Measurement Date</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Serum Creatinine Units</p>
                                                        <p className='view-value'>{patientFormData.pid}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Serum Creatinine Callibration</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Isotope Dilution Mass Spectrometry (IDMS) Callibration</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Serum Creatinine</p>
                                                        <p className='view-value'>{patientFormData.pid}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>eGFR Equation</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>eGFR value</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Method of Albuminuria Measurement</p>
                                                        <p className='view-value'>{patientFormData.pid}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Units of Albuminuria Measurement</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Albuminuria Callibration</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Results of Urine ACR</p>
                                                        <p className='view-value'>{patientFormData.pid}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Urine PCR</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Date of Albuminuria Measurement</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                            <p></p>
                            {/* Comorbidities SACQ */}
                            <Grid container>
                                <Grid item xs={12} md={10}>
                                    <Card>
                                        <CardContent>
                                            <p className='mb24'><strong>Comorbidities - Self Administered Comorbidity Questionnaire (SACQ)</strong></p>
                                            <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Year of Birth</p>
                                                        <p className='view-value'>{patientFormData.pid}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Education Level</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Gender</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                            <p></p>
                            {/* Survival */}
                            <Grid container>
                                <Grid item xs={12} md={10}>
                                    <Card>
                                        <CardContent>
                                            <p className='mb24'><strong>Survival</strong></p>
                                            <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Vital Status</p>
                                                        <p className='view-value'>{patientFormData.pid}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Date of Death</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Cause of Death</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                            <p></p>
                            {/* Burden of Care */}
                            <Grid container>
                                <Grid item xs={12} md={10}>
                                    <Card>
                                        <CardContent>
                                            <p className='mb24'><strong>Burden of Care</strong></p>
                                            <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>All admission to Hospitals</p>
                                                        <p className='view-value'>{patientFormData.pid}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Total number of days spent in Hospital over past 12 months</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Cardiovascular Event</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Myocardial Infarction</p>
                                                        <p className='view-value'>{patientFormData.pid}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Date of Acute Myocardial Infarction</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Stroke (excluding transient ischemic attacks)</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Date of stroke</p>
                                                        <p className='view-value'>{patientFormData.pid}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Limb amputation (not due to traumatic injury)</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Date of limb amputation</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                            <p></p>
                            {/* Treatment Specific */}
                            <Grid container>
                                <Grid item xs={12} md={10}>
                                    <Card>
                                        <CardContent>
                                            <p className='mb24'><strong>Treatment Specific</strong></p>
                                            <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Renal/Kidney Allograft Function</p>
                                                        <p className='view-value'>{patientFormData.pid}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Serum Creatinine units</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Serum Creatinine</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>eGFR equation</p>
                                                        <p className='view-value'>{patientFormData.pid}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Estimated glomerular filtration rate</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Renal function measurement date</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Method of albuminuria measurement</p>
                                                        <p className='view-value'>{patientFormData.pid}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Units of albuminuria measurement</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Results of urine ACR</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Urine PCR</p>
                                                        <p className='view-value'>{patientFormData.pid}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Date of albuminuria measurement</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Bacteraemia</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Number of diagnosed bacteraemia episodes</p>
                                                        <p className='view-value'>{patientFormData.pid}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Haemodialysis start</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Vascular access type</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Subsequent vascular access</p>
                                                        <p className='view-value'>{patientFormData.pid}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Subsequent vascular access date</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>New vascular access type</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Peritoneal dialysis start</p>
                                                        <p className='view-value'>{patientFormData.pid}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Treatment pathway change</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Treatment pathway change date</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Peritonitis</p>
                                                        <p className='view-value'>{patientFormData.pid}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Peritonitis diagnosis</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Number of peritonitis episodes diagnosed</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Date of kidney transplant</p>
                                                        <p className='view-value'>{patientFormData.pid}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Subsequent kidney transplant</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Type of new transplant</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Date of subsequent kidney transplant</p>
                                                        <p className='view-value'>{patientFormData.pid}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Kidney transplant rejection</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Transplant rejection diagnosis</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Kidney transplant rejection date</p>
                                                        <p className='view-value'>{patientFormData.pid}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Malignancies</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Solid malignancy</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Skin malignancy</p>
                                                        <p className='view-value'>{patientFormData.pid}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Haematological malignancy</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <div className="mb24">
                                                        <p className='view-label mb8'>Date of malignancy diagnosis</p>
                                                        <p className='view-value'>{patientFormData.status}</p>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                            <p></p>
                        </TabPanel>
                        <TabPanel value="3">
                        </TabPanel>
                    </div>
                </TabContext>
            }
            <Box component="footer">
                <Copyright sx={{ pb: 3, px: 3 }} />
            </Box>
        </div >
    )
}

export default ViewPatient;
