import React, {useState} from "react";
import "./Signup.css"

// import Box from '@mui/material/Box';
// import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import logo from '../../assets/logos/PROCare_logo.png';
// import kidneycareimg from '../../assets/kidney_care.jpg';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from "react-router-dom";
// import { createTheme, ThemeProvider } from '@mui/material/styles';
// import SFProDisplay from '../../../public/assets/SF-Pro-Display-Regular.woff';

import { useNavigate  } from "react-router-dom";

// const theme = createTheme({
//     typography: {
//       fontFamily: 'SF Pro Display',
//     },
//     components: {
//       MuiCssBaseline: {
//         styleOverrides: `
//           @font-face {
//             font-family: 'SF Pro Display';
//             font-style: normal;
//             font-display: swap;
//             font-weight: 400;
//             src: local('SF Pro Display'), local('SF-Pro-Display-Regular'), url(${SFProDisplay}) format('woff');
//             unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
//           }
//         `,
//       },
//     },
//   });

function Signup() {

    let navigate = useNavigate();

    function handleSubmit() {
        // console.log("Hi");
        // if(username === "admin" && password === "admin") {
        //     navigate("/dashboard");
        // }
        // else {
        //     alert("Wrong Credentials")
        // }
        navigate("/login");
    }

    return (
        // <ThemeProvider theme={theme}>
        <div className="layout layout-full-height">
            <div className="layout layout-item-center layout-flex-full login-side-bg">
                
            </div>
            <div className="layout layout-item-center layout-flex-full ml48 login-form-column">
                <div>
                    <img src={logo} width="194px" alt="" className="mb48"/>
                    <div>
                        <div className="login_form">
                            <h1>Sign Up</h1>
                            <form onSubmit={handleSubmit}>
                                <div className="mb16">
                                    <TextField
                                        label="First Name" 
                                        variant="outlined" 
                                        
                                        className="txtfield-login"
                                        >
                                    </TextField>
                                </div>
                                <div className="mb16">
                                    <TextField
                                        label="Last Name" 
                                        variant="outlined" 
                                        
                                        className="txtfield-login"
                                        >
                                    </TextField>
                                </div>
                                <div className="mb16">
                                    <TextField
                                        label="Email" 
                                        variant="outlined"
                                        
                                        className="txtfield-login"
                                        >
                                    </TextField>
                                </div>
                                <div className="mb16">
                                    <TextField
                                        label="Password" 
                                        variant="outlined" 
                                        className="txtfield-login"
                                        >
                                    </TextField>
                                </div>
                                <div className="mb16">
                                    <TextField
                                        label="Phone number" 
                                        variant="outlined" 
                                        
                                        className="txtfield-login"
                                        >
                                    </TextField>
                                </div>
                                
                                <div className="mb16">
                                    <Button className="round-button" variant="contained" type="submit">
                                        Sign Up
                                    </Button>
                                </div>

                                <div className="layout layout-item-center layout-justify-space-between">
                                    <p>Already have an account?</p>
                                    <Link to="/login">Sign In</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        // <div className="login">
        //     <Box sx={{ flexGrow: 1 }}>
        //         <Grid container spacing={2}>
        //             <Grid item xs></Grid>
        //             <Grid item xs={12} sm={4} lg={4}>
        //                 <div className="login_form">
        //                     <h1>Login</h1>
        //                     <form onSubmit={handleSubmit}>
        //                         <Grid
        //                             container
        //                             spacing={3}
        //                             direction={'column'}
        //                             justify={'center'}
        //                             alignItems={'center'}>

        //                             <Grid item xs={12}>
                                        
        //                                 <TextField
        //                                     label="Email or Phone number" 
        //                                     variant="outlined" 
        //                                     value={username}
        //                                     onChange={(e) => setUsername(e.target.value)}
        //                                     required
        //                                     type={'text'} />
        //                             </Grid>
        //                             <Grid item xs={12}>
        //                                 <TextField 
        //                                     label="Password" 
        //                                     variant="outlined" 
        //                                     value={password}
        //                                     onChange={(e) => setPassword(e.target.value)}
        //                                     required 
        //                                     type={'password'}>
        //                                 </TextField>
        //                             </Grid>
        //                             <Grid item xs={12}>
        //                                 <Button className="round-button" variant="contained" type="submit">
        //                                     Sign In 
        //                                 </Button>
        //                             </Grid>
        //                         </Grid>
        //                     </form>
        //                 </div>
        //             </Grid>
        //             <Grid item xs></Grid>
        //         </Grid>
        //     </Box>
        // </div>
        // </ThemeProvider>
    )
}

export default Signup;