import React from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Divider,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

const QuestionFormDialog = ({ open, onClose, onSave, configList }) => {
  const { register, handleSubmit, control, watch, reset } = useForm({
    defaultValues: {
      variableId: "",
      questionOrderId: "",
      item: "",
      displayedValue: "",
      definition: "",
      supportingDefinition: "",
      inclusionCriteria: "",
      rationalForInclusion: "",
      timing: "",
      reportingSource: "",
      type: "",
      valueDomain: "",
      responseType: "",
      responseOptions: [{ option: "", value: "" }],
      minValue: "",
      maxValue: "",
      step: "",
      reference: "",
      additionalNotes: "",
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "responseOptions",
  });

  const responseTypeWatch = watch("responseType");

  const onSubmit = (data) => {
    const transformedData = {
      ...data,
      question: data.displayedValue,
      source: data.reportingSource,
      options: data.responseOptions,
      questionOrderId: data.variableId,
      rationalForInclusion: "",
    };
    onSave(transformedData);
    reset();
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: "16px" } }}
      maxWidth="xl"
      fullWidth
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Add New Question</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                className="mb16"
                label="Variable ID"
                {...register("variableId")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                className="mb16"
                label="Item"
                {...register("item")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                className="mb16"
                label="Question Order ID"
                {...register("questionOrderId")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                className="mb16"
                label="Reference"
                {...register("reference")}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                className="mb16"
                label="Question"
                {...register("displayedValue")}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                className="mb16"
                label="Definition"
                multiline
                rows={2}
                {...register("definition")}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                className="mb16"
                label="Supporting Definition"
                multiline
                rows={2}
                {...register("supportingDefinition")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth className="mb16">
                <InputLabel>Inclusion Criteria</InputLabel>
                <Controller
                  name="inclusionCriteria"
                  control={control}
                  render={({ field }) => (
                    <Select {...field} label="Inclusion Criteria">
                      {configList.inclusionCriteria?.map((criteria) => (
                        <MenuItem key={criteria} value={criteria}>
                          {criteria}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth className="mb16">
                <InputLabel>Timing</InputLabel>
                <Controller
                  name="timing"
                  control={control}
                  render={({ field }) => (
                    <Select {...field} label="Timing">
                      {configList.timing?.map((timing) => (
                        <MenuItem key={timing} value={timing}>
                          {timing}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth className="mb16">
                <InputLabel>Reporting Source</InputLabel>
                <Controller
                  name="reportingSource"
                  control={control}
                  render={({ field }) => (
                    <Select {...field} label="Reporting Source">
                      {configList.reportingSource?.map((source) => (
                        <MenuItem key={source} value={source}>
                          {source}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth className="mb16">
                <InputLabel>Value Domain</InputLabel>
                <Controller
                  name="valueDomain"
                  control={control}
                  render={({ field }) => (
                    <Select {...field} label="Value Domain">
                      {configList.valueDomain?.map((domain) => (
                        <MenuItem key={domain} value={domain}>
                          {domain}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                className="mb16"
                label="Rational for Inclusion"
                {...register("rationalForInclusion")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                className="mb16"
                label="Additional Notes"
                multiline
                rows={2}
                {...register("additionalNotes")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth className="mb16">
                <InputLabel>Question Type</InputLabel>
                <Controller
                  name="type"
                  control={control}
                  render={({ field }) => (
                    <Select {...field} label="Question Type">
                      {configList.questionType?.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth className="mb16">
                <InputLabel>Response Type</InputLabel>
                <Controller
                  name="responseType"
                  control={control}
                  render={({ field }) => (
                    <Select {...field} label="Response Type">
                      {configList.responseType?.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Divider style={{ margin: "20px 0" }} />

          {(responseTypeWatch === "Single" ||
            responseTypeWatch === "Multiple") && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h4>Response Options</h4>
              </Grid>
              {fields.map((field, index) => (
                <Grid container item spacing={2} key={field.id}>
                  <Grid item xs={2}>
                    <div className="mb16">
                      <TextField
                        fullWidth
                        label={`Response Value ${index + 1}`}
                        {...register(`responseOptions.${index}.option`)}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="mb16">
                      <TextField
                        fullWidth
                        label={`Response Option ${index + 1}`}
                        {...register(`responseOptions.${index}.value`)}
                      />
                    </div>
                  </Grid>
                  {index === 0 ? (
                    <Grid item xs={2}>
                      <Button
                        variant="outlined"
                        startIcon={<AddIcon />}
                        className="round-button"
                        onClick={() => append({ option: "", value: "" })}
                      >
                        Add Response
                      </Button>
                    </Grid>
                  ) : (
                    <Grid item xs={2}>
                      <IconButton onClick={() => remove(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              ))}
            </Grid>
          )}
          {responseTypeWatch === "Range" && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h4>Range Options</h4>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Minimum Value"
                  type="text"
                  inputProps={{
                    pattern: "^-?\\d*\\.?\\d+$",
                    step: "any",
                  }}
                  {...register("minValue", {
                    pattern: {
                      value: /^-?\d*\.?\d+$/,
                      message: "Please enter a valid number",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Maximum Value"
                  type="text"
                  inputProps={{
                    pattern: "^-?\\d*\\.?\\d+$",
                    step: "any",
                  }}
                  {...register("maxValue", {
                    pattern: {
                      value: /^-?\d*\.?\d+$/,
                      message: "Please enter a valid number",
                    },
                  })}
                />
              </Grid>
              {/* <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Step"
                  type="text"
                  inputProps={{
                    pattern: "^\\d*\\.?\\d+$",
                    step: "any",
                  }}
                  {...register("step", {
                    pattern: {
                      value: /^\d*\.?\d+$/,
                      message: "Please enter a valid positive number",
                    },
                  })}
                />
              </Grid> */}
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            type="submit"
            className="round-button"
            variant="outlined"
            color="primary"
          >
            Save Question
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default QuestionFormDialog;
