import React from 'react';
import {
  TextField, Grid, FormControl, InputLabel, Select, MenuItem, Chip, Box
} from '@mui/material';
import { Controller } from 'react-hook-form';

const SurveyDetailsForm = ({ register, errors, control, surveySubsets }) => {
  return (
    <>
      <p><b>Survey Details</b></p>
      <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
        <Grid item xs={12} md={5}>
          <TextField
            fullWidth
            label="Survey Title"
            {...register('surveyName', { required: 'Survey Title is required' })}
            error={!!errors.surveyName}
            helperText={errors.surveyName?.message}
          />
          <TextField
            name="surveyDescription"
            label="Description"
            fullWidth
            multiline
            rows={4}
            {...register('surveyDescription', { required: 'Description is required' })}
            error={!!errors.surveyDescription}
            helperText={errors.surveyDescription?.message}
            style={{ marginTop: '16px' }}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <FormControl fullWidth>
            <InputLabel>Can be Survey Set</InputLabel>
            <Controller
              name="canBeSurveySet"
              control={control}
              render={({ field }) => (
                <Select {...field} label="Can be Survey Set">
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              )}
            />
            {errors.canBeSurveySet && <p className="error-message">{errors.canBeSurveySet.message}</p>}
          </FormControl>
          <FormControl fullWidth style={{ marginTop: '16px' }}>
            <InputLabel>Can be Survey Subset</InputLabel>
            <Controller
              name="canBeSubset"
              control={control}
              render={({ field }) => (
                <Select {...field} label="Can be Survey Subset">
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              )}
            />
            {errors.canBeSubset && <p className="error-message">{errors.canBeSubset.message}</p>}
          </FormControl>
          {/* <FormControl fullWidth style={{ marginTop: '16px' }}>
            <InputLabel>Survey Subsets</InputLabel>
            <Controller
              name="surveySubSets"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  multiple
                  label="Survey Subsets"
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                >
                  {surveySubsets?.map((subset) => (
                    <MenuItem key={subset.uid} value={subset.uid}>
                      {subset.surveyName}
                    </MenuItem>
                  ))}
                  
                </Select>
              )}
            />
          </FormControl> */}
        </Grid>
      </Grid>
    </>
  );
};

export default SurveyDetailsForm;
