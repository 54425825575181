import React from 'react'
import { Box, Grid, useMediaQuery } from '@mui/material'
import backgroundImage from "../../assets/images/care.jpg";
import { useTheme } from '@emotion/react';

const StaticOverlay = () => {
	const theme = useTheme();

	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		!isSmallScreen && <Grid item xs={false} sm={6} md={6} sx={{ position: "relative" }}>
			<Box
				display='flex'
				sx={{
					backgroundImage: `url(${backgroundImage})`,
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
					backgroundPosition: "center",
					height: "100%",
					position: "absolute",
					width: "100%",
					zIndex: 1,
				}}
			>
				{/* <div style={{ background: 'rgba(0, 0, 0, 0.4)'}}> */}
					<div style={{ 'align-self': 'center', padding: '10%',  }}>
						<h1 style={{ 'fontSize': '3em' }}>A stride towards delivering better patient outcomes</h1>
						<p>
							Streamline Patient-Reported Outcome collection and generate
							continuous Real-World Evidence. Uncover actionable insights on the
							impact of your treatments on patients' quality of life.
						</p>
					</div>
				{/* </div> */}
			</Box>
		</Grid>
	)
}

export default StaticOverlay