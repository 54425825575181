import dayjs from 'dayjs';
import { useState, useEffect } from 'react';

const useFetchStudyData = (studyId) => {
    const [studyFormData, setStudyFormData] = useState({});
    const [hospitalList, setHospitalList] = useState([]);
    const [surveyList, setSurveyList] = useState([]);
    const [configList, setConfigList] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [usersDataMap, setUsersDataMap] = useState({}); // [0
    const [patientsData, setPatientsData] = useState([]);
    const [selectedPatientsData, setSelectedPatientsData] = useState([]);
    const  [selectedPatients, setSelectedPatients] = useState([]); // [1]
    const [selectedUsersData, setSelectedUsersData] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]); // [2]
    const [hospitalAddress, setHospitalAddress] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [roleList, setRoleList] = useState([]);
    const [isActiveStudy, setIsActiveStudy] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);

                const [studyRes, hospitalsRes, surveysRes, configsRes, rolesRes] = await Promise.all([
                    fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/studies/${studyId}`),
                    fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/hospitals`),
                    fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/surveys?status=all`),
                    fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/configs`),
                    fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/roles`),
                ]);

                const studyData = await studyRes.json();
                const hospitalsData = await hospitalsRes.json();
                const surveysData = await surveysRes.json();
                const configsData = await configsRes.json();
                const rolesData = await rolesRes.json();
                const studyDataFormatted = {
                    ...studyData.data,
                    startDate: studyData.data?.startDate && studyData.data?.startDate!=='0001-01-01T00:00:00Z' ? dayjs(studyData.data?.startDate, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY') : undefined,
                    endDate: studyData.data?.endDate && studyData.data?.endDate !== '0001-01-01T00:00:00Z' ? dayjs(studyData.data?.endDate, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY') : undefined,
                }
                setIsActiveStudy(studyDataFormatted.status === 'Active');
                console.log('studyDataFormatted',studyDataFormatted);
                setStudyFormData(studyDataFormatted);
                setHospitalList(hospitalsData.data.data);
                setSurveyList(surveysData.data.data);
                setConfigList(configsData.data.data);
                setRoleList(rolesData.data.data);
                // Fetch selected patients and users based on the study data
                if (studyData.data.patients?.length > 0) {
                    await fetchPatientsData(studyData.data.patients);
                }else{
                    await fetchPatientsData([]);
                }
                if (studyData.data.users?.length > 0) {
                    await fetchUsersData(studyData.data.users);
                }else{
                    await  fetchUsersData([]);
                }

                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, [studyId]);

    const fetchPatientsData = async (uids) => {
        try {
            const patientsRes = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/patients`);
            const patientsData = await patientsRes.json();
            setPatientsData(patientsData.data);

            if (uids.length > 0) {
                const selectedPatients = uids.map(uid => patientsData.data?.data?.find(patient => patient.uid === uid));
                setSelectedPatientsData(selectedPatients);
                setSelectedPatients(selectedPatients.map(patient => patient.uid))
            }
        } catch (error) {
            console.error('Error fetching patients data:', error);
        }
    };

    const fetchUsersData = async (uids, append=false) => {
        try {
            const usersRes = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/users`);
            const usersData = await usersRes.json();
            setUsersData(usersData.data);
            const usersDataMap = usersData.data?.data?.reduce((acc, user) => {
                acc[user.uid] = user;
                return acc;
            }, {});
            setUsersDataMap(usersDataMap);
            if (uids.length > 0) {
                const selectedUsers = uids.map(uid => usersData.data?.data?.find(user => user.uid === uid));
                setSelectedUsersData([...selectedUsers]);
                setSelectedUsers([...uids])
            }
        } catch (error) {
            console.error('Error fetching users data:', error);
        }
    };

    const fetchHospitalAddress = async (hospitalId) => {
        try {
            const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/hospitals/${hospitalId}`);
            const data = await res.json();
            setHospitalAddress(data.data.address);
        } catch (error) {
            console.error('Error fetching hospital address:', error);
        }
    };
    return {
        studyFormData,
        hospitalList,
        surveyList,
        configList,
        usersData,
        patientsData,
        selectedPatientsData,
        selectedUsersData,
        selectedPatients,
        selectedUsers,
        hospitalAddress,
        isLoading,
        roleList,
        fetchHospitalAddress,
        setSelectedPatientsData,
        setSelectedPatients,
        setSelectedUsers,
        setSelectedUsersData,
        isActiveStudy,  
        setIsActiveStudy,
        usersDataMap,
    };
};

export default useFetchStudyData;
