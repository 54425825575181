import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import logo from '../../assets/logos/PROCare_logo.png';
import "./style.css";

function AlreadyVerified({reason}) {
    
    const navigate = useNavigate();
    const location = useLocation();

    setTimeout(() => {
        navigate('/login')
    }, 10000);
   
    return (
        <div className="layout layout-full-height">
            <div className="layout layout-item-center layout-flex-full login-side-bg">
            </div>
            <div className="layout layout-item-center layout-flex-full ml48 login-form-column">
                <div>
                    <img src={logo} width="194px" alt="" className="mb48"/>
                    <div className="login_form">
                        <h1>User Verification</h1>
                            <div className="mb16">
                                <span>You have already verified your account.</span>
                            </div>
                            <div className="mb16">
                               <span>If you are not automatically redirected in 10 seconds, click <a href="/login">here</a> to login.<br/><br/>  Please use the forgot password option on login screen to set your password.</span>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AlreadyVerified;
