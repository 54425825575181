import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Icon } from '@mui/material';
import { Chip } from '@mui/material';
import { useEffect, useState, useMemo } from 'react';
import Header from '../../Header/Header';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import Grid from '@mui/material/Grid';
import { useNavigate, useParams } from "react-router-dom";

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import patientAddSuccessImage from '../../../assets/illustrations/patient-add-success.png';
import errorImage from '../../../assets/illustrations/error.png';

import countryList from 'react-select-country-list';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

import { useForm, Controller, useFieldArray } from "react-hook-form";

import CircularProgress from '@mui/material/CircularProgress';
import Copyright from '../../Copyright';

function EditPatient() {

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        clearErrors
    } = useForm({
        defaultValues: async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/patients/` + patientId);
            const data = await response.json();
            // console.log(data);
            return data['data'];
        },
    })

    const onSubmit = (data) => {
        console.log(JSON.stringify(data));
        data['height'] = parseInt(data['height']);
        data['weight'] = parseInt(data['weight']);
        data['phone'] = data['phone'].indexOf('+') > -1 ? data['phone'] : '+' + data['phone'];
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: JSON.stringify(data),
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/patients/` + patientId, requestOptions)
            .then(response => response.text())
            .then(result => {
                if (!JSON.parse(result).err) {
                    console.log(result)
                    setOpenDialog(true);
                } else {
                    console.log('error', JSON.parse(result).err)
                    setErrorDialog(true);
                    setErrMsg(JSON.parse(result).err);
                }
            })
            .catch(error => {
                console.log('error', error)
            });

    }

    let { patientId } = useParams();
    let navigate = useNavigate();
    const [data, setData] = useState(null)
    const [patientFormData, setPatientFormData] = useState({
        firstName: '',
        lastName: '',
        dob: dayjs(null),
        gender: '',
        phone: '',
        email: '',
        height: null,
        weight: null,
        bloodGroup: '',
        bloodPressure: '',
        address: {
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            zipcode: '',
            country: ''
        },
        hospitalUID: '',
        treatmentType: ''
    })
    const [hospitalList, setHospitalList] = useState([])
    const [configList, setConfigList] = useState([])

    const [isLoading, setisLoading] = React.useState(false);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/hospitals`)
            .then((res) => res.json())
            .then((data) => {
                console.log("Hospitals", data);
            })
    }, [])

    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/hospitals`)
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    setHospitalList(data['data']['data']);
                }
            })
    }, [])
    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/configs`)
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    setConfigList(data['data']['data']);
                }
            })
    }, [])

    const handleChange = (e) => {
        setPatientFormData((patientFormData) => ({
            ...patientFormData,
            [e.target.name]: e.target.name == 'height' || e.target.name == 'weight' ? parseInt(e.target.value) : e.target.value
        }));
    };

    const setDoB = (e) => {
        console.log("dob", e.format('DD-MM-YYYY'));
        setPatientFormData((patientFormData) => ({
            ...patientFormData,
            ['dob']: e.format('DD-MM-YYYY')
        }));
    };


    // const handleSubmit = () => {
    //     console.log("Patient Data", patientFormData);
    // let myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    // let requestOptions = {
    //     method: 'PUT',
    //     headers: myHeaders,
    //     body: JSON.stringify(patientFormData),
    //     redirect: 'follow'
    // };

    // fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/patients/" + patientId, requestOptions)
    //     .then(response => response.text())
    //     .then(result => {
    //         if(!JSON.parse(result).err){
    //             console.log(result)
    //             setOpenDialog(true);
    //         }else{
    //             console.log('error', JSON.parse(result).err)
    //             setErrorDialog(true);
    //             setErrMsg(JSON.parse(result).err);
    //         }
    //     })
    //     .catch(error => {
    //         console.log('error', error)
    //     });
    // };

    function backToPatient() {
        navigate('/patients/viewpatient/' + patientId)
    }

    useEffect(() => {
        setisLoading(true);
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/patients/` + patientId)
            .then((res) => res.json())
            .then((data) => {
                setPatientFormData({
                    ...data['data']
                });
                setisLoading(false);
                console.log("Patient Data Edit", data);
            })
    }, [])

    // Dialog Declarations
    const [openDialog, setOpenDialog] = React.useState(false);
    const [errorDialog, setErrorDialog] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState('');

    // const handleClickOpen = () => {
    //     setOpenDialog(true);
    // };

    const handleClose = () => {
        setOpenDialog(false);
    };
    const closeErrorDialog = () => {
        setErrorDialog(false);
    };

    const options = useMemo(() => countryList().getData(), [])
    const handleAddressChange = (e) => {
        let address = JSON.parse(JSON.stringify(patientFormData.address));
        address = { ...address, [e.target.name]: e.target.value };
        setPatientFormData((patientFormData) => ({
            ...patientFormData,
            ['address']: address
        }));
        // console.log("CL",options);
        // console.log("Ck",countries);
    };



    return (
        <div>
            <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{ sx: { borderRadius: "16px" } }}
            >
                <DialogTitle id="alert-dialog-title">
                </DialogTitle>
                <DialogContent className='pad48'>
                    <DialogContentText id="alert-dialog-description">
                        <div className="layout layout-vertical layout-justify-center layout-item-center">
                            <img src={patientAddSuccessImage} alt="" />
                            <p className='dialog-description'>
                                Patient Updated successfully!
                            </p>
                            <p></p>
                        </div>

                    </DialogContentText>
                </DialogContent>
                <DialogActions className="dialog-action-bg">
                    <div className='layout layout-item-center layout-justify-center layout-full-width mt16 mb16'>
                        <Button variant="text" onClick={backToPatient}>Close</Button>
                        {/* <Button className="round-button ml24" variant="outlined" onClick={backToPatient}>View User</Button> */}
                    </div>

                </DialogActions>
            </Dialog>
            <Dialog
                open={errorDialog}
                onClose={closeErrorDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{ sx: { borderRadius: "16px" } }}
            >
                <DialogTitle id="alert-dialog-title">
                </DialogTitle>
                <DialogContent className='pad48'>
                    <DialogContentText id="alert-dialog-description">
                        <div className="layout layout-vertical layout-justify-center layout-item-center">
                            <img src={errorImage} alt="" height="256px" />
                            <p className='dialog-description' style={{ color: '#B04933' }}>
                                Something Went Wrong!
                            </p>
                            <p style={{ textTransform: 'capitalize', textAlign: 'center' }}>{errMsg}</p>
                        </div>

                    </DialogContentText>
                </DialogContent>
                <DialogActions className="dialog-action-bg">
                    <div className='layout layout-item-center layout-justify-center layout-full-width mt16 mb16'>
                        <Button variant="text" onClick={closeErrorDialog}>Close</Button>
                    </div>

                </DialogActions>
            </Dialog>
            <form onSubmit={handleSubmit(onSubmit)}  >
                <Header>
                    <div className="layout layout-item-center layout-justify-space-between">
                        <h2>Edit Patient - {patientFormData.firstName} {patientFormData.lastName} - {patientFormData.pid}</h2>
                        <Stack spacing={2} direction="row">
                            <Button variant="text" onClick={backToPatient}>Cancel</Button>
                            <Button className="round-button" variant="contained" type='submit'>Save</Button>
                        </Stack>
                    </div>
                </Header>

                {isLoading == true ?
                    <div className='layout layout-item-center layout-justify-center loadingProgress'>
                        <CircularProgress />
                    </div>
                    :
                    <div style={{ 'padding': '24px' }}>
                        <Grid container>
                            <Grid item xs={12} md={10}>
                                <Card>
                                    <CardContent>
                                        <p><b>Patient Details</b></p>
                                        <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                            <Grid item xs={12} md={5}>
                                                <div className="mb16">
                                                    <TextField
                                                        fullWidth
                                                        name="firstName"
                                                        label="First Name"
                                                        type="text"
                                                        {...register('firstName', { required: true, minLength: 3, maxLength: 30 })}
                                                        InputLabelProps={{ shrink: true }}
                                                        // value={patientFormData.firstName} 
                                                        // onChange={handleChange} 
                                                        aria-label="First Name"
                                                        tabIndex={1}
                                                        autoFocus
                                                    />
                                                    {errors.firstName && errors.firstName.type === "required" && <p role="alert" className="error-message">Please enter First Name</p>}
                                                    {errors.firstName && errors.firstName.type === "minLength" && <p role="alert" className="error-message">First Name should be minimum 3 characters long</p>}
                                                    {errors.firstName && errors.firstName.type === "maxLength" && <p role="alert" className="error-message">First Name should not be more than 30 characters</p>}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={5}>
                                                <div className="mb16">
                                                    <TextField
                                                        fullWidth
                                                        name="lastName"
                                                        label="Last Name"
                                                        type="text"
                                                        {...register('lastName', { required: true, minLength: 3, maxLength: 30 })}
                                                        InputLabelProps={{ shrink: true }}
                                                        // value={patientFormData.lastName} 
                                                        // onChange={handleChange} 
                                                        aria-label="Last Name"
                                                        tabIndex={2}
                                                    />
                                                    {errors.lastName && errors.lastName.type === "required" && <p role="alert" className="error-message">Please enter Last Name</p>}
                                                    {errors.lastName && errors.lastName.type === "minLength" && <p role="alert" className="error-message">Last Name should be minimum 3 characters long</p>}
                                                    {errors.lastName && errors.lastName.type === "maxLength" && <p role="alert" className="error-message">Last Name should not be more than 30 characters</p>}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={2}></Grid>

                                            <Grid item xs={12} md={5}>
                                                <div className="mb16">
                                                    <Controller
                                                        control={control}
                                                        name='gender'
                                                        rules={{ required: true }}
                                                        defaultValue={''}
                                                        render={({ field }) => (
                                                            <FormControl fullWidth>
                                                                <InputLabel id="gender">Gender</InputLabel>
                                                                <Select
                                                                    name="gender"
                                                                    labelId="gender"
                                                                    id="gender-select"
                                                                    // value={userFormData.gender}
                                                                    // onChange={handleChange}
                                                                    {...field}
                                                                    label="Gender"
                                                                    aria-label="Gender"
                                                                    tabIndex={3}
                                                                >
                                                                    {configList['gender']?.map((gender) => (
                                                                        <MenuItem value={gender}>{gender}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        )}
                                                    />
                                                    {errors.gender && <p role="alert" className="error-message">Please enter Gender</p>}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={5}>
                                                <div className="mb16">
                                                    <Controller
                                                        control={control}
                                                        name='dob'
                                                        render={({ field }) => (
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DatePicker
                                                                    slotProps={{ textField: { fullWidth: true } }}
                                                                    name="dob"
                                                                    label="Date of Birth"
                                                                    // value={dayjs(userFormData.dob)} 
                                                                    // onChange={(newValue) => setDoB(newValue)}
                                                                    // {...register('dob', { required: true })}
                                                                    onChange={(date) => field.onChange(date.format('DD-MM-YYYY'))}
                                                                    selected={field.value}
                                                                    value={dayjs(field.value)}
                                                                    disableFuture
                                                                    format="DD/MM/YYYY"
                                                                    aria-label="Date of Birth"
                                                                    tabIndex={4}
                                                                />
                                                            </LocalizationProvider>
                                                        )}
                                                    />
                                                    {errors.dob && <p role="alert" className="error-message">Please enter Date of Birth</p>}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={5}>
                                                <div className="mb16">
                                                    {/* <TextField name="phone" label="Phone" type="text" max="13" value={patientFormData.phone} onChange={handleChange} /> */}
                                                    <Controller
                                                        control={control}
                                                        name='phone'
                                                        rules={{ required: true }}
                                                        render={({ field }) => (
                                                            <PhoneInput
                                                                country={'us'}
                                                                // value={userFormData.phone}
                                                                // onChange={(value,country,e)=>handleChange(e)}
                                                                // {...register('phone', { required: true })}
                                                                inputProps={{
                                                                    name: 'phone',
                                                                    style: { width: '100%' }
                                                                }}
                                                                {...field}
                                                                aria-label="Phone"
                                                                tabIndex={5}
                                                            />
                                                        )}
                                                    />
                                                    {errors.phone && <p role="alert" className="error-message">Please enter Phone Number</p>}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={5}>
                                                <div className="mb16">
                                                    <TextField
                                                        fullWidth
                                                        name="email"
                                                        label="Email"
                                                        type="text"
                                                        {...register('email', {
                                                            required: false,
                                                            pattern: {
                                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                message: "Please enter a valid Email Address"
                                                            }
                                                        })}
                                                        InputLabelProps={{ shrink: true }}
                                                        // value={userFormData.email} 
                                                        // onChange={handleChange}
                                                        aria-label="Email"
                                                        tabIndex={6}
                                                    />
                                                    {errors.email && <p role="alert" className="error-message">Please enter an Email Address</p>}
                                                    {errors.email && <p role="alert" className="error-message">{errors.email.message}</p>}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={2}></Grid>
                                            <Grid item xs={12} md={5}>
                                                <div className="mb16">
                                                    <TextField
                                                        fullWidth
                                                        name="addressLine1"
                                                        label="Appartment, House No., Flat"
                                                        type="text"
                                                        {...register('address.addressLine1', { required: true })}
                                                        InputLabelProps={{ shrink: true }}
                                                        // value={patientFormData.address.addressLine1} 
                                                        // onChange={handleAddressChange}
                                                        aria-label="Address Line 1"
                                                        tabIndex={7}
                                                    />
                                                    {errors.address?.addressLine1 && <p role="alert" className="error-message">Please enter Appartment or House No. or Flat</p>}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={5}>
                                                <div className="mb16">
                                                    <TextField
                                                        fullWidth
                                                        name="addressLine2"
                                                        label="Area, Street"
                                                        type="text"
                                                        {...register('address.addressLine2', { required: false })}
                                                        InputLabelProps={{ shrink: true }}
                                                        // value={patientFormData.address.addressLine2} 
                                                        // onChange={handleAddressChange}
                                                        aria-label="Address Line 2"
                                                        tabIndex={8}
                                                    />
                                                    {errors.address?.addressLine2 && <p role="alert" className="error-message">Please enter Area or Street</p>}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={2}></Grid>
                                            <Grid item xs={12} md={5}>
                                                <div className="mb16">
                                                    <TextField
                                                        fullWidth
                                                        name="city"
                                                        label="City"
                                                        type="text"
                                                        {...register('address.city', { required: true })}
                                                        InputLabelProps={{ shrink: true }}
                                                        // value={patientFormData.address.city}
                                                        // onChange={handleAddressChange}
                                                        aria-label="City"
                                                        tabIndex={9}
                                                    />
                                                    {errors.address?.city && <p role="alert" className="error-message">Please enter City</p>}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={5}>
                                                <div className="mb16">
                                                    <TextField
                                                        fullWidth
                                                        name="zipcode"
                                                        label="ZIP Code"
                                                        type="text"
                                                        max="6"
                                                        {...register('address.zipcode', { required: true })}
                                                        InputLabelProps={{ shrink: true }}
                                                        // value={patientFormData.address.zipcode} 
                                                        // onChange={handleAddressChange}
                                                        aria-label="Zip Code"
                                                        tabIndex={10}
                                                    />
                                                    {errors.address?.zipcode && <p role="alert" className="error-message">Please enter Zip Code</p>}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={2}></Grid>
                                            <Grid item xs={12} md={5}>
                                                <div className="mb16">
                                                    <TextField
                                                        fullWidth
                                                        name="state"
                                                        label="State"
                                                        type="text"
                                                        {...register('address.state', { required: true })}
                                                        InputLabelProps={{ shrink: true }}
                                                        // value={patientFormData.address.state} 
                                                        // onChange={handleAddressChange}
                                                        aria-label="State"
                                                        tabIndex={11}
                                                    />
                                                    {errors.address?.state && <p role="alert" className="error-message">Please enter State</p>}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={5}>
                                                <div className="mb16">
                                                    {/* <TextField name="country" label="Country" type="text" value={patientFormData.country} onChange={handleChange} /> */}
                                                    <Controller
                                                        control={control}
                                                        name='address.country'
                                                        rules={{ required: true }}
                                                        defaultValue={''}
                                                        render={({ field }) => (

                                                            <FormControl fullWidth>
                                                                <InputLabel id="country">Country</InputLabel>
                                                                <Select
                                                                    name="country"
                                                                    labelId="country-label"
                                                                    id="country-select"
                                                                    {...register('address.country', { required: true })}
                                                                    {...field}
                                                                    // value={patientFormData.address.country}
                                                                    // onChange={handleAddressChange}
                                                                    label="Country"
                                                                    aria-label="Country"
                                                                    tabIndex={12}
                                                                >
                                                                    {options.map((option) => (
                                                                        <MenuItem value={option.label}>
                                                                            <div className='layout layout-item-center'>
                                                                                <img
                                                                                    loading="lazy"
                                                                                    width="20"
                                                                                    srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`}
                                                                                    src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`}
                                                                                    alt=""
                                                                                />
                                                                                <span className='ml8'>{option.label}</span>
                                                                                {/* <span className='ml8'>{option.value}</span> */}
                                                                            </div>

                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        )}
                                                    />
                                                    {errors.address?.country && <p role="alert" className="error-message">Please enter Country</p>}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <p></p>
                        <Grid container>
                            <Grid item xs={12} md={10}>
                                <Card>
                                    <CardContent>
                                        <p><b>Health Details</b></p>
                                        <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                            <Grid item xs={12} md={5}>
                                                <div className="mb16">
                                                    <TextField
                                                        fullWidth
                                                        name="height"
                                                        label="Height"
                                                        type="number"
                                                        {...register('height', { required: true })}
                                                        InputLabelProps={{ shrink: true }}
                                                        // value={patientFormData.height}
                                                        // onChange={handleChange}
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                                                        }}
                                                        aria-label="Height"
                                                        tabIndex={13}
                                                    />
                                                    {errors.height && <p role="alert" className="error-message">Please enter Patient's Height</p>}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={5}>
                                                <div className="mb16">
                                                    {/* <TextField name="bloodGroup" label="Blood Group" type="text" value={patientFormData.bloodGroup} onChange={handleChange} /> */}
                                                    <Controller
                                                        control={control}
                                                        name='bloodGroup'
                                                        rules={{ required: false }}
                                                        defaultValue={''}
                                                        render={({ field }) => (
                                                            <FormControl fullWidth>
                                                                <InputLabel id="bloodgroup">Blood Group</InputLabel>
                                                                <Select
                                                                    name="bloodGroup"
                                                                    labelId="bloodgroup"
                                                                    id="bloodgroup-select"
                                                                    {...register('bloodGroup', { required: true })}
                                                                    {...field}
                                                                    // value={patientFormData.bloodGroup}
                                                                    // onChange={handleChange}
                                                                    label="Blood Group"
                                                                    aria-label="Blood Group"
                                                                    tabIndex={14}
                                                                >
                                                                    {configList['bloodGroup']?.map((bloodgroup) => (
                                                                        <MenuItem value={bloodgroup}>{bloodgroup}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        )}
                                                    />
                                                    {errors.bloodGroup && <p role="alert" className="error-message">Please enter Patient's Blood Group</p>}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={2}></Grid>
                                            <Grid item xs={12} md={5}>
                                                <div className="mb16">
                                                    <TextField
                                                        fullWidth
                                                        name="weight"
                                                        label="Weight"
                                                        type="number"
                                                        {...register('weight', { required: false })}
                                                        InputLabelProps={{ shrink: true }}
                                                        // value={patientFormData.weight}
                                                        // onChange={handleChange}
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">kgs</InputAdornment>,
                                                        }}
                                                        aria-label="Weignt"
                                                        tabIndex={15}
                                                    />
                                                    {errors.weight && <p role="alert" className="error-message">Please enter Patient's Weightp</p>}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={5}>
                                                <div className="mb16">
                                                    <TextField
                                                        fullWidth
                                                        name="bloodPressure"
                                                        label="Blood Pressure"
                                                        type="text"
                                                        {...register('bloodPressure', { required: false })}
                                                        InputLabelProps={{ shrink: true }}
                                                        // value={patientFormData.bloodPressure}
                                                        // onChange={handleChange}
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">mmHg</InputAdornment>,
                                                        }}
                                                        aria-label="Blood Pressure (S/D)"
                                                        tabIndex={16}
                                                    />
                                                </div>
                                                {errors.bloodPressure && <p role="alert" className="error-message">Please enter Patient's Blood Pressure</p>}
                                            </Grid>
                                            <Grid item xs={12} md={2}></Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <p></p>
                        <Grid container>
                            <Grid item xs={12} md={10}>
                                <Card>
                                    <CardContent>
                                        <p><b>Treatment Details</b></p>
                                        <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                            <Grid item xs={12} md={5}>
                                                <div className="mb16">
                                                    <Controller
                                                        control={control}
                                                        name='treatmentType'
                                                        rules={{ required: true }}
                                                        defaultValue={''}
                                                        render={({ field }) => (

                                                            <FormControl fullWidth>
                                                                <InputLabel id="treatmentType">Treatment Type</InputLabel>
                                                                <Select
                                                                    name="treatmentType"
                                                                    labelId="treatmenttype"
                                                                    id="treatment-type-select"
                                                                    {...register('treatmentType', { required: true })}
                                                                    {...field}
                                                                    // value={patientFormData.treatmentType}
                                                                    // onChange={handleChange}
                                                                    label="Treatment Type"
                                                                    aria-label="Treatment Type"
                                                                    tabIndex={17}
                                                                >
                                                                    {configList['treatmentType']?.map((treatment) => (
                                                                        <MenuItem value={treatment}>{treatment}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        )}
                                                    />
                                                    {errors.treatmentType && <p role="alert" className="error-message">Please enter Patient's Treatment Type</p>}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <p></p>
                        <Grid container>
                            <Grid item xs={12} md={10}>
                                <Card>
                                    <CardContent>
                                        <p><b>Hospital Details</b></p>
                                        <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                            <Grid item xs={12} md={5}>
                                                <div className="mb16">
                                                    <Controller
                                                        control={control}
                                                        name='hospitalUID'
                                                        rules={{ required: true }}
                                                        defaultValue={''}
                                                        render={({ field }) => (

                                                            <FormControl fullWidth>
                                                                <InputLabel id="hospitals" key='hospitals'>Hospital Name</InputLabel>
                                                                <Select
                                                                    name="hospitalUID"
                                                                    labelId="hospital"
                                                                    id="hospital-select"
                                                                    {...register('hospitalUID', { required: true })}
                                                                    {...field}
                                                                    // value={patientFormData.hospitalUID}
                                                                    // onChange={handleChange}
                                                                    label="Hospital"
                                                                    aria-label="Hospital"
                                                                    tabIndex={18}

                                                                >
                                                                    {hospitalList?.map((hospital) => (
                                                                        <MenuItem value={hospital.uid}>{hospital.hospitalName}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        )}
                                                    />
                                                    {errors.treatmentType && <p role="alert" className="error-message">Please select a Hospital</p>}
                                                </div>
                                            </Grid>

                                            {/* <Grid item xs={4}>
                                    <div className="mb16">
                                        <TextField 
                                            fullWidth
                                            name="department" 
                                            label="Department" 
                                            type="text" 
                                            {...register('department', { required: false })}
                                            InputLabelProps={{ shrink: true }}
                                            // value={patientFormData.department} 
                                            // onChange={handleChange} 
                                            />
                                    </div>
                                </Grid> */}
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                }
            </form >
            <Box component="footer">
                <Copyright sx={{ pb: 3, px: 3 }} />
            </Box>
        </div >
    )
}

export default EditPatient;