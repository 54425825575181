import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Icon from '@mui/material/Icon';
import logo from '../../assets/logos/PROCare_logo.png';
import IconDashboard from '../../assets/icons/Dashboard.png';
import IconPatients from '../../assets/icons/Patients.png';
import IconSettings from '../../assets/icons/Settings.png';
import IconStudies from '../../assets/icons/Studies.png';
import IconSurveys from '../../assets/icons/Surveys.png';
import IconUsers from '../../assets/icons/Users.png';
import Avatar from '@mui/material/Avatar';
import { deepOrange, deepPurple } from '@mui/material/colors';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Logout from '@mui/icons-material/Logout';
import TranslateIcon from '@mui/icons-material/Translate';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import "./Sidenav.css"
import { useAuth } from "../../auth.context";

function SideNav() {
    const { authState, logout } = useAuth();
    let navigate = useNavigate();

    useEffect(() => {
        console.log("authState", authState)
        if (!authState.authenticated) {
            navigate('/login');
        }
    }, [authState, navigate]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [language, setLanguage] = React.useState('');

    const handleChange = (event) => {
        setLanguage(event.target.value);
    };
    const generateAvatar= (firstName, lastName) => {
        return firstName.charAt(0).toUpperCase() + lastName?.charAt(0).toUpperCase();
    }
    return (
        <div className="sidenav layout layout-vertical layout-justify-space-between">
            <div>
                <div className="layout layout-item-center ml24 mb48">
                    <img src={logo} alt="PRO Care logo" width="135px" className="mt24" />
                </div>

                <ul className="side-nav-links">
                    <li>
                        {/* <div>
                            <img src={IconDashboard} />
                        </div>
                        <div className="ml8">
                            <Link to="/dashboard">Dashboard</Link>
                        </div> */}
                        <NavLink to="/dashboard" className="layout layout-item-center ml24"  activeClassName="active">
                            <div>
                                <Icon color="dark">dashboard</Icon>
                            </div>
                            <div className="ml8">
                                Dashboard
                            </div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/users" className="layout layout-item-center ml24"  activeClassName="active">
                            <div>
                                <Icon color="dark">person_outlined</Icon>
                            </div>
                            <div className="ml8">
                                Users
                            </div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/patients" className="layout layout-item-center ml24"  activeClassName="active">
                            <div>
                                <Icon color="dark">person_add_alt</Icon>
                            </div>
                            <div className="ml8">
                            Patients
                            </div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/hospitals" className="layout layout-item-center ml24"  activeClassName="active">
                            <div>
                                <Icon color="dark">local_hospital</Icon>
                            </div>
                            <div className="ml8">
                            Hospitals
                            </div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/surveys" className="layout layout-item-center ml24"  activeClassName="active">
                            <div>
                                <Icon color="dark">poll</Icon>
                            </div>
                            <div className="ml8">
                            Surveys
                            </div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/studies" className="layout layout-item-center ml24"  activeClassName="active">
                            <div>
                                <Icon color="dark">book</Icon>
                            </div>
                            <div className="ml8">
                            Studies
                            </div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/settings" className="layout layout-item-center ml24"  activeClassName="active">
                            <div>
                                <Icon color="dark">settings</Icon>
                            </div>
                            <div className="ml8">
                            Settings
                            </div>
                        </NavLink>
                    </li>
                </ul>
            </div>
            <div class="layout layout-horizontal layout-item-center mb24 ml24">

                <Avatar sx={{ bgcolor: deepOrange[500] }}>{generateAvatar(authState.user?.firstName||"",authState.user?.lastName||"" )}</Avatar>
                <p class="ml8"><b>{authState.user?.firstName + ' '+ authState.user?.lastName}</b></p>
                
                <ArrowForwardIosOutlinedIcon style={{width:16,marginLeft:4}} 
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                />
            </div>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    
                },
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            >
                <Link to={`/profile/${authState.user?.uid}`} style={{ textDecoration: 'none',color: 'rgba(0, 0, 0, 0.87)' }}>
                    <MenuItem >
                            <ListItemIcon>
                                <PersonOutlineOutlinedIcon fontSize="small" />
                            </ListItemIcon>
                            Profile
                    </MenuItem>
                </Link>
                
                <Divider />
                {/* <MenuItem onClick={handleClose}>
                    <div class="layout layout-item-center">
                        <div>
                        <ListItemIcon>
                            <TranslateIcon fontSize="small" />
                        </ListItemIcon>
                        </div>
                        <div>
                            Language
                        </div>
                        <div>
                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={language}
                                    defaultValue={"English"}
                                    onChange={handleChange}
                                >
                                    <MenuItem value="English">English</MenuItem>
                                    <MenuItem value="French">French</MenuItem>
                                    <MenuItem value="Spanish">Spanish</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    
                    </div>
                </MenuItem>
                <Divider /> */}
                <MenuItem onClick={logout}>
                <ListItemIcon>
                    <Logout fontSize="small" />
                </ListItemIcon>
                Logout
                </MenuItem>
            </Menu>
            
        </div>
    )
}

export default SideNav;