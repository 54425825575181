import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Icon } from '@mui/material';
import { Chip } from '@mui/material';
import { useEffect, useState } from 'react';
import Header from "../../Header/Header";

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import Grid from '@mui/material/Grid';
import { useNavigate, useParams } from "react-router-dom";

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

import CircularProgress from '@mui/material/CircularProgress';
import Copyright from '../../Copyright';

function ViewUser() {
    let { userId } = useParams();
    let navigate = useNavigate();
    // const [data, setData] = useState(null)
    const [userFormData, setUserFormData] = useState({
        firstName: '',
        lastName: '',
        dob: '',
        phone: '',
        email: '',
        gender: '',
        roles: [],
        hospitalName: ''
    })

    const [open, setOpen] = React.useState(false);
    const [isLoading, setisLoading] = React.useState(false);

    // const handleChange = (e) => {
    //     setUserFormData((userFormData) => ({
    //         ...userFormData,
    //         [e.target.name]: e.target.value
    //     }));
    // };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const action = (
        <React.Fragment>

            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );
    const [message, setMessage] = useState('');

    // const handleSubmit = () => {
    //     console.log("userdata", userFormData);
    //     let myHeaders = new Headers();
    //     myHeaders.append("Content-Type", "application/json");
    //     let requestOptions = {
    //         method: 'PUT',
    //         headers: myHeaders,
    //         body: JSON.stringify(userFormData),
    //         redirect: 'follow'
    //     };

    //     fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/users/" + userId, requestOptions)
    //         .then(response => response.text())
    //         .then(result => {
    //             console.log(result)

    //             setMessage(result);
    //             setOpen(true);
    //         })
    //         .catch(error => {
    //             console.log('error', error)
    //             setMessage(error);
    //             setOpen(true);
    //         });
    // };

    function backToUsers() {
        navigate('/users')
    }

    function gotoEditUser(uid) {
        navigate(`/users/edituser/${uid}`)
    }

    useEffect(() => {
        setisLoading(true);
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/users/` + userId)
            .then((res) => res.json())
            .then((data) => {
                setUserFormData({
                    ...data['data']
                });
                setisLoading(false);
                console.log("User Data", data);
            })
    }, [])

    const [roleList, setRoleList] = useState([])
    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/roles`)
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    setRoleList(data['data']['data']);
                }
            })
    }, [])



    return (
        <div>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message={message}
                action={action}
            />
            <Header>
                <div className="layout layout-item-center layout-justify-space-between">
                    <h2>View User - {userFormData.firstName} {userFormData.lastName} - {userFormData.pid}</h2>
                    <Stack spacing={2} direction="row">
                        <Button variant="text" onClick={backToUsers}>Cancel</Button>
                        <Button className="round-button" variant="contained" onClick={() => gotoEditUser(userFormData.uid)}>Edit</Button>
                    </Stack>
                </div>
            </Header>

            {isLoading == true ?
                <div className='layout layout-item-center layout-justify-center loadingProgress'>
                    <CircularProgress />
                </div>
                :
                <div style={{ padding: '24px' }}>
                    {/* <Box
                    id="userForm"
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                > */}

                    <Grid container>
                        <Grid item xs={12} md={10}>
                            <Card>
                                <CardContent>
                                    <p className='mb24'><strong>User Details</strong></p>
                                    <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb24">
                                                <p className='view-label mb8'>User ID</p>
                                                <p className='view-value'>{userFormData.pid}</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb24">
                                                <p className='view-label mb8'>Status</p>
                                                <p className='view-value'>{userFormData.status}</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={2}></Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <p></p>
                    <Grid container>
                        <Grid item xs={12} md={10}>
                            <Card>
                                <CardContent>
                                    <p className='mb24'><strong>Personal Details</strong></p>
                                    <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb24">
                                                <p className='view-label mb8'>First Name</p>
                                                <p className='view-value'>{userFormData.firstName}</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb24">
                                                <p className='view-label mb8'>Last Name</p>
                                                <p className='view-value'>{userFormData.lastName}</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb24">
                                                <p className='view-label mb8'>Gender</p>
                                                <p className='view-value'>{userFormData.gender}</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb24">
                                                <p className='view-label mb8'>Date of Birth</p>
                                                <p className='view-value'>{dayjs(userFormData.dob).format('DD/MM/YYYY')}</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb24">
                                                <p className='view-label mb8'>Phone</p>
                                                <p className='view-value'>{userFormData.phone ? userFormData.phone : "Not Available"}</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb24">
                                                <p className='view-label mb8'>Email</p>
                                                <p className='view-value'>{userFormData.email}</p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <p></p>
                    <Grid container>
                        <Grid item xs={12} md={10}>
                            <Card>
                                <CardContent>
                                    <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                        <Grid item xs={12} md={5}>
                                            <p className='mb24'><strong>Hospital Details</strong></p>
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <p className='mb24'><strong>Role Details</strong></p>
                                        </Grid>
                                    </Grid>
                                    {userFormData.roles?.length > 0 ? userFormData.roles.map((hospitalrole) => (
                                        <div>
                                            <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                                <Grid item xs={12} md={5}>
                                                    <div className="mb24">
                                                        {/* <p className='view-label mb8'>Hospital Name</p> */}
                                                        <p className='view-value'>{hospitalrole.hospitalName}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={5}>
                                                    <div className="mb24 view-value">
                                                        {/* <p className='view-label mb8'>Roles</p> */}
                                                        <Stack direction="row" spacing={1}>
                                                            {hospitalrole.roleUIDs?.length > 0 ? hospitalrole.roleUIDs.map((role) => (
                                                                <Chip key={role} label={roleList?.find((item) => item.uid === role)?.name || ''} variant="outlined" />
                                                            )) : 'Not Available'}
                                                        </Stack>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item xs={8}>
                                                    <div style={{ margin: '0px 0 16px', backgroundColor: '#f5f5f5', height: '1px' }}></div>
                                                </Grid>
                                            </Grid>

                                        </div>

                                    )) :
                                        'Not Available'}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    {/* <p></p>
                    <Card>
                        <CardContent>
                            <p className='mb24'><strong>Hospital Details</strong></p>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <div className="mb24">
                                        <p className='view-label mb8'>Hospital Name</p>
                                        <p className='view-value'>{userFormData.hospitalName}</p>
                                    </div>
                                </Grid>

                                <Grid item xs={4}>

                                    <div className="mb24">
                                        <p className='view-label mb8'>Department</p>
                                        <p className='view-value'>{userFormData.department ? userFormData.department : "Not Available"}</p>
                                    </div>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card> */}
                    {/* </Box> */}
                </div>
            }
            <Box component="footer">
                <Copyright sx={{ pb: 3, px: 3 }} />
            </Box>
        </div>
    )
}

export default ViewUser;