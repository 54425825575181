import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Icon } from '@mui/material';
import { Chip } from '@mui/material';
import { useEffect, useState } from 'react';
import Header from "../../Header/Header";

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import Grid from '@mui/material/Grid';
import { useNavigate, useParams } from "react-router-dom";

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

import CircularProgress from '@mui/material/CircularProgress';
import Copyright from '../../Copyright';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    // [`&.${tableCellClasses.head}`]: {
    //   backgroundColor: theme.palette.common.black,
    //   color: theme.palette.common.white,
    // },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // '&:nth-of-type(odd)': {
    //   backgroundColor: theme.palette.action.hover,
    // },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

let rows = []

function ViewSurvey() {
    let { surveyId } = useParams();
    let navigate = useNavigate();
    const [data, setData] = useState(null)
    const [surveyFormData, setSurveyFormData] = useState({
        firstName: '',
        lastName: '',
        dob: '',
        phone: '',
        email: '',
        gender: '',
        roles: [],
        hospitalName: ''
    })

    // const [surveyQuestionsFormData, setSurveyQuestionsFormData] = useState({
    //     firstName: '',
    //     lastName: '',
    //     dob: '',
    //     phone: '',
    //     email: '',
    //     gender: '',
    //     roles: [],
    //     hospitalName: ''
    // })

    const [open, setOpen] = React.useState(false);

    const [isLoading, setisLoading] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const action = (
        <React.Fragment>

            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    const [message, setMessage] = useState('');

    function backToSurveys() {
        navigate('/surveys')
    }

    function gotoEditSurvey(uid) {
        navigate(`/surveys/editsurvey/${uid}`)
    }

    function editQuestion(uid) {
        console.log("uid", uid);
        // navigate('/edithospital/' + uid);
    }

    useEffect(() => {
        setisLoading(true);
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/surveys/` + surveyId)
            .then((res) => res.json())
            .then((data) => {
                setSurveyFormData({
                    ...data['data']
                });
                setisLoading(false);
                console.log("Survey Data", data);
            })
    }, [])

    // useEffect(() => {
    //     fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/surveys/` + surveyId + '/questions')
    //         .then((res) => res.json())
    //         .then((data) => {
    //             setSurveyQuestionsFormData({
    //                 ...data['data']
    //             });
    //             console.log("Survey Questions Data", data);
    //         })
    // }, [])

    return (
        <div>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message={message}
                action={action}
            />

            <Header>
                <div className="layout layout-item-center layout-justify-space-between">
                    <h2>View Survey - {surveyFormData.surveyName} - {surveyFormData.pid}</h2>
                    <Stack spacing={2} direction="row">
                        <Button variant="text" onClick={backToSurveys}>Cancel</Button>
                        <Button className="round-button" variant="contained" onClick={() => gotoEditSurvey(surveyFormData.uid)}>Edit</Button>
                    </Stack>
                </div>
            </Header>

            {isLoading == true ?
                <div className='layout layout-item-center layout-justify-center loadingProgress'>
                    <CircularProgress />
                </div>
                :
                <div style={{ padding: '24px' }}>
                    <Grid container>
                        <Grid item xs={12} md={10}>
                            <Card>
                                <CardContent>
                                    <p className='mb24'><strong>Basic Details</strong></p>
                                    <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb24">
                                                <p className='view-label mb8'>Survey ID</p>
                                                <p className='view-value'>{surveyFormData.pid}</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb24">
                                                <p className='view-label mb8'>Status</p>
                                                <p className='view-value'>{surveyFormData.status}</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={2}></Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <p></p>
                    <Grid container>
                        <Grid item xs={12} md={10}>
                            <Card>
                                <CardContent>
                                    <p className='mb24'><strong>Survey Details</strong></p>
                                    <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb24">
                                                <p className='view-label mb8'>Survey Name</p>
                                                <p className='view-value'>{surveyFormData.surveyName}</p>
                                            </div>
                                            {/* </Grid> */}
                                            {/* <Grid item xs={12} md={5}>
                                            <div className="mb24">
                                                <p className='view-label mb8'>Status</p>
                                                <p className='view-value'>{surveyFormData.status}</p>
                                            </div>
                                        </Grid> */}
                                            {/* <Grid item xs={12} md={5}> */}
                                            <div className="mb24">
                                                <p className='view-label mb8'>Description</p>
                                                <p className='view-value'>{surveyFormData.surveyDescription}</p>
                                            </div>
                                        </Grid>
                                        {/* <Grid item xs={12} md={2}></Grid> */}
                                        <Grid item xs={12} md={5}>
                                            <div className="mb24">
                                                <p className='view-label mb8'>Can be Survey Set</p>
                                                <p className='view-value'>{surveyFormData.canBeSurveySet = true ? "Yes" : "No"}</p>
                                            </div>
                                            {/* </Grid>
                                        <Grid item xs={12} md={5}> */}
                                            <div className="mb24">
                                                <p className='view-label mb8'>Can be Survey Subset</p>
                                                <p className='view-value'>{surveyFormData.canBeSubset = true ? "Yes" : "No"}</p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <p></p>
                    <Grid container>
                        <Grid item xs={12} md={10}>
                            <Card>
                                <CardContent>
                                    <p className='mb24'><strong>Survey Questions</strong></p>
                                    {/* <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>Question No.</StyledTableCell>
                                            <StyledTableCell>Variable ID</StyledTableCell>
                                            <StyledTableCell>Item</StyledTableCell>
                                            <StyledTableCell>Question</StyledTableCell>
                                            <StyledTableCell>Source</StyledTableCell>
                                            <StyledTableCell>Actions</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row) => (
                                            <StyledTableRow key={row.pid}>
                                                <StyledTableCell>{row.hospitalName}</StyledTableCell>
                                                <StyledTableCell>{row.city}</StyledTableCell>
                                                <StyledTableCell>{row.state}</StyledTableCell>
                                                <StyledTableCell>{row.country}</StyledTableCell>
                                                <StyledTableCell>
                                                    <IconButton aria-label="create">
                                                        <Icon color="dark" onClick={() => editQuestion(row.uid)}>create_outlined</Icon>
                                                    </IconButton>
                                                    <IconButton aria-label="archive" title='Archive'>
                                                        <Icon color="dark">archive_outlined</Icon>
                                                    </IconButton>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer> */}
                                    <TableContainer >
                                        <Table sx={{ minWidth: 700 }} aria-label="customized table" size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell width={'100px'}>Sequence</StyledTableCell>
                                                    <StyledTableCell>Item</StyledTableCell>
                                                    <StyledTableCell width={'500px'}>Question</StyledTableCell>
                                                    <StyledTableCell>Question Type</StyledTableCell>
                                                    <StyledTableCell>Reporting Source</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {surveyFormData?.questions?.length > 0 ? surveyFormData?.questions?.map((row, index) => (
                                                    <StyledTableRow key={row.pid}>
                                                        <StyledTableCell scope="row">
                                                            {/* <NavLink to={`/viewuser/${row.uid}`} exact activeClassName="active"> */}
                                                            {row.priorityLevel}
                                                            {/* </NavLink> */}
                                                        </StyledTableCell>
                                                        <StyledTableCell>{row.item}</StyledTableCell>
                                                        <StyledTableCell>{row.displayedValue}</StyledTableCell>
                                                        <StyledTableCell>{row.type}</StyledTableCell>
                                                        {/* <StyledTableCell>{row.timing}</StyledTableCell> */}

                                                        <StyledTableCell>{row.reportingSource}</StyledTableCell>
                                                    </StyledTableRow>
                                                )) : <StyledTableRow><StyledTableCell colSpan={8} align='center'>No Records Available</StyledTableCell></StyledTableRow>}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            }
            <Box component="footer">
                <Copyright sx={{ pb: 3, px: 3 }} />
            </Box>
        </div>
    )
}



export default ViewSurvey;