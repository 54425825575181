import * as React from 'react';

import underConstructionImage from '../../assets/illustrations/under-construction.png';



function Settings() {
	return (
		<div>
            <div className="layout layout-item-center layout-justify-center">
                <h1>We are currently working on this feature and will launch soon!</h1>
            </div>
            <div className="layout layout-item-center layout-justify-center">
                <div>
                    <img src={underConstructionImage} alt="Under Construction Image" width="512px" />
                </div>
            </div>
        </div>
	)
}

export default Settings;