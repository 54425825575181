import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Icon } from '@mui/material';
import { Chip } from '@mui/material';
import { useEffect, useState } from 'react';
import Header from "../../Header/Header";

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import Grid from '@mui/material/Grid';
import { useNavigate, useParams } from "react-router-dom";

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

import CircularProgress from '@mui/material/CircularProgress';
import Copyright from '../../Copyright';



function ViewHospital() {
    let { hospitalId } = useParams();
    let navigate = useNavigate();
    const [data, setData] = useState(null)
    const [hospitalFormData, setHospitalFormData] = useState({
        hospitalName: '',
        address: {
            street: '',
            city: '',
            state: '',
            zip: '',
            country: ''
        }
    })

    const [open, setOpen] = React.useState(false);

    const [isLoading, setisLoading] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    const [message, setMessage] = useState('');

    function backToHospitals() {
        navigate('/hospitals')
    }

    function gotoEditHospital(uid) {
        navigate(`/hospitals/edithospital/${uid}`)
    }

    useEffect(() => {
        setisLoading(true);
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/hospitals/` + hospitalId)
            .then((res) => res.json())
            .then((data) => {
                setHospitalFormData({
                    ...data['data']
                });
                setisLoading(false);
                console.log("Hospital data", data);
            })
    }, [])



    return (
        <div>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message={message}
                action={action}
            />
            <Header>
                <div className="layout layout-item-center layout-justify-space-between">
                    <h2>View Hospital - {hospitalFormData.hospitalName} - {hospitalFormData.pid}</h2>
                    <Stack spacing={2} direction="row">
                        <Button variant="text" onClick={backToHospitals}>Cancel</Button>
                        <Button className="round-button" variant="contained" onClick={() => gotoEditHospital(hospitalFormData.uid)}>Edit</Button>
                    </Stack>
                </div>
            </Header>

            {isLoading == true ?
                <div className='layout layout-item-center layout-justify-center loadingProgress'>
                    <CircularProgress />
                </div>
                :
                <div style={{ padding: '24px' }}>
                    {/* <Box
                    id="hospitalForm"
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                > */}
                    <Grid container>
                        <Grid item xs={12} md={10}>

                            <Card>
                                <CardContent>
                                    <p className='mb24'><strong>Hospital Details</strong></p>
                                    <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb24">
                                                <p className='view-label mb8'>Hospital Name</p>
                                                <p className='view-value'>{hospitalFormData.hospitalName}</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb24">
                                                <p className='view-label mb8'>Street</p>
                                                <p className='view-value'>{hospitalFormData.address.street}</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={2}></Grid>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb24">
                                                <p className='view-label mb8'>City</p>
                                                <p className='view-value'>{hospitalFormData.address.city}</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb24">
                                                <p className='view-label mb8'>ZIP Code</p>
                                                <p className='view-value'>{hospitalFormData.address.zipcode}</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={2}></Grid>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb24">
                                                <p className='view-label mb8'>State</p>
                                                <p className='view-value'>{hospitalFormData.address.state}</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb24">
                                                <p className='view-label mb8'>Country</p>
                                                <p className='view-value'>{hospitalFormData.address.country}</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={2}></Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    {/* </Box> */}
                </div>
            }
            <Box component="footer">
                <Copyright sx={{ pb: 3, px: 3 }} />
            </Box>
        </div>
    )
}

export default ViewHospital;