import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Icon } from '@mui/material';
import { Chip } from '@mui/material';
import { useEffect, useState, useMemo } from 'react';
import Header from '../../Header/Header';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import Grid from '@mui/material/Grid';
import { useNavigate, useParams } from "react-router-dom";

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';

import patientAddSuccessImage from '../../../assets/illustrations/patient-add-success.png';
import errorImage from '../../../assets/illustrations/error.png';

import countryList from 'react-select-country-list';
import { useForm, Controller, useFieldArray } from "react-hook-form";

import CircularProgress from '@mui/material/CircularProgress';
import Copyright from '../../Copyright';



function EditHospital() {

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        clearErrors,
    } = useForm({
        defaultValues: async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/hospitals/` + hospitalId);
            const data = await response.json();
            // console.log(data);
            return data['data'];
        },
    })

    const onSubmit = (data) => {
        console.log(JSON.stringify(data));
        let myHeaders = new Headers();
        let updatedData = {}
        updatedData.address = { 'uid': '', 'street': '', 'city': '', 'state': '', 'zipcode': '', 'country': '' };
        updatedData['hospitalName'] = data['hospitalName'];
        updatedData['address']['uid'] = data['address']['uid'];
        updatedData['address']['street'] = data['address']['street'];
        updatedData['address']['city'] = data['address']['city'];
        updatedData['address']['state'] = data['address']['state'];
        updatedData['address']['zipcode'] = data['address']['zipcode'];
        updatedData['address']['country'] = data['address']['country'];
        myHeaders.append("Content-Type", "application/json");
        let requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: JSON.stringify(updatedData),
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/hospitals/` + hospitalId, requestOptions)
            .then(response => response.text())
            .then(result => {
                if (!JSON.parse(result).err) {
                    console.log(result)
                    setOpenDialog(true);
                } else {
                    console.log('error', JSON.parse(result).err)
                    setErrorDialog(true);
                    setErrMsg(JSON.parse(result).err);
                }
            })
            .catch(error => console.log('error', error));

    }

    let { hospitalId } = useParams();
    let navigate = useNavigate();
    const [data, setData] = useState(null)
    const [hospitalFormData, setHospitalFormData] = useState({
        hospitalName: '',
        addresses: {
            street: '',
            city: '',
            state: '',
            zipcode: '',
            country: ''
        }
    })

    const [open, setOpen] = React.useState(false);

    const [isLoading, setisLoading] = React.useState(false);

    const handleChange = (e) => {
        setHospitalFormData((hospitalFormData) => ({
            ...hospitalFormData,
            [e.target.name]: e.target.value
        }));
    };
    const handleAddress = (e) => {
        let address = JSON.parse(JSON.stringify(hospitalFormData.address));
        address = { ...address, [e.target.name]: e.target.value };
        setHospitalFormData((hospitalFormData) => ({
            ...hospitalFormData,
            ['address']: address
        }));
    };

    // const handleClose = (event, reason) => {
    //   if (reason === 'clickaway') {
    //     return;
    //   }

    //   setOpen(false);
    // };
    // const action = (
    //   <React.Fragment>

    //     <IconButton
    //       size="small"
    //       aria-label="close"
    //       color="inherit"
    //       onClick={handleClose}
    //     >
    //       <CloseIcon fontSize="small" />
    //     </IconButton>
    //   </React.Fragment>
    // );
    // const [message, setMessage] = useState('');
    //   const handleSubmit = () => {
    //     console.log("userdata",hospitalFormData);
    //     let myHeaders = new Headers();
    //     myHeaders.append("Content-Type", "application/json");
    //     let requestOptions = {
    //         method: 'PUT',
    //         headers: myHeaders,
    //         body: JSON.stringify(hospitalFormData),
    //         redirect: 'follow'
    //       };

    //       fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/users/"+hospitalId, requestOptions)
    //         .then(response => response.text())
    //         .then(result => {
    //             console.log(result)

    //             setMessage(result);
    //             setOpen(true);
    //         })
    //         .catch(error => {
    //             console.log('error', error)
    //             setMessage(error);
    //             setOpen(true);
    //         });
    // };

    function backToHospitals() {
        navigate('/hospitals')
    }

    // useEffect(() => {
    //     fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/hospitals/'+hospitalId)
    //         .then((res) => res.json())
    //         .then((data) =>{ 
    //             console.log("type",typeof(data['data']['roles']))
    //             setUserFormData({
    //                 ...data['data'],
    //                 ['roles']: typeof(data['data']['roles']) == "string" ? [] :data['data']['roles']
    //               });
    //             console.log("User data Edit",data);
    //         })
    // }, [])

    useEffect(() => {
        setisLoading(true);
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/hospitals/` + hospitalId)
            .then((res) => res.json())
            .then((data) => {
                setHospitalFormData({
                    ...data['data']
                });
                setisLoading(false);
                console.log("Hospital data", data);
            })
    }, [])

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const theme = useTheme();

    // Dialog Declarations
    const [openDialog, setOpenDialog] = React.useState(false);
    const [errorDialog, setErrorDialog] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState('');

    // const handleClickOpen = () => {
    //     setOpenDialog(true);
    // };



    const handleClose = () => {
        setOpenDialog(false);
    };
    const closeErrorDialog = () => {
        setErrorDialog(false);
    };

    const options = useMemo(() => countryList().getData(), [])



    return (
        <div>

            <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{ sx: { borderRadius: "16px" } }}
            >
                <DialogTitle id="alert-dialog-title">
                </DialogTitle>
                <DialogContent className='pad48'>
                    <DialogContentText id="alert-dialog-description">
                        <div className="layout layout-vertical layout-justify-center layout-item-center">
                            <img src={patientAddSuccessImage} alt="" />
                            <p className='dialog-description'>
                                Hospital updated successfully!
                            </p>
                            <p></p>
                        </div>

                    </DialogContentText>
                </DialogContent>
                <DialogActions className="dialog-action-bg">
                    <div className='layout layout-item-center layout-justify-center layout-full-width mt16 mb16'>
                        <Button variant="text" onClick={backToHospitals}>Close</Button>
                        {/* <Button className="round-button ml24" variant="outlined" onClick={backToViewUser}>View User</Button> */}
                    </div>

                </DialogActions>
            </Dialog>
            <Dialog
                open={errorDialog}
                onClose={closeErrorDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{ sx: { borderRadius: "16px" } }}
            >
                <DialogTitle id="alert-dialog-title">
                </DialogTitle>
                <DialogContent className='pad48'>
                    <DialogContentText id="alert-dialog-description">
                        <div className="layout layout-vertical layout-justify-center layout-item-center">
                            <img src={errorImage} alt="" height="256px" />
                            <p className='dialog-description' style={{ 'color': '#B04933' }}>
                                Something Went Wrong!
                            </p>
                            <p style={{ textTransform: 'capitalize', textAlign: 'center' }}>{errMsg}</p>
                        </div>

                    </DialogContentText>
                </DialogContent>
                <DialogActions className="dialog-action-bg">
                    <div className='layout layout-item-center layout-justify-center layout-full-width mt16 mb16'>
                        <Button variant="text" onClick={closeErrorDialog}>Close</Button>
                    </div>

                </DialogActions>
            </Dialog>
            <form onSubmit={handleSubmit(onSubmit)}  >
                <Header>
                    <div className="layout layout-item-center layout-justify-space-between">
                        <h2>Edit Hospital - {hospitalFormData.hospitalName} {hospitalFormData.lastName} - {hospitalFormData.pid}</h2>
                        <Stack spacing={2} direction="row">
                            <Button variant="text" onClick={backToHospitals}>Cancel</Button>
                            <Button className="round-button" variant="contained" type='submit'>Save</Button>
                        </Stack>
                    </div>
                </Header>

                {isLoading == true ?
                    <div className='layout layout-item-center layout-justify-center loadingProgress'>
                        <CircularProgress />
                    </div>
                    :
                    <div style={{ padding: '24px' }}>
                        <Grid container>
                            <Grid item xs={12} md={10}>

                                <Card>
                                    <CardContent>
                                        <p><b>Hospital Details</b></p>
                                        <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                            <Grid item xs={12} md={5}>
                                                <div className="mb16">
                                                    <TextField
                                                        fullWidth
                                                        name="hospitalName"
                                                        label="Hospital Name"
                                                        type="text"
                                                        {...register('hospitalName', { required: true, maxLength: 30 })}
                                                        InputLabelProps={{ shrink: true }}
                                                        // value={hospitalFormData.hospitalName} 
                                                        // onChange={handleChange}
                                                        aria-label="Hospital Name"
                                                        aria-invalid={errors.hospitalName ? "true" : "false"}
                                                        tabIndex={1}
                                                        autoFocus
                                                    />
                                                    {errors.hospitalName && errors.hospitalName.type === "required" && <p role="alert" className="error-message">Please enter Hospital Name</p>}
                                                    {errors.hospitalName && errors.hospitalName.type === "maxLength" && <p role="alert" className="error-message">Please enter a shorter Hospital Name</p>}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={5}>
                                                <div className="mb16">
                                                    <TextField
                                                        fullWidth
                                                        name="street"
                                                        label="Street"
                                                        type="text"
                                                        {...register('address.street', { required: true })}
                                                        InputLabelProps={{ shrink: true }}
                                                        // value={hospitalFormData.street} 
                                                        // onChange={handleChange}
                                                        aria-label="Street"
                                                        tabIndex={4}
                                                    />
                                                    {errors.address?.street && <p role="alert" className="error-message">Please enter Street details</p>}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={2}></Grid>
                                            <Grid item xs={12} md={5}>
                                                <div className="mb16">
                                                    <TextField
                                                        fullWidth
                                                        name="city"
                                                        label="City"
                                                        type="text"
                                                        {...register('address.city', { required: true })}
                                                        InputLabelProps={{ shrink: true }}
                                                        // value={hospitalFormData.city} 
                                                        // onChange={handleChange}
                                                        aria-label="City"
                                                        tabIndex={2}
                                                    />
                                                    {errors.address?.city && <p role="alert" className="error-message">Please enter City Name</p>}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={5}>
                                                <div className="mb16">
                                                    <TextField
                                                        fullWidth
                                                        name="zipcode"
                                                        label="ZIP Code"
                                                        type="text"
                                                        max="6"
                                                        {...register('address.zipcode', { required: true, maxLength: 6 })}
                                                        InputLabelProps={{ shrink: true }}
                                                        // value={hospitalFormData.zipcode} 
                                                        // onChange={handleChange}
                                                        aria-label="ZIP Code"
                                                        aria-invalid={errors.zipcode ? "true" : "false"}
                                                        tabIndex={3}
                                                    />
                                                    {errors.address?.zipcode && errors.address?.zipcode.type === "required" && <p role="alert" className="error-message">Please enter ZIP Code</p>}
                                                    {errors.address?.zipcode && errors.address?.zipcode.type === "maxLength" && <p role="alert" className="error-message">ZIP Code should be 6 characters</p>}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={2}></Grid>
                                            <Grid item xs={12} md={5}>
                                                <div className="mb16">
                                                    <TextField
                                                        fullWidth
                                                        name="state"
                                                        label="State"
                                                        type="text"
                                                        {...register('address.state', { required: true })}
                                                        InputLabelProps={{ shrink: true }}
                                                        // value={hospitalFormData.state} 
                                                        // onChange={handleChange}
                                                        aria-label="State"
                                                        tabIndex={5}
                                                    />
                                                    {errors.address?.state && <p role="alert" className="error-message">Please enter State</p>}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={5}>
                                                <div className="mb16">
                                                    <Controller
                                                        control={control}
                                                        name='address.country'
                                                        rules={{ required: true }}
                                                        defaultValue={''}
                                                        render={({ field }) => (

                                                            <FormControl fullWidth>
                                                                <InputLabel id="country">Country</InputLabel>
                                                                <Select
                                                                    name="country"
                                                                    labelId="country-label"
                                                                    id="country-select"
                                                                    {...field}
                                                                    // value={patientFormData.address.country}
                                                                    // onChange={handleAddressChange}
                                                                    label="Country"
                                                                    aria-label="Country"
                                                                    tabIndex={6}
                                                                >
                                                                    {options.map((option) => (
                                                                        <MenuItem value={option.label}>
                                                                            <div className='layout layout-item-center'>
                                                                                <img
                                                                                    loading="lazy"
                                                                                    width="20"
                                                                                    srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`}
                                                                                    src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`}
                                                                                    alt=""
                                                                                />
                                                                                <span className='ml8'>{option.label}</span>
                                                                                {/* <span className='ml8'>{option.value}</span> */}
                                                                            </div>

                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        )}
                                                    />
                                                    {errors.address?.country && <p role="alert" className="error-message">Please enter Country</p>}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={2}></Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                }
            </form>
            <Box component="footer">
                <Copyright sx={{ pb: 3, px: 3 }} />
            </Box>
        </div >
    )
}

export default EditHospital;