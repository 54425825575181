import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Card, CardContent, Box, Stack } from "@mui/material";

import Header from "../../Header/Header";
import Copyright from "../../Copyright";
import SurveyDetailsForm from "./SurveyDetailsForm";
import SurveyQuestionsTable from "./SurveyQuestionsTable";
import QuestionFormDialog from "./QuestionFormDialog";
import { SuccessDialog, ErrorDialog } from "./DialogComponents";

function CreateSurvey() {
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [questionList, setQuestionList] = useState([]);
  const [configList, setConfigList] = useState({});
  const [surveySubsets, setSurveySubsets] = useState([]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      surveyName: "",
      surveyDescription: "",
      surveySubSets: [],
    },
  });

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/configs`)
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setConfigList(data.data.data);
        }
      });
  }, []);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/surveys?can_be_subset=true`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setSurveySubsets(data.data?.data);
        }
      });
  }, []);
  useEffect(() => {
    console.log(`formerrors`, errors);
  }, [errors]);
  const onSubmit = (data) => {
    const surveyData = {
      ...data,
      questions: questionList,
    };
    fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/surveys`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(surveyData),
    })
      .then((response) => response.json())
      .then((result) => {
        if (!result.err) {
          setSuccessDialog(true);
        } else {
          setErrorDialog(true);
          setErrMsg(result.err);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setErrorDialog(true);
        setErrMsg("An unexpected error occurred");
      });
  };

  const handleAddQuestion = (questionData) => {
    setQuestionList([...questionList, questionData]);
    setOpenDialog(false);
  };

  const handleDeleteQuestion = (index) => {
    const updatedQuestions = questionList.filter((_, i) => i !== index);
    setQuestionList(updatedQuestions);
  };

  return (
    <div>
      <SuccessDialog
        open={successDialog}
        onClose={() => navigate("/surveys")}
        message="Survey added successfully!"
      />
      <ErrorDialog
        open={errorDialog}
        onClose={() => setErrorDialog(false)}
        errorMessage={errMsg}
      />
      <QuestionFormDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onSave={handleAddQuestion}
        configList={configList}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Header>
          <div className="layout layout-item-center layout-justify-space-between">
            <h2>Create Survey</h2>
            <Stack spacing={2} direction="row">
              <Button variant="text" onClick={() => navigate("/surveys")}>
                Cancel
              </Button>
              <Button
                className="round-button"
                variant="contained"
                type="submit"
              >
                Save Survey
              </Button>
            </Stack>
          </div>
        </Header>

        <div style={{ padding: "24px" }}>
          <Grid container>
            <Grid item xs={12} md={10}>
              <Card className="mb16">
                <CardContent>
                  <SurveyDetailsForm
                    register={register}
                    errors={errors}
                    control={control}
                    surveySubsets={surveySubsets}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} md={10}>
              <Card>
                <CardContent>
                  <SurveyQuestionsTable
                    questionList={questionList}
                    onDeleteQuestion={handleDeleteQuestion}
                    onAddQuestion={() => setOpenDialog(true)}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </form>
      <Box component="footer">
        <Copyright sx={{ pb: 3, px: 3 }} />
      </Box>
    </div>
  );
}

export default CreateSurvey;
