import React, { createContext, useReducer, useContext, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const AuthContext = createContext();

function authReducer(state, action) {
  switch (action.type) {
    case 'AUTH_INIT_REQUEST':
      return { ...state, isLoading: true, isInitialized: false };
    case 'AUTH_INIT_SUCCESS':
      return { ...state, isLoading: false, isInitialized: true, authenticated: action.payload.authenticated, user: action.payload.user };
    case 'LOGIN_REQUEST':
      return { ...state, isLoading: true, error: null };
    case 'LOGIN_SUCCESS':
      return { ...state, isLoading: false, authenticated: true, user: action.payload, error: null };
    case 'LOGIN_FAILURE':
      return { ...state, isLoading: false, error: action.payload };
    case 'LOGOUT':
      return { ...state, isLoading: false, authenticated: false, user: null, error: null };
    default:
      return state;
  }
}

const initialState = {
  isInitialized: false,
  isLoading: true,
  user: null,
  error: null,
  authenticated: false
};

export const AuthProvider = ({ children }) => {
  const [authState, dispatch] = useReducer(authReducer, initialState);
  const navigate = useNavigate();
  const location = useLocation();

  const saveLastPath = useCallback((path) => {
    const publicRoutes = ['/login', '/signup', '/forgotpassword', '/create-password'];
    if (!publicRoutes.includes(path)) {
      localStorage.setItem('lastPath', path);
    }
  }, []);

  useEffect(() => {
    const initializeAuth = async () => {
      dispatch({ type: 'AUTH_INIT_REQUEST' });
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      if (token) {
        try {
          const user = await fetchUserDetail(userId);
          dispatch({ type: 'AUTH_INIT_SUCCESS', payload: { authenticated: true, user } });
        } catch (error) {
          console.error('Failed to fetch user details:', error);
          dispatch({ type: 'AUTH_INIT_SUCCESS', payload: { authenticated: false, user: null } });
        }
      } else {
        dispatch({ type: 'AUTH_INIT_SUCCESS', payload: { authenticated: false, user: null } });
      }
    };

    initializeAuth();
  }, []);

  useEffect(() => {
    if (authState.isInitialized && authState.authenticated) {
      const lastPath = localStorage.getItem('lastPath') || '/dashboard';
      if (location.pathname === '/login') {
        navigate(lastPath);
      } else {
        saveLastPath(location.pathname);
      }
    }
  }, [authState.isInitialized, authState.authenticated, location.pathname, navigate, saveLastPath]);

  const login = async (username, password) => {
    dispatch({ type: 'LOGIN_REQUEST' });
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/auth/signin`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ authType: "password", email: username, password })
      });
      const data = await response.json();
      if (response.ok) {
        localStorage.setItem('token', data?.data?.token);
        localStorage.setItem('userId', data?.data?.id);
        const user = await fetchUserDetail(data?.data?.id);
        dispatch({ type: 'LOGIN_SUCCESS', payload: { ...data, ...user } });
      } else {
        dispatch({ type: 'LOGIN_FAILURE', payload: data.data || data.message });
      }
    } catch (error) {
      dispatch({ type: 'LOGIN_FAILURE', payload: error.message });
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    dispatch({ type: 'LOGOUT' });
    navigate('/login');
  };

  return (
    <AuthContext.Provider value={{ authState, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

async function fetchUserDetail(id) {
  const userDetailRequest = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/users/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  });
  const userDetail = await userDetailRequest.json();
  const user = userDetail.data;
  return user;
}