import React from 'react';
import {
  Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton,
  tableCellClasses
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const SurveyQuestionsTable = ({ questionList, onDeleteQuestion, onAddQuestion }) => {
  return (
    <>
      <div className='layout layout-item-center layout-justify-space-between mb16'>
        <p><b>Survey Questions</b></p>
        <div className='layout layout-item-center'>
          <Button variant="text">Import</Button>
          <Button className="round-button ml16" variant="outlined" onClick={onAddQuestion}>Add New</Button>
        </div>
      </div>

      <TableContainer>
        <Table sx={{ minWidth: 700 }} aria-label="customized table" size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>Sequence</StyledTableCell>
              <StyledTableCell>Item</StyledTableCell>
              <StyledTableCell>Question</StyledTableCell>
              <StyledTableCell>Question Type</StyledTableCell>
              <StyledTableCell>Reporting Source</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {questionList.length > 0 ? questionList.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>{index + 1}</StyledTableCell>
                <StyledTableCell>{row.item}</StyledTableCell>
                <StyledTableCell>{row.displayedValue}</StyledTableCell>
                <StyledTableCell>{row.type}</StyledTableCell>
                <StyledTableCell>{row.reportingSource}</StyledTableCell>
                <StyledTableCell>
                  <IconButton onClick={() => onDeleteQuestion(index)}>
                    <DeleteIcon />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            )) : (
              <StyledTableRow>
                <StyledTableCell colSpan={6} align='center'>No Records Available</StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default SurveyQuestionsTable;
