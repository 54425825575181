import React from "react";
import "./Header.css";
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';

import { useNavigate } from "react-router-dom";
// import SideNav from "../SideNav/Sidenav";

const Header = ({children}) => {
    let navigate = useNavigate();

    // const handleChange = (event: SelectChangeEvent) => {
    //     setAge(event.target.value as string);
    // };


    // function LogOut() {
    //     navigate("/");
    // }

    return (
        <header>
            <div className="header">
                {children}
                {/* <Tooltip title="Log Out">
                    <Button variant="text" onClick={LogOut}>
                        <Icon>power_settings_new</Icon>
                    </Button>
                </Tooltip> */}
                
            </div>
        </header>
    )
}

export default Header;