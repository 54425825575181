import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import logo from "../../assets/logos/PROCare_logo.png";
import "./CreatePassword.css";

function CreatePassword() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [openDialog, setOpenDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [passwordChangeStatus, setPasswordChangeStatus] = useState("");

  // Fetching token from URL query parameter
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const password = watch("password");
  useEffect(() => {
    if (!token) {
      // navigate('/login');
    }
  }, [token, navigate]);

  const onSubmit = (data) => {
    console.log(JSON.stringify(data));
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: "follow",
    };
    const token = queryParams.get("token");
    fetch(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/auth/change-password/${token}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        if (!JSON.parse(result).err) {
          console.log(result);
          setOpenDialog(true);
          setPasswordChangeStatus("success");
        } else {
          console.log("error", JSON.parse(result).err);
          setPasswordChangeStatus("error");
          setErrorDialog(true);
          setErrMsg(JSON.parse(result).err);
        }
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <div className="layout layout-full-height">
      <div className="layout layout-item-center layout-flex-full login-side-bg"></div>
      <div className="layout layout-item-center layout-flex-full ml48 login-form-column">
        <div>
          <img src={logo} width="194px" alt="" className="mb48" />
          <div className="login_form">
            <h1>Create New Password</h1>
            {passwordChangeStatus === "" && (
              <>
                {" "}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="mb16">
                    <TextField
                      label="Password"
                      variant="outlined"
                      type="password"
                      {...register("password", {
                        required: "Password is required",
                        minLength: {
                          value: 8,
                          message:
                            "Password must be at least 8 characters long",
                        },
                      })}
                      error={!!errors.password}
                      helperText={errors.password?.message}
                      fullWidth
                    />
                  </div>
                  <div className="mb16">
                    <TextField
                      label="Confirm Password"
                      variant="outlined"
                      type="password"
                      {...register("confirmPassword", {
                        required: "Confirm password is required",
                        validate: (value, formValues) =>
                          value === formValues.password ||
                          "Passwords do not match",
                      })}
                      error={!!errors.confirmPassword}
                      helperText={errors.confirmPassword?.message}
                      fullWidth
                    />
                  </div>
                  <Button
                    className="round-button"
                    variant="contained"
                    type="submit"
                  >
                    Submit
                  </Button>
                </form>
              </>
            )}
            {passwordChangeStatus === "success" && (
              <>
                <div className="mb16">
                  <span>Password set successfully</span>
                </div>
                <div className="mb16">
                  <span>
                    If you are not automatically redirected in 10 seconds, click <a href="/login">here</a> to login with your password
                  </span>
                </div>
              </>
            )}
            {passwordChangeStatus === "error" && (
              <>
                <div className="mb16">
                  <span>{errMsg}</span>
                </div>
                <div className="mb16">
                  <span>
                    Please try again from login screen. If you are not automatically redirected in 10 seconds, click <NavLink to="/login">here</NavLink> to login with your password
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreatePassword;
