import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Icon } from '@mui/material';
import { Chip } from '@mui/material';
import { useEffect, useState } from 'react';
import Header from "../../Header/Header";

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import userAddSuccessImage from '../../../assets/illustrations/user-add-success.png';
import errorImage from '../../../assets/illustrations/error.png';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

import { useForm, Controller, useFieldArray } from "react-hook-form";
import Copyright from '../../Copyright';



function CreateUser() {

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        clearErrors,
    } = useForm({
        defaultValues: {
            roles: [
                { hospitalName: "", roleUIDs: [] },
            ]
        }
    })
    
    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "roles", // unique name for your Field Array
    });

    const onSubmit = (data) => {
        console.log(JSON.stringify(data));
        data['phone'] = '+' + data['phone'];
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(data),
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/users`, requestOptions)
            .then(response => response.text())
            .then(result => {
                if (!JSON.parse(result).err) {
                    console.log(result)
                    setOpenDialog(true);
                    setUserID(JSON.parse(result).data.uid);
                } else {
                    console.log('error', JSON.parse(result).err)
                    setErrorDialog(true);
                    setErrMsg(JSON.parse(result).err);
                }

            })
            .catch(error => {
                console.log('error', error)
                setErrorDialog(true);
            });

    }
    let navigate = useNavigate();
    const [data, setData] = useState(null)
    const [userFormData, setUserFormData] = useState({
        firstName: '',
        lastName: '',
        dob: dayjs(null),
        phone: '',
        email: '',
        gender: '',
        roles: [{ 'id': 0, 'hospitalUID': '', 'roleUIDs': [] }],
        department: ''
    })
    const [hospitalrolecontrol, setHospitalRoleControl] = useState([{ 'id': 0, 'hospitalUID': '', 'roleUIDs': [] }]);
    const [hrcCounter, sethrcCounter] = useState(1)
    const [hospitalList, setHospitalList] = useState([])
    const [configList, setConfigList] = useState([])
    const [roleList, setRoleList] = useState([])
    const [userID, setUserID] = useState('')

    // API calls for hospitals, config and roles
    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/hospitals`)
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    setHospitalList(data['data']['data']);
                }
            })
    }, [])

    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/configs`)
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    setConfigList(data['data']['data']);
                }
            })
    }, [])

    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/roles`)
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    setRoleList(data['data']['data']);
                }
            })
    }, [])

    const addHospitalRole = () => {
        let newCtrl = { 'id': hrcCounter, 'hospitalUID': '', 'roleUIDs': [] };
        setHospitalRoleControl(hospitalrolecontrol => [...hospitalrolecontrol, newCtrl]);
        sethrcCounter(hrcCounter + 1);
        console.log('hr', hospitalrolecontrol);
    }

    const deleteHospitalRoleControl = (e) => {
        console.log("delete event", e.currentTarget.id);
        setHospitalRoleControl(hospitalrolecontrol => hospitalrolecontrol.filter((data) => data.id != e.currentTarget.id));
    }

    const handleChange = (e) => {
        if (e.target.name != "hospitalName") {
            setUserFormData((userFormData) => ({
                ...userFormData,
                [e.target.name]: e.target.value
            }));
        } else if (e.target.name == "hospitalName") {
            let roles = { 'id': 0, 'hospitalUID': e.target.value, 'roleUIDs': userFormData.roles[0].roleUIDs };
            setUserFormData((userFormData) => ({
                ...userFormData,
                ['roles']: [roles]
            }));
        }
    };

    const handleHospitalRole = (e) => {
        console.log(" event", e);
        let formData = [...hospitalrolecontrol];
        if (e.target.name.indexOf("hospitalName") >= 0) {
            formData[e.target.name.split("-")[1] - 1].hospitalUID = e.target.value;
        } else {
            formData[e.target.name.split("-")[1] - 1].roleUIDs = typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value;
        }

        setHospitalRoleControl(formData);
        console.log(" hospitalrolecontrol", hospitalrolecontrol);
    };

    const setDoB = (e) => {
        console.log("dob", e.format('DD-MM-YYYY'));
        setUserFormData((userFormData) => ({
            ...userFormData,
            ['dob']: e.format('DD-MM-YYYY')
        }));
    };

    // const handleSubmit = () => {
    //     console.log("userdata", userFormData);
    //     console.log("hospitalroledata",hospitalrolecontrol);
    //     let data = userFormData;
    //     data.roles = data.roles.concat(hospitalrolecontrol);
    //     console.log("data",data);
    // let myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    // let requestOptions = {
    //     method: 'POST',
    //     headers: myHeaders,
    //     body: JSON.stringify({ ...data }),
    //     redirect: 'follow'
    // };

    // fetch("/users", requestOptions)
    //     .then(response => response.text())
    //     .then(result => {
    //         if(!JSON.parse(result).err){
    //             console.log(result)
    //             setOpenDialog(true);
    //             setUserID(JSON.parse(result).data.uid);
    //         }else{
    //             console.log('error', JSON.parse(result).err)
    //             setErrorDialog(true);
    //             setErrMsg(JSON.parse(result).err);
    //         }

    //     })
    //     .catch(error => {
    //         console.log('error', error)
    //         setErrorDialog(true);
    //     });
    // };

    function backToUsers() {
        navigate('/users')
    }
    function backToViewUser() {
        navigate(`/users/viewuser/${userID}`)
    }

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };



    function getStyles(name, personName, theme) {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    const theme = useTheme();

    const handleMultiselectChipChange = (event) => {
        const {
            target: { value },
        } = event;
        let roles = { 'id': 0, 'hospitalUID': userFormData.roles[0].hospitalUID, 'roleUIDs': value === 'string' ? value.split(',') : value };
        setUserFormData((userFormData) => ({
            ...userFormData,
            ['roles']: [roles]
        }));
    };

    // Dialog Declarations
    const [openDialog, setOpenDialog] = React.useState(false);
    const [errorDialog, setErrorDialog] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState('');

    // const handleClickOpen = () => {
    //     setOpenDialog(true);
    // };

    const handleClose = () => {
        setOpenDialog(false);
    };
    const closeErrorDialog = () => {
        setErrorDialog(false);
    };
    return (
        <div>
            {/* <Button variant="outlined" onClick={handleClickOpen}>
                Open alert dialog
            </Button> */}
            <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{ sx: { borderRadius: "16px" } }}
            >
                <DialogTitle id="alert-dialog-title">
                </DialogTitle>
                <DialogContent className='pad48'>
                    <DialogContentText id="alert-dialog-description">
                        <div className="layout layout-vertical layout-justify-center layout-item-center">
                            <img src={userAddSuccessImage} alt="" />
                            <p className='dialog-description'>
                                User added successfully!
                            </p>
                            <p>Click “View User” to preview the newly added user</p>
                        </div>

                    </DialogContentText>
                </DialogContent>
                <DialogActions className="dialog-action-bg">
                    <div className='layout layout-item-center layout-justify-center layout-full-width mt16 mb16'>
                        <Button variant="text" onClick={backToUsers}>Close</Button>
                        <Button className="round-button ml24" variant="outlined" onClick={backToViewUser}>View User</Button>
                    </div>

                </DialogActions>
            </Dialog>
            <Dialog
                open={errorDialog}
                onClose={closeErrorDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{ sx: { borderRadius: "16px" } }}
            >
                <DialogTitle id="alert-dialog-title">
                </DialogTitle>
                <DialogContent className='pad48'>
                    <DialogContentText id="alert-dialog-description">
                        <div className="layout layout-vertical layout-justify-center layout-item-center">
                            <img src={errorImage} alt="" height="256px" />
                            <p className='dialog-description' style={{ color: '#B04933' }}>
                                Something Went Wrong!
                            </p>
                            <p style={{ textTransform: 'capitalize', textAlign: 'center' }}>{errMsg}</p>
                        </div>

                    </DialogContentText>
                </DialogContent>
                <DialogActions className="dialog-action-bg">
                    <div className='layout layout-item-center layout-justify-center layout-full-width mt16 mb16'>
                        <Button variant="text" onClick={closeErrorDialog}>Close</Button>
                    </div>

                </DialogActions>
            </Dialog>
            <form onSubmit={handleSubmit(onSubmit)}  >
                <Header>
                    <div className="layout layout-item-center layout-justify-space-between">
                        <h2>Create User</h2>
                        <Stack spacing={2} direction="row">
                            <Button variant="text" onClick={backToUsers}>Cancel</Button>
                            <Button className="round-button" variant="contained" type='submit'>Save User</Button>
                        </Stack>
                    </div>
                </Header>
                <div style={{ padding: '24px' }}>

                    <Grid container>
                        <Grid item xs={12} md={10}>
                            <Card>
                                <CardContent>
                                    <p><b>Personal Details</b></p>
                                    <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb16">
                                                <TextField
                                                    fullWidth
                                                    name="firstName"
                                                    {...register('firstName', { required: true, minLength: 3, maxLength: 30 })}
                                                    label="First Name"
                                                    type="text"
                                                    // value={userFormData.firstName} 
                                                    // onChange={handleChange} 
                                                    aria-label="First Name"
                                                    tabIndex={1}
                                                    autoFocus
                                                />
                                                {/* {errors.firstName && <p role="alert" className="error-message">Please enter First Name</p>} */}
                                                {errors.firstName && errors.firstName.type === "required" && <p role="alert" className="error-message">Please enter First Name</p>}
                                                {errors.firstName && errors.firstName.type === "minLength" && <p role="alert" className="error-message">First Name should be minimum 3 characters long</p>}
                                                {errors.firstName && errors.firstName.type === "maxLength" && <p role="alert" className="error-message">First Name should not be more than 30 characters</p>}
                                                {/* <Controller
                                            name="firstName"
                                            control={control}
                                            rules={{
                                                required: true
                                            }}
                                            render={({ field }) => <TextField label="First Name" fullWidth type="text"  {...field}/>} 
                                        />*/}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb16">
                                                <TextField
                                                    fullWidth
                                                    name="lastName"
                                                    label="Last Name"
                                                    type="text"
                                                    // value={userFormData.lastName} 
                                                    // onChange={handleChange} 
                                                    {...register('lastName', { required: true, minLength: 3, maxLength: 30 })}
                                                    aria-label="Last Name"
                                                    tabIndex={2}
                                                />
                                                {errors.lastName && errors.lastName.type === "required" && <p role="alert" className="error-message">Please enter Last Name</p>}
                                                {errors.lastName && errors.lastName.type === "minLength" && <p role="alert" className="error-message">Last Name should be minimum 3 characters long</p>}
                                                {errors.lastName && errors.lastName.type === "maxLength" && <p role="alert" className="error-message">Last Name should not be more than 30 characters</p>}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={2}></Grid>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb16">
                                                <FormControl fullWidth>
                                                    <InputLabel id="gender">Gender</InputLabel>
                                                    <Select
                                                        name="gender"
                                                        labelId="gender"
                                                        id="gender-select"
                                                        // value={userFormData.gender}
                                                        // onChange={handleChange}
                                                        {...register('gender', { required: true })}
                                                        defaultValue=""
                                                        label="Gender"
                                                        aria-label="Gender"
                                                        onChange={(event) => {
                                                            setValue('gender', event.target.value);
                                                            clearErrors('gender');
                                                        }}
                                                        tabIndex={3}
                                                    >

                                                        {configList['gender']?.map((gender) => (
                                                            <MenuItem value={gender}>{gender}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                {errors.gender && <p role="alert" className="error-message">Please enter Gender</p>}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb16">
                                                {/* <TextField name="dob" label="DOB" type="date" value={userFormData.dob} onChange={handleChange} /> */}
                                                <Controller
                                                    control={control}
                                                    name='dob'
                                                    render={({ field }) => (
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DatePicker
                                                                slotProps={{ textField: { fullWidth: true } }}
                                                                name="dob"
                                                                label="Date of Birth"
                                                                {...register('dob', { required: true })}
                                                                // value={dayjs(userFormData.dob)} 
                                                                // onChange={(newValue) => setDoB(newValue)}
                                                                onChange={(date) => field.onChange(date.format('DD-MM-YYYY'))}
                                                                selected={field.value}
                                                                disableFuture
                                                                format="DD/MM/YYYY"
                                                                aria-label="Date of Birth"
                                                                tabIndex={4}
                                                            />
                                                            {errors.dob && <p role="alert" className="error-message">Please enter Date of Birth</p>}
                                                        </LocalizationProvider>
                                                    )}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={2}></Grid>
                                        <Grid item xs={12} md={5}>
                                            <div className="mb16">
                                                {/* <TextField name="phone" label="Phone" type="text" max="13" value={userFormData.phone} onChange={handleChange} /> */}
                                                <Controller
                                                    control={control}
                                                    name='phone'
                                                    // rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <PhoneInput
                                                            country={'us'}
                                                            // value={userFormData.phone}
                                                            // onChange={(value,country,e)=>handleChange(e)}
                                                            {...register('phone', { required: true })}
                                                            inputProps={{
                                                                name: 'phone',
                                                                style: { width: '100%' }
                                                            }}
                                                            {...field}
                                                            aria-label="Phone"
                                                            tabIndex={5}
                                                        />
                                                    )}
                                                />
                                                {errors.phone && <p role="alert" className="error-message">Please enter Phone Number</p>}
                                            </div>
                                        </Grid>                                        
                                        <Grid item xs={12} md={5}>
                                            <div className="mb16">
                                                <TextField
                                                    fullWidth
                                                    name="email"
                                                    label="Email"
                                                    type="text"
                                                    {...register('email', {
                                                        required: true,
                                                        pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                            message: "Please enter a valid Email Address"
                                                        }
                                                    })}
                                                    aria-label="Email"
                                                    tabIndex={6}
                                                />
                                                {errors.email && <p role="alert" className="error-message">Please enter an Email Address</p>}
                                                {errors.email && <p role="alert" className="error-message">{errors.email.message}</p>}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={2}></Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <p></p>
                    <Grid container>
                        <Grid item xs={12} md={10}>
                            <Card>
                                <CardContent>

                                    <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                        <Grid item xs={12} md={5}>
                                            <p><b>Hospital Details</b></p>
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <p><b>Role Details</b></p>
                                        </Grid>
                                        <Grid item xs={12} md={2}></Grid>
                                    </Grid>
                                    {/* <Grid container spacing={2}>
                                    <Grid container item xs={8} spacing={2}>
                                        <Grid item xs={6}>
                                            <div className="mb16">
                                                <FormControl fullWidth>
                                                    <InputLabel id="hospitals">Hospital Name</InputLabel>
                                                    <Select
                                                        name="hospitalName"
                                                        labelId="hospital"
                                                        id="hospital-select"
                                                        // value={userFormData.roles[0]?.hospitalUID || ''}
                                                        // onChange={handleChange}
                                                        {...register('roles.hospitalName', { required: true })}
                                                        label="Hospital"
                                                    >
                                                        {hospitalList?.map((hospital)=>(
                                                            <MenuItem value={hospital.uid}>{hospital.hospitalName}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </Grid>                                    
                                        <Grid item xs={6}>                                                
                                                <div className="mb16">                                                   
                                                    <Controller
                                                        control={control}
                                                                                name='roles.roleUIDs'
                                                                                defaultValue={[]}
                                                                                render={({ field }) => (
                                                                                    <FormControl style={{'width':'100%'}}>
                                                                                        <InputLabel id="roles">Roles</InputLabel>
                                                                                        <Select
                                                                                        name="roleUIDs"
                                                                                        labelId="role"
                                                                                        id="role-select"
                                                                                        multiple
                                                                                        // value={userFormData.roles[0]?.roleUIDs || []}
                                                                                        // onChange={handleMultiselectChipChange}
                                                                                        {...field}
                                                                                        defaultValue={[]}
                                                                                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                                                                        renderValue={(selected) => (
                                                                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                                            {selected.map((value) => (
                                                                                                <Chip key={value} label={roleList.find((item) => item.uid === value).name} />
                                                                                            ))}
                                                                                            </Box>
                                                                                        )}
                                                                                        MenuProps={MenuProps}
                                                                                        onChange={(event) => {
                                                                                            field.onChange(event);
                                                                                            handleMultiselectChipChange(event);
                                                                                        }}
                                                                                        >
                                                                                        {roleList?.map((role)=>(
                                                                                            <MenuItem
                                                                                            key={role.name}
                                                                                            value={role.uid}
                                                                                            style={getStyles(role.name, userFormData.roles[0].roleUIDs, theme)}
                                                                                            >
                                                                                            {role.name}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                        </Select>
                                                                                    </FormControl>
                                                                                    
                                                                            )}
                                                                            />
                                                                            
                                                                        </div>
                                                                    </Grid>

                                                                    
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Button className="round-button ml24" variant="outlined" onClick={addHospitalRole} startIcon={<AddIcon />}>Add New</Button>
                                                            </Grid>
                                                        </Grid> */}
                                                            {/* <Grid container spacing={2}> */}
                                                            {fields.map((item, index) => (
                                                                <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }} key={item.id}>
                                                                    <Grid item xs={12} md={5}>
                                                                        <div className="mb16">
                                                                            <FormControl fullWidth>
                                                                                <InputLabel id="hospitals">Hospital Name</InputLabel>
                                                                                <Select
                                                                                    name={`hospitalName-${index}`}
                                                                                    labelId="hospital"
                                                                                    id={`hospital-select-${index}`}
                                                                                    {...register(`roles.${index}.hospitalUID`, { required: true })}
                                                                                    defaultValue=""
                                                                                    label="Hospital"
                                                                                    aria-label="Hospital Name"
                                                                                    onChange={(event) => {
                                                                                        setValue('roles?.[index]?.hospitalUID', event.target.value);
                                                                                        clearErrors('roles?.[index]?.hospitalUID');
                                                                                    }}
                                                                                    tabIndex={7}
                                                                                >
                                                                                    {hospitalList?.map((hospital) => (
                                                                                        <MenuItem value={hospital.uid}>{hospital.hospitalName}</MenuItem>
                                                                                    ))}
                                                                                </Select>                                                                                
                                                                            </FormControl>
                                                                            {errors.roles?.[index]?.hospitalUID && <p role="alert" className="error-message">Please select a Hospital</p>}
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={12} md={5}>
                                                                        <div className="mb16">
                                                                            <Controller
                                                                                control={control}
                                                                                name={`roles.${index}.roleUIDs`}
                                                                                defaultValue={[]}
                                                                                render={({ field }) => (
                                                                                    <FormControl style={{ 'width': '100%' }}>
                                                                                        <InputLabel id="roles">Roles</InputLabel>
                                                                                        <Select
                                                                                            name={`roles-${index}`}
                                                                                            labelId="role"
                                                                                            id={`role-select-${index}`}
                                                                                            multiple
                                                                                            {...field}
                                                                                            {...register(`roles.${index}.roleUIDs`, { required: true })}
                                                                                            defaultValue={[]}
                                                                                            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                                                                            renderValue={(selected) => (
                                                                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                                                    {selected.map((value) => (
                                                                                                        <Chip key={value} label={roleList?.find((item) => item.uid === value).name} />
                                                                                                    ))}
                                                                                                </Box>
                                                                                            )}
                                                                                            MenuProps={MenuProps}
                                                                                            lable="Roles"
                                                                                            aria-label="Roles"
                                                                                            // onChange={(event) => {
                                                                                            //     setValue('roles?.[index]?.roleUIDs', event.target.value);
                                                                                            //     clearErrors('roles?.[index]?.roleUIDs');
                                                                                            // }}
                                                                                            tabIndex={8}
                                                                                            // onChange={(event) => {
                                                                                            //     field.onChange(event);
                                                                                            //     clearErrors(`roles.${index}.roleUIDs`);
                                                                                            // }}
                                                                                        >
                                                                                            {roleList?.map((role) => (
                                                                                                <MenuItem
                                                                                                    key={role.name}
                                                                                                    value={role.uid}
                                                                                                    style={getStyles(role.name, userFormData.roles, theme)}
                                                                                                >
                                                                                                    {role.name}
                                                                                                </MenuItem>
                                                                                            ))}
                                                                                        </Select>
                                                                                        {errors.roles?.[index]?.roleUIDs && <p role="alert" className="error-message">Please select at least one role</p>}
                                                                                    </FormControl>
                                                                                )}
                                                                            />
                                                                        </div>
                                                                    </Grid>
                                            {index != 0 ?
                                                <Grid item xs={12} md={2} className='mt8'>
                                                    <IconButton aria-label="delete" id={index} onClick={() => remove(index)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Grid> :
                                                <Grid item xs={12} md={2}>
                                                    <Button
                                                        className="round-button"
                                                        variant="outlined"
                                                        onClick={() => {
                                                            console.log('fields 1', fields);
                                                            append({ hospitalName: '', roleUIDs: [] });
                                                        }}
                                                        startIcon={<AddIcon />}>
                                                        Add New
                                                    </Button>
                                                </Grid>
                                            }
                                        </Grid>
                                    ))}
                                    {/* </Grid> */}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                </div>
            </form >
            <Box component="footer">
                <Copyright sx={{ pb: 3, px: 3 }} />
            </Box>
        </div >
    )
}

export default CreateUser;