import React, { useState } from "react";
import "./ForgotPassword.css";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import logo from "../../assets/logos/PROCare_logo.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import backgroundImage from "../../assets/images/care.jpg";
import StaticOverlay from "../Shared/static-overlay";

const schema = z.object({
	email: z.string().min(1, "Email is required").email("Invalid email format"),
});

function ForgotPassword() {
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [requestError, setRequestError] = useState("");
	const [loading, setLoading] = useState(false);
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: zodResolver(schema),
	});
	const navigate = useNavigate();

	const onSubmit = async (data) => {
		setLoading(true);
		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		let requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify(data),
			redirect: "follow",
		};
		try {
			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/api/v1/auth/reset-password`,
				requestOptions
			);
			const result = await response.json();
			if (!response.ok) {
				setRequestError(result.message);
				setLoading(false);
				return;
			} else {
				setRequestError("");
			}
			setIsSubmitted(true);
			setLoading(false);
		} catch (error) {
			setRequestError("Something went wrong. Please try again later.");
			setLoading(false);
			console.log(error);
		}
	};

	return (
		<Grid container component="main" sx={{ height: '100vh' }}>
			<StaticOverlay />
			<Grid item xs={12} sm={6} md={6} elevation={6} square>
				<Box
					sx={{
						mx: 4,
						my: 8,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Grid container>
						<Grid item xs={12} md={1}></Grid>
						<Grid item xs={12} md={10}>
							<Card>
								<CardContent>
									<img src={logo} width="194px" alt="Logo" className="mb48" />
									<div>
										<div className="login_form">
											{!isSubmitted ? (
												<>
													<h1>Forgot your password?</h1>
													<form onSubmit={handleSubmit(onSubmit)}>
														<Box sx={{ mt: 3 }}>
															<Grid container spacing={2}>
																<Grid item xs={12}>
																	<TextField
																		label="Email"
																		variant="outlined"
																		fullWidth
																		autoComplete="email"
																		autoFocus
																		{...register("email")}
																		error={!!errors.email}
																		helperText={errors.email?.message}
																	/>
																</Grid>
															</Grid>
															<Button
																className="round-button"
																variant="contained"
																type="submit"
																sx={{ mt: 3, mb: 2 }}
																disabled={loading}
															>
																{loading ? <CircularProgress size={24} /> : 'Submit'}
															</Button>
														</Box>
													</form>
												</>
											) : (
												<>
													<h1>Reset your password</h1>
													<div className="mb16">
														<span>
															If we've found your account on PROCare, we've sent you an
															email with instructions on how to reset your password.
															Once you've completed the reset process, you can return <NavLink to="/login">here</NavLink> and login with your new password.
														</span>
													</div>
												</>
											)}
											{requestError && <div className="error">{requestError}</div>}
										</div>
									</div>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
				</Box>
			</Grid>
		</Grid>
	);
}

export default ForgotPassword;
