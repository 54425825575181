import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Icon } from '@mui/material';
import { Chip } from '@mui/material';
import { useEffect, useState, useMemo } from 'react';
import Header from '../../Header/Header';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import Grid from '@mui/material/Grid';
import { useNavigate, useParams } from "react-router-dom";

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import userAddSuccessImage from '../../../assets/illustrations/user-add-success.png';
import errorImage from '../../../assets/illustrations/error.png';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

import { useForm, Controller, useFieldArray } from "react-hook-form"

import CircularProgress from '@mui/material/CircularProgress';
import Copyright from '../../Copyright';

function EditUser() {

    let { userId } = useParams();
    let navigate = useNavigate();

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        clearErrors,
    } = useForm({
        defaultValues: async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/users/` + userId);
            const data = await response.json();
            // console.log(data);
            return data['data'];
            // roles: [
            //     { hospitalName: "", roleUIDs: [] },
            // ]
        }
    })

    const [userFormData, setUserFormData] = useState({
        firstName: '',
        lastName: '',
        dob: dayjs(null),
        phone: '',
        email: '',
        gender: '',
        roles: [{ 'id': 0, 'hospitalUID': '', 'roleUIDs': [] }],
        hospitalName: ''
    })

    const [hospitalrolecontrol, setHospitalRoleControl] = useState([]);
    const [hrcCounter, sethrcCounter] = useState(1)
    const [hospitalList, setHospitalList] = useState([])
    const [configList, setConfigList] = useState([])
    const [roleList, setRoleList] = useState([])
    const [userID, setUserID] = useState('')
    const [isLoading, setisLoading] = React.useState(false);

    // const [open, setOpen] = React.useState(false);

    // const handleChange = (e) => {
    //     setUserFormData((userFormData) => ({
    //         ...userFormData,
    //         [e.target.name]: e.target.value
    //     }));
    // };

    // const handleClose = (event, reason) => {
    //     if (reason === 'clickaway') {
    //         return;
    //     }
    //     setOpen(false);
    // };

    // const action = (
    //     <React.Fragment>
    //         <IconButton
    //             size="small"
    //             aria-label="close"
    //             color="inherit"
    //             onClick={handleClose}
    //         >
    //             <CloseIcon fontSize="small" />
    //         </IconButton>
    //     </React.Fragment>
    // );

    // const [message, setMessage] = useState('');
    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/hospitals`)
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    setHospitalList(data['data']['data']);
                }
            })
    }, [])

    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/configs`)
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    setConfigList(data['data']['data']);
                }
            })
    }, [])

    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/roles`)
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    setRoleList(data['data']['data']);
                }
            })
    }, [])

    const addHospitalRole = () => {
        let newCtrl = { 'id': hrcCounter, 'hospitalUID': '', 'roleUIDs': [] };
        setHospitalRoleControl(hospitalrolecontrol => [...hospitalrolecontrol, newCtrl]);
        sethrcCounter(hrcCounter + 1);
        console.log('hr', hospitalrolecontrol);
    }

    const deleteHospitalRoleControl = (e) => {
        console.log("delete event", e.currentTarget.id);
        setHospitalRoleControl(hospitalrolecontrol => hospitalrolecontrol.filter((data) => data.id != e.currentTarget.id));
    }

    const handleChange = (e) => {
        if (e.target.name != "hospitalName") {
            setUserFormData((userFormData) => ({
                ...userFormData,
                [e.target.name]: e.target.value
            }));
        } else if (e.target.name == "hospitalName") {
            let roles = { 'id': 0, 'hospitalUID': e.target.value, 'roleUIDs': userFormData.roles[0].roleUIDs };
            setUserFormData((userFormData) => ({
                ...userFormData,
                ['roles']: [roles]
            }));
        }
    };

    const handleHospitalRole = (e) => {
        console.log(" event", e);
        let formData = [...hospitalrolecontrol];
        if (e.target.name.indexOf("hospitalName") >= 0) {
            formData[e.target.name.split("-")[1] - 1].hospitalUID = e.target.value;
        } else {
            formData[e.target.name.split("-")[1] - 1].roleUIDs = typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value;
        }

        setHospitalRoleControl(formData);
        console.log(" hospitalrolecontrol", hospitalrolecontrol);
    };

    const setDoB = (e) => {
        console.log("dob", e.format('DD-MM-YYYY'));
        setUserFormData((userFormData) => ({
            ...userFormData,
            ['dob']: e.format('DD-MM-YYYY')
        }));
    };

    // const handleSubmit = () => {
    //     console.log("userdata", userFormData);
    //     console.log("hospitalroledata",hospitalrolecontrol);
    //     let data = userFormData;
    //     data.roles = data.roles.concat(hospitalrolecontrol);
    //     console.log("data",data);
    // let myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    // let requestOptions = {
    //     method: 'PUT',
    //     headers: myHeaders,
    //     body: JSON.stringify({ ...data }),
    //     redirect: 'follow'
    // };

    // fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/users/` + userId, requestOptions)
    //     .then(response => response.text())
    //     .then(result => {
    //         if(!JSON.parse(result).err){
    //             console.log(result)
    //             setOpenDialog(true);
    //             setUserID(JSON.parse(result).data.uid);
    //         }else{
    //             console.log('error', JSON.parse(result).err)
    //             setErrorDialog(true);
    //             setErrMsg(JSON.parse(result).err);
    //         }

    //     })
    //     .catch(error => {
    //         console.log('error', error)
    //         setErrorDialog(true);
    //     });
    // };

    function backToUsers() {
        navigate('/users')
    }
    function backToViewUser() {
        navigate(`/users/viewuser/${userId}`)
    }

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };



    function getStyles(name, personName, theme) {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    const theme = useTheme();

    const handleMultiselectChipChange = (event) => {
        const {
            target: { value },
        } = event;
        let roles = { 'id': 0, 'hospitalUID': userFormData.roles[0].hospitalUID, 'roleUIDs': value === 'string' ? value.split(',') : value };
        setUserFormData((userFormData) => ({
            ...userFormData,
            ['roles']: [roles]
        }));
    };

    // Dialog Declarations
    const [openDialog, setOpenDialog] = React.useState(false);
    const [errorDialog, setErrorDialog] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState('');

    // const handleClickOpen = () => {
    //     setOpenDialog(true);
    // };

    const handleClose = () => {
        setOpenDialog(false);
    };
    const closeErrorDialog = () => {
        setErrorDialog(false);
    };


    const [userdata, setData] = useState(null)
    useEffect(() => {
        setisLoading(true);
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/users/` + userId)
            .then((res) => res.json())
            .then((data) => {
                // console.log("type", typeof (data['data']['roles']))
                setData(data['data']);
                setisLoading(false);
                console.log("User Data", data);
            })
    }, [])

    // const { register, handleSubmit, control } = useForm({
    //     defaultValues: async () => {
    //         const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/users/` + userId);
    //         const data = await response.json();
    //         // console.log(data);
    //         return data['data'];
    //     },
    // })

    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "roles", // unique name for your Field Array
    });

    const onSubmit = (data) => {
        console.log(JSON.stringify(data));
        data['phone'] = data['phone'].indexOf('+') > -1 ? data['phone'] : '+' + data['phone'];
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: JSON.stringify({ ...data }),
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/users/` + userId, requestOptions)
            .then(response => response.text())
            .then(result => {
                if (!JSON.parse(result).err) {
                    console.log(result)
                    setOpenDialog(true);
                    setUserID(JSON.parse(result).data.uid);
                } else {
                    console.log('error', JSON.parse(result).err)
                    setErrorDialog(true);
                    setErrMsg(JSON.parse(result).err);
                }

            })
            .catch(error => {
                console.log('error', error)
                setErrorDialog(true);
            });

    }

    function getRoleName(role) {
        if (roleList.length > 0) {
            return roleList.find((item) => item.uid === role).name
        }
    }

    return (
        <div>
            {/* <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message={message}
                action={action}
            /> */}
            <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{ sx: { borderRadius: "16px" } }}
            >
                <DialogTitle id="alert-dialog-title">
                </DialogTitle>
                <DialogContent className='pad48'>
                    <DialogContentText id="alert-dialog-description">
                        <div className="layout layout-vertical layout-justify-center layout-item-center">
                            <img src={userAddSuccessImage} alt="" />
                            <p className='dialog-description'>
                                User Updated successfully!
                            </p>
                            <p>Click “View User” to preview the newly added user</p>
                        </div>

                    </DialogContentText>
                </DialogContent>
                <DialogActions className="dialog-action-bg">
                    <div className='layout layout-item-center layout-justify-center layout-full-width mt16 mb16'>
                        <Button variant="text" onClick={backToUsers}>Close</Button>
                        <Button className="round-button ml24" variant="outlined" onClick={backToViewUser}>View User</Button>
                    </div>

                </DialogActions>
            </Dialog>
            <Dialog
                open={errorDialog}
                onClose={closeErrorDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{ sx: { borderRadius: "16px" } }}
            >
                <DialogTitle id="alert-dialog-title">
                </DialogTitle>
                <DialogContent className='pad48'>
                    <DialogContentText id="alert-dialog-description">
                        <div className="layout layout-vertical layout-justify-center layout-item-center">
                            <img src={errorImage} alt="" height="256px" />
                            <p className='dialog-description' style={{ color: '#B04933' }}>
                                Something Went Wrong!
                            </p>
                            <p style={{ textTransform: 'capitalize', textAlign: 'center' }}>{errMsg}</p>
                        </div>

                    </DialogContentText>
                </DialogContent>
                <DialogActions className='dialog-action-bg'>
                    <div className='layout layout-item-center layout-justify-center layout-full-width mt16 mb16'>
                        <Button variant="text" onClick={closeErrorDialog}>Close</Button>
                    </div>

                </DialogActions>
            </Dialog>
            <form onSubmit={handleSubmit(onSubmit)}  >
                <Header>
                    <div className='layout layout-item-center layout-justify-space-between'>
                        <h2>Edit User - {userdata?.firstName} {userdata?.lastName} - {userdata?.pid}</h2>
                        <Stack spacing={2} direction="row">
                            <Button variant="text" onClick={backToViewUser}>Cancel</Button>
                            <Button className="round-button" variant="contained" type='submit'>Save</Button>
                        </Stack>
                    </div>
                </Header>

                {isLoading == true ?
                    <div className='layout layout-item-center layout-justify-center loadingProgress'>
                        <CircularProgress />
                    </div>
                    :
                    <div style={{ padding: '24px' }}>
                        {/* <Box
                        id="userForm"
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { width: '100%' },
                        }}
                        noValidate
                        autoComplete="off"
                        > */}

                        <Grid container>
                            <Grid item xs={12} md={10}>
                                <Card>
                                    <CardContent>
                                        <p><b>Personal Details</b></p>
                                        <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                            <Grid item xs={12} md={5}>
                                                <div className="mb16">
                                                    <TextField
                                                        fullWidth
                                                        name="firstName"
                                                        label="First Name"
                                                        type="text"
                                                        // value={userFormData.firstName} 
                                                        // onChange={handleChange} 
                                                        {...register('firstName', { required: true })}
                                                        InputLabelProps={{ shrink: true }}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={5}>
                                                <div className="mb16">
                                                    <TextField
                                                        fullWidth
                                                        name="lastName"
                                                        label="Last Name"
                                                        type="text"
                                                        // value={userFormData.lastName} 
                                                        // onChange={handleChange} 
                                                        {...register('lastName', { required: true })}
                                                        InputLabelProps={{ shrink: true }}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={2}></Grid>
                                            <Grid item xs={12} md={5}>
                                                <div className="mb16">
                                                    <Controller
                                                        control={control}
                                                        name='gender'
                                                        rules={{ required: true }}
                                                        defaultValue={''}
                                                        render={({ field }) => (

                                                            <FormControl fullWidth>
                                                                <InputLabel id="gender">Gender</InputLabel>
                                                                <Select
                                                                    name="gender"
                                                                    labelId="gender"
                                                                    id="gender-select"
                                                                    // value={userFormData.gender}
                                                                    // onChange={handleChange}
                                                                    {...field}
                                                                    label="Gender"
                                                                >

                                                                    {configList['gender']?.map((gender) => (
                                                                        <MenuItem value={gender}>{gender}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        )}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={5}>
                                                <div className="mb16">
                                                    {/* <TextField name="dob" label="DOB" type="date" value={userFormData.dob} onChange={handleChange} /> */}
                                                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker name="dob" label="Date of Birth" value={dayjs(userFormData.dob)} onChange={(newValue) => setDoB(newValue)} format="DD/MM/YYYY"  />
                                            </LocalizationProvider> */}
                                                    <Controller
                                                        control={control}
                                                        name='dob'
                                                        render={({ field }) => (
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DatePicker
                                                                    slotProps={{ textField: { fullWidth: true } }}
                                                                    name="dob"
                                                                    label="Date of Birth"
                                                                    // value={dayjs(userFormData.dob)} 
                                                                    // onChange={(newValue) => setDoB(newValue)}
                                                                    // {...register('dob', { required: true })}
                                                                    onChange={(date) => field.onChange(date.format('DD-MM-YYYY'))}
                                                                    value={dayjs(field.value)}
                                                                    disableFuture
                                                                    format="DD/MM/YYYY" />
                                                            </LocalizationProvider>
                                                        )}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={2}></Grid>
                                            <Grid item xs={12} md={5}>
                                                <div className="mb16">
                                                    {/* <TextField name="phone" label="Phone" type="text" max="13" value={userFormData.phone} onChange={handleChange} /> */}
                                                    <Controller
                                                        control={control}
                                                        name='phone'
                                                        rules={{ required: true }}
                                                        render={({ field }) => (
                                                            <PhoneInput
                                                                country={'us'}
                                                                // value={userFormData.phone}
                                                                // onChange={(value,country,e)=>handleChange(e)}
                                                                // {...register('phone', { required: true })}
                                                                inputProps={{
                                                                    name: 'phone',
                                                                    style: { width: '100%' }
                                                                }}
                                                                {...field}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={5}>
                                                <div className="mb16">
                                                    <TextField
                                                        fullWidth
                                                        name="email"
                                                        label="Email"
                                                        type="text"
                                                        {...register('email', { required: true })}
                                                        InputLabelProps={{ shrink: true }}
                                                    // value={userFormData.email} 
                                                    // onChange={handleChange} 
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={2}></Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <p></p>
                        <Grid container>
                            <Grid item xs={12} md={10}>
                                <Card>
                                    <CardContent>
                                        <Grid container rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                            <Grid item xs={5}>
                                                <p><b>Hospital Details</b></p>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <p><b>Role Details</b></p>
                                            </Grid>
                                        </Grid>
                                        {/* <Grid container spacing={2}>
                                    <Grid container item xs={8} spacing={2}>
                                        <Grid item xs={6}>
                                            <div className="mb16">
                                                <FormControl fullWidth>
                                                    <InputLabel id="hospitals">Hospital Name</InputLabel>
                                                    <Select
                                                        name="hospitalName"
                                                        labelId="hospital"
                                                        id="hospital-select"
                                                        value={userFormData.roles[0]?.hospitalUID || ''}
                                                        onChange={handleChange}
                                                        label="Hospital"
                                                    >
                                                        {hospitalList?.map((hospital)=>(
                                                            <MenuItem value={hospital.uid}>{hospital.hospitalName}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </Grid>

                                        
                                        <Grid item xs={6}>
                                                
                                                <div className="mb16">
                                                    
                                                    <FormControl style={{'width':'100%'}}>
                                                        <InputLabel id="roles">Roles</InputLabel>
                                                        <Select
                                                        name="roles"
                                                        labelId="role"
                                                        id="role-select"
                                                        multiple
                                                        value={userFormData.roles[0]?.roleUIDs || []}
                                                        onChange={handleMultiselectChipChange}
                                                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                                        renderValue={(selected) => (
                                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                            {selected.map((value) => (
                                                                <Chip key={value} label={roleList?.find((item) => item.uid === value)?.name || ''} />
                                                            ))}
                                                            </Box>
                                                        )}
                                                        MenuProps={MenuProps}
                                                        >
                                                        {roleList?.map((role)=>(
                                                            <MenuItem
                                                            key={role.name}
                                                            value={role.uid}
                                                            
                                                            >
                                                            {role.name}
                                                            </MenuItem>
                                                        ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </Grid>

                                            
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button className="round-button ml24" variant="outlined" onClick={addHospitalRole} startIcon={<AddIcon />}>Add New</Button>
                                    </Grid>
                                </Grid> */}
                                        {/* <Grid container spacing={2}> */}
                                        {fields.map((item, index) => (
                                            <Grid container item rowSpacing={{ xs: 1, sm: 1, md: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 2 }} key={item.id}>
                                                <Grid item xs={12} md={5}>
                                                    <div className="mb16">
                                                        <Controller
                                                            control={control}
                                                            name={`roles.${index}.hospitalUID`}
                                                            rules={{ required: true }}
                                                            defaultValue={''}
                                                            render={({ field }) => (

                                                                <FormControl fullWidth>
                                                                    <InputLabel id="hospitals">Hospital Name</InputLabel>
                                                                    <Select
                                                                        name={`roles.${index}.hospitalUID`}
                                                                        labelId="hospital"
                                                                        id={`hospital-select-${index}`}
                                                                        // value={hospitalrolecontrol[hospitalrole.id - 1]?.hospitalUID || ''}
                                                                        // onChange={handleHospitalRole}
                                                                        {...field}
                                                                        label="Hospital"
                                                                    >
                                                                        {hospitalList?.map((hospital) => (
                                                                            <MenuItem value={hospital.uid}>{hospital.hospitalName}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            )}
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={5}>
                                                    <div className="mb16">
                                                        {/* <FormControl fullWidth>
                                                            <InputLabel id="roles">Role</InputLabel>
                                                            <Select
                                                                name="roles"
                                                                labelId="role"
                                                                id="role-select"
                                                                value={userFormData.roles}
                                                                onChange={handleChange}
                                                                label="Role"
                                                            >

                                                                <MenuItem value={'Super Admin'}>Super Admin</MenuItem>
                                                                <MenuItem value={'Admin'}>Admin</MenuItem>
                                                                <MenuItem value={'Medical Personnel'}>Medical Personnel</MenuItem>
                                                                <MenuItem value={'Researcher'}>Researcher</MenuItem>
                                                            </Select>
                                                        </FormControl> */}
                                                        <Controller
                                                            control={control}
                                                            name={`roles.${index}.roleUIDs`}
                                                            defaultValue={[]}
                                                            render={({ field }) => (
                                                                <FormControl style={{ 'width': '100%' }}>
                                                                    <InputLabel id="roles">Roles</InputLabel>
                                                                    <Select
                                                                        name={`roles-${index}`}
                                                                        labelId="role"
                                                                        id={`role-select-${index}`}
                                                                        multiple
                                                                        // value={hospitalrolecontrol[hospitalrole.id - 1]?.roleUIDs || []}
                                                                        // onChange={handleHospitalRole}
                                                                        {...field}
                                                                        defaultValue={[]}
                                                                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                                                        renderValue={(selected) => (
                                                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                                {selected.map((value) => (
                                                                                    <Chip key={value} label={getRoleName(value)} />
                                                                                ))}
                                                                            </Box>
                                                                        )}
                                                                        MenuProps={MenuProps}
                                                                    >
                                                                        {roleList?.map((role) => (
                                                                            <MenuItem
                                                                                key={role.name}
                                                                                value={role.uid}
                                                                                style={getStyles(role.name, userFormData.roles, theme)}
                                                                            >
                                                                                {role.name}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>

                                                            )}
                                                        />
                                                    </div>
                                                </Grid>
                                                {index != 0 ?
                                                    <Grid item xs={12} md={2} className='mt8'>
                                                        <IconButton aria-label="delete" id={index} onClick={() => remove(index)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Grid> :
                                                    <Grid item xs={12} md={2}>
                                                        <Button
                                                            className="round-button"
                                                            variant="outlined"
                                                            onClick={() => {
                                                                console.log('fields 1', fields);
                                                                append({ hospitalName: '', roleUIDs: [] });
                                                            }}
                                                            startIcon={<AddIcon />}>
                                                            Add New
                                                        </Button>
                                                    </Grid>
                                                }
                                            </Grid>
                                        ))}
                                        {/* </Grid> */}
                                    </CardContent>
                                </Card>
                                {/* </Box> */}
                            </Grid>
                        </Grid>

                    </div>
                }
            </form>
            <Box component="footer">
                <Copyright sx={{ pb: 3, px: 3 }} />
            </Box>
        </div>
    )
}

export default EditUser;