import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CircularProgress, Icon } from "@mui/material";
import { Chip } from "@mui/material";
import { useEffect, useState } from "react";
import Header from "../../Header/Header";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import IconButton from "@mui/material/IconButton";
import { NavLink } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";

import patientAddSuccessImage from "../../../assets/illustrations/patient-add-success.png";
import errorImage from "../../../assets/illustrations/error.png";

import { useForm, Controller, useFieldArray } from "react-hook-form";
import Copyright from "../../Copyright";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  // [`&.${tableCellClasses.head}`]: {
  //   backgroundColor: theme.palette.common.black,
  //   color: theme.palette.common.white,
  // },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // '&:nth-of-type(odd)': {
  //   backgroundColor: theme.palette.action.hover,
  // },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function CreateStudy() {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();
  const hospital = watch("hospitalUID");
  const [hospitalAddress, setHospitalAddress] = useState({});

  const onSubmit = (data) => {
    console.log(JSON.stringify(data));
    setisLoading(true);
    let myHeaders = new Headers();
    let selectedpatientsid = [];
    let selectedusersid = [];
    if (selectedPatientsData.length > 0) {
      selectedPatientsData.map((selectedpatient) => {
        selectedpatientsid.push(selectedpatient.uid);
      });
    }

    if (selectedUsersData.length > 0) {
      selectedUsersData.map((selecteduser) => {
        selectedusersid.push(selecteduser.uid);
      });
    }

    console.log("selectedids", selectedpatientsid);
    console.log("selectedids", selectedusersid);
    let studydata = {
      ...data,
      ["patients"]: selectedpatientsid,
      ["users"]: selectedusersid,
    };
    myHeaders.append("Content-Type", "application/json");
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(studydata),
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/studies`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        setisLoading(false);
        if (!JSON.parse(result).err) {
          console.log(result);
          setSuccessDialog(true);
        } else {
          console.log("error", JSON.parse(result).err);
          setErrorDialog(true);
          setErrMsg(JSON.parse(result).err);
        }
      })
      .catch((error) => {
        isLoading(false);
        console.log("error", error);
      });
    console.log("Request body", studydata);
  };
  useEffect(() => {
    if (hospital) {
      fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/hospitals/` + hospital)
        .then((res) => res.json())
        .then((data) => {
          if (data) {
            let address = data["data"]["address"];

            setHospitalAddress(address);
          }
        })
        .catch((error) => {
          console.error("Error fetching hospital address:", error);
        });
    }
  }, [hospital]);

  let navigate = useNavigate();
  const [data, setData] = useState({});
  const [usersData, setUsersData] = useState({});
  const [selectedUsersData, setSelectedUsersData] = useState([]);
  const [patientsData, setPatientsData] = useState({});
  const [selectedPatientsData, setselectedPatientsData] = useState({});
  const [studyData, setStudyData] = useState({});
  const [searchData, setSearchData] = useState({});
  const [hospitalList, setHospitalList] = useState([]);
  const principalInvestigatorWatch = watch("principalInvestigatorUID");
  const [isLoading, setisLoading] = React.useState(false);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/hospitals`)
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setHospitalList(data["data"]["data"]);
        }
      });
  }, []);

  const [surveyList, setsurveyList] = useState([]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/surveys?status=all`)
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setsurveyList(data["data"]["data"]);
        }
      });
  }, []);

  const [configList, setConfigList] = useState([]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/configs`)
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setConfigList(data["data"]["data"]);
        }
      });
  }, []);

  useEffect(() => {
    fetchUsersData();
  }, []);

  const handleChange = (e) => {
    setStudyData((studyData) => ({
      ...studyData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSearch = (e) => {
    searchData((searchData) => ({
      ...searchData,
      [e.target.name]: e.target.value,
    }));
  };

  // const handleSubmit = () => {
  //     console.log("data", studyData);
  //     let myHeaders = new Headers();
  //     let selectedpatientsid = [];
  //     let selectedusersid = [];
  //     selectedPatientsData.map(selectedpatient=>{
  //         selectedpatientsid.push(selectedpatient.uid);
  //     });

  //     selectedUsersData.map(selecteduser=>{
  //         selectedusersid.push(selecteduser.uid);
  //     });
  //     console.log("selectedids",selectedpatientsid);
  //     console.log("selectedids",selectedusersid);
  //     let studydata = {
  //         ...studyData,
  //         ['patients']: selectedpatientsid,
  //         ['users']: selectedusersid
  //     }
  //     myHeaders.append("Content-Type", "application/json");
  //     let requestOptions = {
  //         method: 'POST',
  //         headers: myHeaders,
  //         body: JSON.stringify(studydata),
  //         redirect: 'follow'
  //     };

  //     fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/studies", requestOptions)
  //         .then(response => response.text())
  //         .then(result => {
  //             console.log(result)
  //             navigate('/studies')
  //         })
  //         .catch(error => console.log('error', error));
  //     console.log("Reuest body", studydata);

  // };

  function backToStudies() {
    navigate("/studies");
  }

  function fetchPatientsData() {
    fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/patients?status=Active`)
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setPatientsData(data["data"]);
          console.log("User data", data["data"]["data"]);
        }
      });
  }

  function fetchUsersData() {
    fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/users?status=Active`)
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setUsersData(data["data"]);
          console.log("User data", data["data"]["data"]);
        }
      });
  }

  const [tabValue, setTabValue] = React.useState("1");

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Dialog Declarations
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openUsersDialog, setOpenUsersDialog] = React.useState(false);
  const [successDialog, setSuccessDialog] = React.useState(false);
  const [errorDialog, setErrorDialog] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState("");

  // const handleClickOpen = () => {
  //     setOpenDialog(true);
  // };

  const closeSuccessDialog = () => {
    setSuccessDialog(false);
  };
  const closeErrorDialog = () => {
    setErrorDialog(false);
  };

  const OpenPatientDialog = () => {
    setOpenDialog(true);
    fetchPatientsData();
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const OpenUsersDialog = () => {
    setOpenUsersDialog(true);
    fetchUsersData();
  };

  const closeUsersDialog = () => {
    setOpenUsersDialog(false);
  };

  const [selectedPatients, setSelectedPatients] = React.useState([]);
  const [selectedUsers, setSelectedUsers] = React.useState([]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = patientsData["data"].map((n) => n.id);
      setSelectedPatients(newSelected);
      return;
    }
    setSelectedPatients([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selectedPatients.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedPatients, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedPatients.slice(1));
    } else if (selectedIndex === selectedPatients.length - 1) {
      newSelected = newSelected.concat(selectedPatients.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedPatients.slice(0, selectedIndex),
        selectedPatients.slice(selectedIndex + 1)
      );
    }
    setSelectedPatients(newSelected);
  };

  const isSelected = (id) => selectedPatients.indexOf(id) !== -1;

  function savePatientSelection() {
    let selectedpatients = [];
    selectedPatients.map((id) => {
      selectedpatients.push(
        patientsData["data"].filter((patient) => patient.id == id)[0]
      );
      // console.log(selectedpatients);
    });
    setselectedPatientsData(selectedpatients);
    setOpenDialog(false);
  }

  //Users Table
  const SelectAllUsers = (event) => {
    if (event.target.checked) {
      const newSelected = usersData["data"].map((n) => n.id);
      setSelectedUsers(newSelected);
      return;
    }
    setSelectedUsers([]);
  };

  const selectUser = (event, id) => {
    const selectedIndex = selectedUsers.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedUsers, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelected = newSelected.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      );
    }
    setSelectedUsers(newSelected);
  };

  const isSelectedUsers = (id) => selectedUsers.indexOf(id) !== -1;

  function saveUserSelection() {
    let selectedusers = [];
    selectedUsers.map((id) => {
      selectedusers.push(usersData["data"].filter((user) => user.uid == id)[0]);
      // console.log(selectedpatients);
    });
    setSelectedUsersData(selectedusers);

    setOpenUsersDialog(false);
  }
  useEffect(() => {
    if (principalInvestigatorWatch) {
      selectUser(null, principalInvestigatorWatch);
      let previousSelectedUsersdata = selectedUsersData;
      const principalUserIsExistingUser = previousSelectedUsersdata.some(
        (user) => user.uid === principalInvestigatorWatch
      );
      if (!principalUserIsExistingUser) {
        let principalUser = usersData["data"].filter(
          (user) => user.uid === principalInvestigatorWatch
        )[0];
        previousSelectedUsersdata.push(principalUser);
        setSelectedUsersData(previousSelectedUsersdata);
      }
    }
  }, [principalInvestigatorWatch]);

  return (
    <div>
      <Dialog
        open={successDialog}
        onClose={closeSuccessDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { borderRadius: "16px" } }}
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent className="pad48">
          <DialogContentText id="alert-dialog-description">
            <div className="layout layout-vertical layout-justify-center layout-item-center">
              <img src={patientAddSuccessImage} alt="" />
              <p className="dialog-description">Study added successfully!</p>
              <p></p>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-bg">
          <div className="layout layout-item-center layout-justify-center layout-full-width mt16 mb16">
            <Button variant="text" onClick={backToStudies}>
              Close
            </Button>
            {/* <Button className="round-button ml24" variant="outlined" onClick={backToViewUser}>View User</Button> */}
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={errorDialog}
        onClose={closeErrorDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { borderRadius: "16px" } }}
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent className="pad48">
          <DialogContentText id="alert-dialog-description">
            <div className="layout layout-vertical layout-justify-center layout-item-center">
              <img src={errorImage} alt="" height="256px" />
              <p className="dialog-description" style={{ color: "#B04933" }}>
                Something Went Wrong!
              </p>
              <p style={{ textTransform: "capitalize", textAlign: "center" }}>
                {errMsg}
              </p>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-bg">
          <div className="layout layout-item-center layout-justify-center layout-full-width mt16 mb16">
            <Button variant="text" onClick={closeErrorDialog}>
              Close
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { borderRadius: "16px" } }}
        maxWidth="xl"
      >
        <DialogTitle id="alert-dialog-title">Select Patient(s)</DialogTitle>
        <DialogContent className="pad48">
          <DialogContentText id="alert-dialog-description">
            <Box
              id="userForm"
              component="form"
              sx={{
                "& .MuiTextField-root": { width: "100%" },
              }}
              noValidate
              autoComplete="off"
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div className="mb16">
                    <TextField
                      name="firstName"
                      label="First Name"
                      type="text"
                      value={""}
                      onChange={handleSearch}
                    />
                  </div>

                  <div className="mb16">
                    <TextField
                      name="patientid"
                      label="Patient Id"
                      type="text"
                      value={""}
                      onChange={handleSearch}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="mb16">
                    <TextField
                      name="lastName"
                      label="Last Name"
                      type="text"
                      value={""}
                      onChange={handleSearch}
                    />
                  </div>

                  <div className="mb16">
                    <FormControl fullWidth>
                      <InputLabel id="hospitals">Hospital</InputLabel>
                      <Select
                        name="hospitals"
                        labelId="hospital"
                        id="hospital-select"
                        value={studyData.hospital}
                        onChange={handleSearch}
                        label="Hospital"
                      >
                        {hospitalList?.map((hospital) => (
                          <MenuItem value={hospital.uid}>
                            {hospital.hospitalName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div className="layout layout-item-center layout-justify-end">
                    <Button variant="text" onClick={handleClose}>
                      Reset
                    </Button>
                    <Button
                      className="round-button ml24"
                      variant="outlined"
                      onClick={handleClose}
                    >
                      Search
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Box>
            <TableContainer>
              <Table
                sx={{ minWidth: 700 }}
                aria-label="customized table"
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        // indeterminate={selectedPatients > 0 && selectedPatients < patientsData['data']?.length}
                        // checked={patientsData['data']?.length > 0 && selectedPatients === patientsData['data']?.length}
                        onChange={handleSelectAllClick}
                      />
                    </StyledTableCell>
                    <StyledTableCell>Patient ID</StyledTableCell>
                    <StyledTableCell>First Name</StyledTableCell>
                    <StyledTableCell>Last Name</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {patientsData["data"]?.length > 0 ? (
                    patientsData["data"]?.map((row) => (
                      <StyledTableRow
                        key={row.id}
                        hover
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isSelected(row.id)}
                        tabIndex={-1}
                        selected={isSelected(row.id)}
                        sx={{ cursor: "pointer" }}
                      >
                        <StyledTableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isSelected(row.id)}
                          />
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {/* <NavLink to={`/viewuser/${row.uid}`} exact activeClassName="active"> */}
                          {row.pid}
                          {/* </NavLink> */}
                        </StyledTableCell>
                        <StyledTableCell>{row.firstName}</StyledTableCell>
                        <StyledTableCell>{row.lastName}</StyledTableCell>

                        <StyledTableCell>
                          <Chip label={row.status} variant="outlined" />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell colSpan={8} align="center">
                        No Records Available
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-bg">
          <div className="layout layout-item-center layout-justify-end layout-full-width mt16 mb16 mr32">
            <Button variant="text" disabled={isLoading} onClick={handleClose}>
              Close
            </Button>
            <Button
              className="round-button ml24"
              variant="outlined"
              onClick={savePatientSelection}
            >
              Save Selection
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openUsersDialog}
        onClose={closeUsersDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { borderRadius: "16px" } }}
        maxWidth="xl"
      >
        <DialogTitle id="alert-dialog-title">Select User(s)</DialogTitle>
        <DialogContent className="pad48">
          <DialogContentText id="alert-dialog-description">
            <Box
              id="userForm"
              component="form"
              sx={{
                "& .MuiTextField-root": { width: "100%" },
              }}
              noValidate
              autoComplete="off"
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div className="mb16">
                    <TextField
                      name="firstName"
                      label="First Name"
                      type="text"
                      value={""}
                      onChange={handleSearch}
                    />
                  </div>
                  <div className="mb16">
                    <TextField
                      name="patientid"
                      label="Patient Id"
                      type="text"
                      value={""}
                      onChange={handleSearch}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="mb16">
                    <TextField
                      name="lastName"
                      label="Last Name"
                      type="text"
                      value={""}
                      onChange={handleSearch}
                    />
                  </div>
                  <div className="mb16">
                    <FormControl fullWidth>
                      <InputLabel id="hospitals">Hospital</InputLabel>
                      <Select
                        name="hospitals"
                        labelId="hospital"
                        id="hospital-select"
                        value={studyData.hospital}
                        onChange={handleSearch}
                        label="Hospital"
                      >
                        {hospitalList?.map((hospital) => (
                          <MenuItem value={hospital.uid}>
                            {hospital.hospitalName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div className="layout layout-item-center layout-justify-end">
                    <Button variant="text" onClick={handleClose}>
                      Reset
                    </Button>
                    <Button
                      className="round-button ml24"
                      variant="outlined"
                      onClick={handleClose}
                    >
                      Search
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Box>
            <TableContainer>
              <Table
                sx={{ minWidth: 700 }}
                aria-label="customized table"
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        // indeterminate={selectedPatients > 0 && selectedPatients < patientsData['data']?.length}
                        // checked={patientsData['data']?.length > 0 && selectedPatients === patientsData['data']?.length}
                        onChange={SelectAllUsers}
                      />
                    </StyledTableCell>
                    <StyledTableCell>User ID</StyledTableCell>
                    <StyledTableCell>First Name</StyledTableCell>
                    <StyledTableCell>Last Name</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {usersData["data"]?.length > 0 ? (
                    usersData["data"]?.map((row) => (
                      <StyledTableRow
                        key={row.uid}
                        hover
                        onClick={(event) => selectUser(event, row.uid)}
                        role="checkbox"
                        aria-checked={isSelectedUsers(row.uid)}
                        tabIndex={-1}
                        selected={isSelectedUsers(row.uid)}
                        sx={{ cursor: "pointer" }}
                      >
                        <StyledTableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isSelectedUsers(row.uid)}
                          />
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {/* <NavLink to={`/viewuser/${row.uid}`} exact activeClassName="active"> */}
                          {row.pid}
                          {/* </NavLink> */}
                        </StyledTableCell>
                        <StyledTableCell>{row.firstName}</StyledTableCell>
                        <StyledTableCell>{row.lastName}</StyledTableCell>

                        <StyledTableCell>
                          <Chip label={row.status} variant="outlined" />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell colSpan={8} align="center">
                        No Records Available
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-bg">
          <div className="layout layout-item-center layout-justify-end layout-full-width mt16 mb16 mr32">
            <Button variant="text" onClick={closeUsersDialog}>
              Close
            </Button>
            <Button
              className="round-button ml24"
              variant="outlined"
              onClick={saveUserSelection}
            >
              Save Selection
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Header>
          <div className="layout layout-item-center layout-justify-space-between">
            <h2>Create Study</h2>
            <Stack spacing={2} direction="row">
              <Button variant="text" onClick={backToStudies}>
                Cancel
              </Button>
              <Button
                className="round-button"
                variant="contained"
                type="submit"
              >
                {isLoading ? <CircularProgress color="secondary" size={24} /> : "Save Study"}
              </Button>
            </Stack>
          </div>
        </Header>
        <div style={{ padding: "24px" }}>
          {/* <Box
                    id="userForm"
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                > */}
          <Grid container>
            <Grid item xs={12} md={10}>
              <Card className="mb16">
                <CardContent>
                  <p>
                    <b>Study Details</b>
                  </p>
                  <Grid
                    container
                    rowSpacing={{ xs: 1, sm: 1, md: 1 }}
                    columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                  >
                    <Grid item xs={12} md={5}>
                      <div className="mb16">
                        <TextField
                          fullWidth
                          name="studyName"
                          label="Study Name"
                          type="text"
                          {...register("studyName", {
                            required: true,
                            minLength: 3,
                            maxLength: 60,
                          })}
                          // value={studyData.name}
                          // onChange={handleChange}
                          aria-label="Study Name"
                          tabIndex={1}
                          autoFocus
                        />
                        {errors.studyName &&
                          errors.studyName.type === "required" && (
                            <p role="alert" className="error-message">
                              Please enter Study Name
                            </p>
                          )}
                        {errors.studyName &&
                          errors.studyName.type === "minLength" && (
                            <p role="alert" className="error-message">
                              Study Name should be minimum 3 characters long
                            </p>
                          )}
                        {errors.studyName &&
                          errors.studyName.type === "maxLength" && (
                            <p role="alert" className="error-message">
                              Study Name should not be more than 60 characters
                            </p>
                          )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <div className="mb16">
                        <FormControl fullWidth>
                          <InputLabel id="studytype">Study Type</InputLabel>
                          <Select
                            name="studyType"
                            labelId="studytype"
                            id="studytype-select"
                            // value={studyData.studyType}
                            // onChange={handleChange}
                            {...register("studyType", { required: true })}
                            defaultValue=""
                            label="Study Type"
                            aria-label="Study Type"
                            tabIndex={2}
                            onChange={(event) => {
                              setValue("studyType", event.target.value);
                              clearErrors("studyType");
                            }}
                          >
                            {configList["studyType"]?.map((type) => (
                              <MenuItem value={type}>{type}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {errors.studyType && (
                          <p role="alert" className="error-message">
                            Please select a Study Type
                          </p>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={2}></Grid>
                    <Grid item xs={12} md={5}>
                      <div className="mb16">
                        <FormControl fullWidth>
                          <InputLabel id="surveySets">Survey Set</InputLabel>
                          <Select
                            name="surveySets"
                            labelId="surveySets"
                            id="surveySets-select"
                            // value={studyData.surveySets}
                            // onChange={handleChange}
                            {...register("surveySetUID", { required: true })}
                            defaultValue=""
                            label="Survey Sets"
                            aria-label="Survey Sets"
                            tabIndex={3}
                            onChange={(event) => {
                              setValue("surveySetUID", event.target.value);
                              clearErrors("surveySetUID");
                            }}
                          >
                            {surveyList?.map((survey) => (
                              <MenuItem value={survey.uid}>
                                {survey.surveyName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {errors.surveySetUID && (
                          <p role="alert" className="error-message">
                            Please select a Survey Set
                          </p>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <div className="mb16">
                        <FormControl fullWidth>
                          <InputLabel id="surveySubset">
                            Survey Subset
                          </InputLabel>
                          <Select
                            name="surveySubset"
                            labelId="surveySubset"
                            id="surveySubset-select"
                            // value={studyData.surveySubType}
                            // onChange={handleChange}
                            {...register("surveySubsetUID", {
                              required: false,
                            })}
                            defaultValue=""
                            label="Survey Subset"
                            aria-label="Survey Subset"
                            tabIndex={4}
                          >
                            {surveyList?.map((survey) => (
                              <MenuItem value={survey.uid}>
                                {survey.surveyName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={2}></Grid>
                    <Grid item xs={12} md={5}>
                      <div class="mb16">
                        <TextField
                          fullWidth
                          name="description"
                          id="outlined-multiline-static"
                          label="Description"
                          multiline
                          rows={4}
                          defaultValue=""
                          // value={studyData.description}
                          // onChange={handleChange}
                          {...register("studyDescription", { required: true })}
                          aria-label="Description"
                          tabIndex={5}
                          // onChange={(event) => {
                          //     setValue('studyDescription', event.target.value);
                          //     clearErrors('studyDescription');
                          // }}
                        />
                        {errors.studyDescription && (
                          <p role="alert" className="error-message">
                            Please enter Study Description
                          </p>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <div className="mb16">
                        <FormControl fullWidth>
                          <InputLabel id="principalInvestigator">
                            Principal Investigator
                          </InputLabel>
                          <Select
                            name="principalInvestigatorUID"
                            labelId="principalInvestigator"
                            id="principalInvestigator-select"
                            // value={studyData.principalInvestigatorUID}
                            // onChange={handleChange}
                            {...register("principalInvestigatorUID", {
                              required: true,
                            })}
                            defaultValue=""
                            label="Principal Investigator"
                            aria-label="Principal Investigator"
                            tabIndex={6}
                            onChange={(event) => {
                              setValue(
                                "principalInvestigatorUID",
                                event.target.value
                              );
                              clearErrors("principalInvestigatorUID");
                            }}
                          >
                            {usersData["data"]?.map((user) => (
                              <MenuItem value={user.uid}>
                                {user.firstName}{" "}
                                {user.lastName ? user.lastName : ""}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {errors.principalInvestigatorUID && (
                          <p role="alert" className="error-message">
                            Please select a PI
                          </p>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={2}></Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} md={10}>
              <Card className="mb16">
                <CardContent>
                  <p>
                    <b>Hospital Details</b>
                  </p>
                  <Grid
                    container
                    rowSpacing={{ xs: 1, sm: 1, md: 1 }}
                    columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                  >
                    <Grid item xs={12} md={5}>
                      <div className="mb16">
                        <FormControl fullWidth>
                          <InputLabel id="hospitals">Hospital Name</InputLabel>
                          <Select
                            name="hospitalUID"
                            labelId="hospital"
                            id="hospital-select"
                            // value={studyData.hospitalUID}
                            // onChange={handleChange}
                            {...register("hospitalUID", { required: true })}
                            defaultValue=""
                            label="Hospital Name"
                            aria-label="Hospital Name"
                            tabIndex={7}
                            onChange={(event) => {
                              setValue("hospitalUID", event.target.value);
                              clearErrors("hospitalUID");
                            }}
                          >
                            {hospitalList?.map((hospital) => (
                              <MenuItem value={hospital.uid}>
                                {hospital.hospitalName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {errors.hospitalUID && (
                          <p role="alert" className="error-message">
                            Please select a Hospital
                          </p>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <div className="mb16">
                        {/* <FormControl fullWidth>
                                            <InputLabel id="roles">Location</InputLabel>
                                            <Select
                                                name="location"
                                                labelId="location"
                                                id="location-select"
                                                // value={studyData.location}
                                                // onChange={handleChange}
                                                {...register('location', { required: true })}
                                                label="Location"
                                            >

                                                <MenuItem value={'Senegal'}>Senegal</MenuItem>
                                            </Select>
                                        </FormControl> */}
                        <p>
                          {hospitalAddress?.street}, {hospitalAddress?.city},{" "}
                          {hospitalAddress?.state}, {hospitalAddress?.country},{" "}
                          {hospitalAddress?.zipcode}
                        </p>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={2}></Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} md={10}>
              <Card className="mb16">
                <CardContent>
                  <p>
                    <b>Consent Form</b>
                  </p>
                  <Grid
                    container
                    rowSpacing={{ xs: 1, sm: 1, md: 1 }}
                    columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                  >
                    <Grid item xs={12} md={5}>
                      <div className="mb16">
                        <TextField
                          fullWidth
                          name="consenttitle"
                          label="Consent Title"
                          type="text"
                          // value={studyData.title}
                          // onChange={handleChange}
                          {...register("consentTitle", { required: true })}
                          aria-label="Consent Title"
                          tabIndex={8}
                        />
                        {errors.consentTitle && (
                          <p role="alert" className="error-message">
                            Please enter Consent Title
                          </p>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <div className="mb16">
                        <FormControl fullWidth>
                          <InputLabel id="language">Language</InputLabel>
                          <Select
                            name="language"
                            labelId="language"
                            id="language-select"
                            // value={studyData.language}
                            // onChange={handleChange}
                            {...register("language", { required: true })}
                            label="Language"
                            defaultValue=""
                            aria-label="Language"
                            tabIndex={9}
                            onChange={(event) => {
                              setValue("language", event.target.value);
                              clearErrors("language");
                            }}
                          >
                            <MenuItem value={"French"}>French</MenuItem>
                            <MenuItem value={"English"}>English</MenuItem>
                          </Select>
                        </FormControl>
                        {errors.language && (
                          <p role="alert" className="error-message">
                            Please select a language
                          </p>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={2}></Grid>
                    <Grid item xs={12} md={5}>
                      <div class="mb16">
                        <TextField
                          fullWidth
                          name="patientConsentForm"
                          id="patientdesc"
                          label="Patient Description"
                          multiline
                          rows={4}
                          // defaultValue=""
                          // value={studyData.patientConsentForm}
                          // onChange={handleChange}
                          {...register("patientConsentForm", {
                            required: true,
                          })}
                          aria-label="Patient Description"
                          tabIndex={10}
                        />
                      </div>
                      {errors.patientConsentForm && (
                        <p role="alert" className="error-message">
                          Please enter Patient Consent Description
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <div class="mb16">
                        <TextField
                          fullWidth
                          name="piConsentForm"
                          id="principalinvestigatordesc"
                          label="Principal Investigator Description"
                          multiline
                          rows={4}
                          // defaultValue=""
                          // value={studyData.piConsentForm}
                          // onChange={handleChange}
                          {...register("piConsentForm", { required: false })}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={2}></Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          {/* </Box> */}

          <Grid container>
            <Grid item xs={12} md={10}>
              <Card className="">
                <CardContent>
                  <Box sx={{ width: "100%", typography: "body1" }}>
                    <TabContext value={tabValue}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <div className="layout layout-item-center layout-justify-space-between">
                          <div>
                            <TabList onChange={handleTabChange} aria-label="">
                              <Tab label="Patients" value="1" />
                              <Tab label="Users" value="2" />
                            </TabList>
                          </div>
                          <div>
                            {tabValue == 1 ? (
                              <Button
                                className="round-button"
                                variant="outlined"
                                onClick={OpenPatientDialog}
                              >
                                Add Patients
                              </Button>
                            ) : (
                              <Button
                                className="round-button"
                                variant="outlined"
                                onClick={OpenUsersDialog}
                              >
                                Add Users
                              </Button>
                            )}
                          </div>
                        </div>
                      </Box>
                      <TabPanel value="1">
                        <TableContainer>
                          <Table
                            sx={{ minWidth: 700 }}
                            aria-label="customized table"
                            size="small"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Patient ID</StyledTableCell>
                                <StyledTableCell>First Name</StyledTableCell>
                                <StyledTableCell>Last Name</StyledTableCell>
                                <StyledTableCell>Gender</StyledTableCell>
                                <StyledTableCell>Date of Birth</StyledTableCell>
                                <StyledTableCell>Actions</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {selectedPatientsData.length > 0 ? (
                                selectedPatientsData.map((row) => (
                                  <StyledTableRow key={row.pid}>
                                    <StyledTableCell component="th" scope="row">
                                      {/* <NavLink to={`/viewpatient/${row.uid}`} exact activeClassName="active"> */}
                                      {row.pid}
                                      {/* </NavLink> */}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row.firstName}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row.lastName}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row.gender}
                                    </StyledTableCell>
                                    <StyledTableCell>{row.dob}</StyledTableCell>
                                    {/* <StyledTableCell>{row.email}</StyledTableCell> */}
                                    <StyledTableCell>
                                      <IconButton aria-label="create">
                                        <Icon color="dark">delete</Icon>
                                      </IconButton>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))
                              ) : (
                                <StyledTableRow>
                                  <StyledTableCell colSpan={8} align="center">
                                    No Records Available
                                  </StyledTableCell>
                                </StyledTableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TabPanel>
                      <TabPanel value="2">
                        <TableContainer>
                          <Table
                            sx={{ minWidth: 700 }}
                            aria-label="customized table"
                            size="small"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>User ID</StyledTableCell>
                                <StyledTableCell>First Name</StyledTableCell>
                                <StyledTableCell>Last Name</StyledTableCell>
                                <StyledTableCell>Status</StyledTableCell>
                                <StyledTableCell>Actions</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {selectedUsersData.length > 0 ? (
                                selectedUsersData.map((row) => (
                                  <StyledTableRow key={row.pid}>
                                    <StyledTableCell component="th" scope="row">
                                      {/* <NavLink to={`/viewuser/${row.uid}`} exact activeClassName="active"> */}
                                      {row.pid}
                                      {/* </NavLink> */}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row.firstName}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row.lastName}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <Chip
                                        label={row.status}
                                        variant="outlined"
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <IconButton aria-label="create">
                                        <Icon color="dark">delete</Icon>
                                      </IconButton>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))
                              ) : (
                                <StyledTableRow>
                                  <StyledTableCell colSpan={8} align="center">
                                    No Records Available
                                  </StyledTableCell>
                                </StyledTableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TabPanel>
                    </TabContext>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </form>
      <Box component="footer">
        <Copyright sx={{ pb: 3, px: 3 }} />
      </Box>
    </div>
  );
}

export default CreateStudy;
